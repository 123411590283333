import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import './styles.scss'
import { logout } from 'state/auth/actions'
import { linkDropdownAccount } from 'components/Menu/config'
import { toggleModalSoon } from 'state/app/actions'
import SubMenu from 'antd/lib/menu/SubMenu'
import { FormattedMessage } from 'react-intl'
import WDropdown from 'widgets/WDropdown'
import { formatCode } from 'helpers'

const ProfileAccount = ({ hideName, className, style }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { userInfo } = useSelector((state) => state.user)

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <WDropdown
      trigger={['click']}
      open
      title={
        <a className={`wProfileAccount ${className}`} onClick={(e) => e.preventDefault()} role="presentation" style={style}>
          <div className="background-cover">
            <img
              src={userInfo?.profile?.avatar ? userInfo?.profile?.avatar : '/images/user-default.png'}
              alt=""
              title=""
              onError={(e) => {
                e.target.onerror = null
                e.target.src = '/images/user-default.png'
              }}
            />
          </div>
          {!hideName && (
            <div className="profile-account-info-wrapper">
              <span>{`${userInfo?.profile?.fistname} ${userInfo?.profile?.lastname}`}</span>
              <span className="profile-account-wallet">{formatCode(userInfo?.wallet_address, 6, 5)}</span>
            </div>
          )}
        </a>
      }
      overlay={
        <Menu className="wOverlayProfileAccount">
          {linkDropdownAccount.map((item, index) => {
            const isHttp = item?.href?.startsWith('http')
            const Tag = isHttp ? 'a' : Link
            const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
            const Icon = item?.icon
            if (item.type === 'soon') {
              return (
                <a onClick={() => dispatch(toggleModalSoon({ toggle: true }))} role="presentation" key={`dropdow-menu-${index}`}>
                  {item.icon && <Icon />}
                  <span>{item.label}</span>
                </a>
              )
            }
            return (
              <SubMenu
                key={`submenu-${index}`}
                title={
                  <Tag {...propsLink}>
                    {item.icon && <Icon />}
                    {item.label}
                  </Tag>
                }
              >
                {item.subMenu.map((itemSub, index) => {
                  const propsLink = isHttp ? { href: itemSub?.href, target: itemSub.target } : { to: itemSub?.href }
                  return (
                    <Menu.Item
                      key={`submenu-${index}`}
                      className={
                        location.pathname.split('/').includes(item.label.toLowerCase())
                          ? 'panel-link-label-title-child active'
                          : 'panel-link-label-title-child'
                      }
                    >
                      <Tag className="panel-link-label-title-child-link" {...propsLink}>
                        {itemSub.label}
                      </Tag>
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            )
          })}
          <SubMenu
            title={
              <a className="logout-icons" role="presentation" onClick={handleLogout}>
                <LogoutOutlined />
                <FormattedMessage id="Logout" />
              </a>
            }
          />
        </Menu>
      }
    />
  )
}

export default ProfileAccount
