import React, { useState } from 'react'
import TableHistoryLoginDesktop from './TableHistoryLoginDesktop'
import { useDispatch } from 'react-redux'
import { logout } from 'state/auth/actions'
import { isMobile } from 'react-device-detect'
import TableHistoryLoginMobile from './TableHistoryLoginMobile'

export const HistoryTable = ({ historyLogin, paramsHistory, setParamsHistory }) => {
  const dispatch = useDispatch()
  const [loading] = useState(false)
  const handleLogout = () => {
    dispatch(logout())
  }

  if (!historyLogin) return <></>

  const loadMoreData = () => {
    if (loading) {
      return
    }

    setParamsHistory((prev) => ({
      ...prev,
      pageSize: prev.pageSize + 10,
    }))
  }
  return (
    <>
      {isMobile ? (
        <TableHistoryLoginMobile
          paramsHistory={paramsHistory}
          setParamsHistory={setParamsHistory}
          total={historyLogin?.total}
          historyLogin={historyLogin || []}
          loadMoreData={loadMoreData}
        />
      ) : (
        <TableHistoryLoginDesktop
          paramsHistory={paramsHistory}
          dataSource={historyLogin.rows}
          historyLogin={historyLogin}
          setParamsHistory={setParamsHistory}
          handleLogout={handleLogout}
        />
      )}
    </>
  )
}
