import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import './styles.scss'
import WBody from 'modules/WBody'
import { BackLinkIcon } from 'widgets/Svg'
import BackLink from 'components/BackLink'
import { HistoryTable } from 'components/AccountActiveTable'
import { getHistoryLogin, logout } from 'state/auth/actions'
import ButtonPrimary from 'widgets/ButtonPrimary'

const Form = () => {
  const { rows, total } = useSelector((state) => state.transactions.transactionsList)
  const { historyLogin } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const [paramsHistory, setParamsHistory] = useState({
    page: 1,
    pageSize: 10,
  })
  useEffect(() => {
    dispatch(getHistoryLogin(paramsHistory))
  }, [dispatch, paramsHistory])

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <>
      {window.innerWidth <= 991 && (
        <BackLink
          className="back-link title-page-account-activity"
          label={<FormattedMessage id="Account Activity" />}
          icon={<BackLinkIcon />}
          to="/profile/security"
        />
      )}

      <WBody className=" warp-history-form">
        {window.innerWidth > 991 && (
          <div className="go-back">
            <BackLink label={<FormattedMessage id="Account Activity" />} icon={<BackLinkIcon />} to="/profile/security" />

            <Link to="/profile/account-activity" role="presentation" onClick={handleLogout}>
              <ButtonPrimary className="danger" style={{ width: '150px' }}>
                <FormattedMessage id="Log Out All" />
              </ButtonPrimary>
            </Link>
          </div>
        )}

        <div className="history-form">
          <HistoryTable
            setParamsHistory={setParamsHistory}
            paramsHistory={paramsHistory}
            historyLogin={historyLogin}
            rows={rows}
            total={total}
          />
        </div>
        {window.innerWidth <= 991 && (
          <div style={{ textAlign: 'center', paddingTop: '32px', paddingBottom: '22px' }}>
            <Link to="/profile/account-activity" role="presentation" onClick={handleLogout}>
              <ButtonPrimary className="danger" style={{ width: '103px', borderRadius: '8px' }}>
                <FormattedMessage id="Log Out All" />
              </ButtonPrimary>
            </Link>
          </div>
        )}
      </WBody>
    </>
  )
}

export default Form
