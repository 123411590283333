import React, { useMemo } from 'react'
import CurrencyFormat from 'react-currency-format'
import './style.scss'
import { isNumber, roundNumber } from 'helpers'
import { DECIMALS } from 'constants/index'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { isMobile } from 'react-device-detect'

const CardBalance = ({ currency }) => {
  const totalBalance = useMemo(() => {
    if (currency) {
      return currency.reduce((total, current) => total + current.balance * current.usd_rate, 0)
    }
    return undefined
  }, [currency])

  return (
    <div className="card-balance">
      {isMobile && <img className="decoration-left-mobile" src="/images/wallets/wallet_decotate_02.png" alt="" />}
      {isMobile && <img className="decoration-right-mobile" src="/images/wallets/wallet_decorate.png" alt="" />}
      <div className="card-header">
        <div className="balance-title">
          <FormattedMessage id="Balance (USD)" />
        </div>
      </div>
      <div className="card-content">
        {isNumber(totalBalance) ? (
          <>
            <span>$</span>
            <CurrencyFormat value={roundNumber(totalBalance, { decimals: DECIMALS })} displayType="text" thousandSeparator />
          </>
        ) : (
          <span>--</span>
        )}
      </div>
      <div className="card-button">
        <Link to="/wallet/deposit/USDT">
          <ButtonPrimary className="active">
            <FormattedMessage id="Deposit" />
          </ButtonPrimary>
        </Link>
        <Link to="/wallet/withdraw/USDT">
          <ButtonPrimary className="active">
            <FormattedMessage id="Withdraw" />
          </ButtonPrimary>
        </Link>

        <Link to="/wallet/history">
          <ButtonPrimary className="active">
            <FormattedMessage id="History" />
          </ButtonPrimary>
        </Link>
      </div>
    </div>
  )
}

export default CardBalance
