import React from 'react'
import { Col, Row } from 'antd'
import { isNumber, roundNumber } from 'helpers'
import { FormattedMessage } from 'react-intl'
import './style.scss'
import FormatAmount from 'widgets/FormatAmount'

const NetWorkInfo = ({ totalReport, investmentStatisticReport }) => {
  const totalCommission = totalReport?.reportTransaction?.reduce(
    (total, curr) => total + curr.COMMISSION + curr.DIRECT_COMMISSION_INVEST + curr.DIVIDEND_COMMISSION + curr.BINARY_COMMISSION,
    0,
  )
  const totalTeamVolume = investmentStatisticReport?.reduce((total, curr) => total + curr.ref_sum_team_volumn / 1e18, 0)
  const totalRefMember = investmentStatisticReport?.reduce((total, curr) => total + curr.ref_count_member, 0)
  const totalDirectMember = investmentStatisticReport?.reduce((total, curr) => total + curr.ref_count_f1, 0)

  return (
    <div className="network-info">
      <Row
        gutter={[
          {
            xs: 8,
            md: 24,
            xl: 48,
          },
          24,
        ]}
      >
        <Col xs={12} lg={6}>
          <div className="network-info-wrap-item">
            <div className="network-info-item network-info-item-firstchild">
              <div className="network-info-item-image">
                <img src="/images/dashboard/total-buy.png" alt="" />
              </div>
              <div className="network-info-item-info">
                <p>
                  <FormatAmount value={roundNumber(totalCommission, { decimals: 18 })} nullValue="--" />
                </p>
                <p>
                  <FormattedMessage id="Total Commission" />
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="network-info-wrap-item">
            <div className="network-info-item">
              <div className="network-info-item-image">
                <img src="/images/dashboard/total-earn.png" alt="" />
              </div>
              <div className="network-info-item-info">
                <p>
                  <FormatAmount value={roundNumber(totalTeamVolume)} nullValue="--" />
                </p>
                <p>
                  <FormattedMessage id="Total Team Volume" />
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="network-info-wrap-item">
            <div className="network-info-item">
              <div className="network-info-item-image">
                <img src="/images/icons/referals-member.png" alt="" />
              </div>
              <div className="network-info-item-info">
                <p>{isNumber(totalRefMember) ? totalRefMember : '--'}</p>
                <p>
                  <FormattedMessage id="Total Referral Members" />
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6} style>
          <div className="network-info-wrap-item">
            <div className="network-info-item">
              <div className="network-info-item-image">
                <img src="/images/icons/total-f1.png" alt="" />
              </div>
              <div className="network-info-item-info">
                <p>{isNumber(totalDirectMember) ? totalDirectMember : '--'}</p>
                <p>
                  <FormattedMessage id="Diamond Member" />
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default NetWorkInfo
