import React, { useEffect, useState } from 'react'
import InvestmentList from './components/InvestmentList'
import './style.scss'
import { Card } from 'antd'
import InvestmentTransactionHistory from './components/InvestmentTransactionHistory'
import InvestmentBoughtTransaction from './components/InvestmentBoughtTransaction'
import ModalInvest from './components/ModalInvest'
import { useDispatch, useSelector } from 'react-redux'
import { getHistoryInvestment } from 'state/invest/actions'
import { removeEmpty } from 'helpers'
import { getTransactionList } from 'state/transactions/actions'
import { FormattedMessage } from 'react-intl'
import InvestmentMyNft from 'modules/InvestmentMyNft'
import { getCurrency } from 'state/wallets/actions'
import useIsMobile from 'hooks/useIsMobile'

const TABS_TX = {
  TAB1: 'txHistory',
  TAB2: 'boughtHistory',
}

const TABS_CARD = {
  TAB1: 'ListCardInvest',
  TAB2: 'boughtHistory',
}

const Investment = () => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState(TABS_TX.TAB1)
  const [activeTabCard, setActiveTabCard] = useState(TABS_CARD.TAB1)

  const isMobile = useIsMobile()

  const { userInfo } = useSelector((state) => state.user)

  const [modalBuy, setModalBuy] = useState({ toggle: false, dataModal: null })

  /* Investment history */
  const { transactionsList } = useSelector((state) => state.transactions)
  const [txInvestParams, setTxInvestParams] = useState({
    page: 1,
    pageSize: 10,
    txhash: '',
    type: 'INVESTMENT',
    from_date: '',
    to_date: '',
    chain_code: '',
    currency: '',
  })
  useEffect(() => {
    dispatch(getTransactionList(removeEmpty(txInvestParams)))
  }, [dispatch, txInvestParams])

  /* Bought history */
  const { historyInvestment } = useSelector((state) => state.invest)
  const [txBoughtParams, setTxBoughtParams] = useState({
    page: 1,
    pageSize: 10,
    customer_id: '',
    package_id: '',
  })
  useEffect(() => {
    if (txBoughtParams.customer_id) {
      dispatch(getHistoryInvestment(removeEmpty(txBoughtParams)))
    }
  }, [dispatch, txBoughtParams])
  useEffect(() => {
    if (userInfo) {
      setTxBoughtParams((prev) => ({
        ...prev,
        customer_id: userInfo?._id,
      }))
    }
  }, [userInfo])

  /*  */
  const handleInvestSuccess = () => {
    dispatch(getCurrency({ page: 1, pageSize: 100 }))
    dispatch(getTransactionList(removeEmpty(txInvestParams)))
    dispatch(getHistoryInvestment(removeEmpty(txBoughtParams)))
  }

  const handleSelectPackage = ({ value }) => {
    setTxBoughtParams((prev) => ({ ...prev, package_id: value }))
  }

  const contentList = {
    [TABS_TX.TAB1]: (
      <InvestmentTransactionHistory
        isMobile={isMobile}
        transactionsList={transactionsList}
        txInvestParams={txInvestParams}
        setTxInvestParams={setTxInvestParams}
      />
    ),
    [TABS_TX.TAB2]: (
      <InvestmentBoughtTransaction
        isMobile={isMobile}
        historyInvestment={historyInvestment}
        txBoughtParams={txBoughtParams}
        setTxBoughtParams={setTxBoughtParams}
        onSelectPackage={handleSelectPackage}
      />
    ),
  }

  const contentListListCard = {
    [TABS_CARD.TAB1]: <InvestmentList setModalBuy={setModalBuy} />,
    [TABS_CARD.TAB2]: <InvestmentMyNft />,
  }
  return (
    <div className="container investment-wrapper">
      {isMobile ? (
        <div className="invest-transaction">
          <Card
            style={{ width: '400' }}
            tabList={[
              {
                key: TABS_CARD.TAB1,
                tab: <FormattedMessage id="Investment" />,
              },
              {
                key: TABS_CARD.TAB2,
                tab: <FormattedMessage id="My NFTs" />,
              },
            ]}
            activeTabKey={activeTabCard}
            onTabChange={(key) => {
              setActiveTabCard(key)
            }}
          >
            {contentListListCard[activeTabCard]}
          </Card>
        </div>
      ) : (
        <InvestmentList setModalBuy={setModalBuy} />
      )}
      {!isMobile && (
        <>
          <div className="investment-link-information">
            <a href="https://bit.ly/3rFP3Xv" target="_blank" rel="noreferrer">
              <FormattedMessage id="More information about all package" />
            </a>
          </div>
          <div className="invest-transaction">
            <Card
              style={{ width: '400' }}
              tabList={[
                {
                  key: TABS_TX.TAB1,
                  tab: <FormattedMessage id="Transaction History" />,
                },
                {
                  key: TABS_TX.TAB2,
                  tab: <FormattedMessage id="Bought History" />,
                },
              ]}
              activeTabKey={activeTab}
              onTabChange={(key) => {
                setActiveTab(key)
              }}
            >
              {contentList[activeTab]}
            </Card>
          </div>
        </>
      )}

      <ModalInvest
        visible={modalBuy.toggle}
        dataModal={modalBuy.dataModal}
        userInfo={userInfo}
        onSuccess={handleInvestSuccess}
        closeModal={() => setModalBuy({ toggle: false, dataModal: null })}
      />
    </div>
  )
}
export default Investment
