import TxAmount from 'components/TxAmount'
import TxStatus from 'components/TxStatus'
import { formatDate, isNumber, roundNumber } from 'helpers'
import CurrencyFormat from 'react-currency-format'

const columns = () => [
  {
    title: <div className="invest-transaction-action">Action</div>,
    dataIndex: 'action',
    render: (text) => <div className="invest-transaction-action">{text}</div>,
  },
  {
    title: <div className="invest-transaction-amount">Amount</div>,
    dataIndex: 'amount',
    render: (_, record) => (
      <div className="investment-transaction-amount">
        {isNumber(+record?.amount) ? (
          <TxAmount
            action={record.action}
            title={
              <CurrencyFormat
                value={roundNumber(record?.amount, { decimals: 18 })}
                displayType="text"
                thousandSeparator
                suffix={record?.currency ? ` ${record.currency}` : ''}
                renderText={(t) => t}
              />
            }
          />
        ) : (
          '--'
        )}
      </div>
    ),
  },
  {
    title: <div className="invest-transaction-package">Package</div>,
    dataIndex: '_id',
    render: (text) => <div>{text}</div>,
  },
  {
    title: <div className="invest-transaction-status">Status</div>,
    dataIndex: 'status',
    render: (_, record) => (
      <div className="invest-transaction-status">
        <TxStatus status={record.status} title={record.status} />
      </div>
    ),
  },
  {
    title: <div className="invest-transaction-time">Time</div>,
    dataIndex: 'createdAt',
    render: (text) => <div>{text ? formatDate(text * 1000) : ''}</div>,
  },
]

export default columns
