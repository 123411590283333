import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'
import './style.scss'
import { roundNumber } from 'helpers/index'
import Loading from 'widgets/Loading/Loading'
import { DECIMALS } from 'constants/index'
import { FormattedMessage, injectIntl } from 'react-intl'
import FormatAmount from 'widgets/FormatAmount'

const WalletListMobile = ({ walletList, searchCode }) => {
  const [data, setData] = useState()

  useEffect(() => {
    if (!walletList) return setData([])
    if (!searchCode) return setData(walletList)
    if (searchCode) {
      setData(
        walletList.filter((entry) => Object.values(entry).some((val) => typeof val === 'string' && val.includes(searchCode))),
      )
    }
  }, [searchCode, walletList])

  return (
    <div className="wrapper-wallet-list-mobile">
      {data &&
        data.map((item) => (
          <div className="item-currency" key={item._id}>
            <div>
              <p>Currency</p>
              <p>
                <img src={item.icon} alt="" /> {item.title}
              </p>
            </div>

            <div>
              <p>
                <FormattedMessage id="Amount" />
              </p>
              <p>
                {item?.balance !== undefined ? (
                  <FormatAmount value={roundNumber(item.balance, { decimals: DECIMALS })} />
                ) : (
                  <Loading />
                )}
              </p>
            </div>

            <div>
              <p>
                <FormattedMessage id="USD" />
              </p>
              <p>
                {item.balance !== undefined && item.balance !== null ? (
                  <CurrencyFormat value={roundNumber(item.usdValue, 2)} displayType="text" thousandSeparator suffix="$" />
                ) : (
                  <Loading />
                )}
              </p>
            </div>

            <div>
              <p>
                <FormattedMessage id="Action" />
              </p>

              <div className="actions">
                <Link to={`/wallet/deposit/${item.code}`}>
                  <button type="button">
                    <img src="/images/icons/deposit.png" alt="" />
                    <FormattedMessage id="Deposit" />
                  </button>
                </Link>
                <Link to={`/wallet/withdraw/${item.code}`}>
                  <button type="button">
                    <img src="/images/icons/withdraw.png" alt="" />
                    <FormattedMessage id="Withdraw" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
export default injectIntl(WalletListMobile)
