import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import './styles.scss'
import { linksPublic } from 'components/Menu/config'
import { toggleModalSoon } from 'state/app/actions'
import SubMenu from 'antd/lib/menu/SubMenu'
import WDropdown from 'widgets/WDropdown'

const MenuCollapse = ({ className, style, toggleMenu }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  // const handleLogout = () => {
  //   dispatch(logout())
  // }

  return (
    <WDropdown
      trigger={['click']}
      title={
        <div className={`wMenuCollapse ${className}`} onClick={toggleMenu} role="presentation" style={style}>
          <img src="/images/icons/menu_collapse.png" alt="" />
        </div>
      }
      overlay={
        <Menu className="wOverlayProfileAccount">
          {linksPublic.map((item, index) => {
            const isHttp = item?.href?.startsWith('http')
            const Tag = isHttp ? 'a' : Link
            const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
            const Icon = item?.icon
            if (item.type === 'soon') {
              return (
                <a onClick={() => dispatch(toggleModalSoon({ toggle: true }))} role="presentation" key={`dropdow-menu-${index}`}>
                  {item.icon && <Icon />}
                  <span>{item.label}</span>
                </a>
              )
            }
            return (
              <SubMenu
                key={`submenu-${index}`}
                title={
                  <Tag {...propsLink}>
                    {item.icon && <Icon />}
                    {item.label}
                  </Tag>
                }
              >
                {item?.subMenu?.map((itemSub, index) => {
                  const propsLink = isHttp ? { href: itemSub?.href, target: itemSub.target } : { to: itemSub?.href }
                  return (
                    <Menu.Item
                      key={`submenu-${index}`}
                      className={
                        location.pathname.split('/').includes(item.label.toLowerCase())
                          ? 'panel-link-label-title-child active'
                          : 'panel-link-label-title-child'
                      }
                    >
                      <Tag className="panel-link-label-title-child-link " {...propsLink}>
                        {itemSub.label}
                      </Tag>
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            )
          })}
        </Menu>
      }
    />
  )
}

export default MenuCollapse
