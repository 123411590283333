import * as types from './constants'
import { DEFAULT_LANGUAGE } from 'language/config'

export const setLanguage = ({ language = DEFAULT_LANGUAGE, reload = false }) => ({
  type: types.SET_LANGUAGE,
  payload: language,
  reload: Boolean(`${reload}`),
})
export const getListCountry = (payload) => ({
  type: types.GET_LIST_COUNTRY,
  payload,
})

export const getListCountrySuccess = (payload) => ({
  type: types.GET_LIST_COUNTRY_SUCCESS,
  payload,
})

export const toggleModalSoon = (payload) => ({
  type: types.TOGGLE_MODAL_SOON,
  payload,
})
