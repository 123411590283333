import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import FormResetPassword from 'modules/auth/components/FormResetPassword'
import { requestForgotPassword } from 'state/auth/actions'
import WrapperSignForm from '../../components/WrapperSignForm'
import FormForgotPass from 'modules/auth/components/FormForgotPass'
import VerifyForgotPass from 'modules/auth/components/VerifyForgotPass'

const VIEW_RESET_PASSWORD = {
  FORM_FORGOT: 0,
  FORM_VERIFY_CODE: 1,
  FORM_NEW_PASS: 2,
}

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [view, setView] = useState(VIEW_RESET_PASSWORD.FORM_FORGOT)
  const [email, setEmail] = useState('')
  const [verifyCode, setVerifyCode] = useState()

  const handleRequestForgotPassword = (values) => {
    executeRecaptcha((recaptcha) => {
      dispatch(
        requestForgotPassword({ ...values, recaptcha }, () => {
          setEmail(values.email)
          setView(VIEW_RESET_PASSWORD.FORM_VERIFY_CODE)
        }),
      )
    })
  }

  const handleFinishInputCode = (code) => {
    if (code) {
      setVerifyCode(code)
      setView(VIEW_RESET_PASSWORD.FORM_NEW_PASS)
    }
  }

  return (
    <WrapperSignForm>
      <div className="wrapper-form-forgot">
        {(() => {
          switch (view) {
            case VIEW_RESET_PASSWORD.FORM_FORGOT:
              return <FormForgotPass onFinish={handleRequestForgotPassword} onBack={() => history.push('/login/email')} />
            case VIEW_RESET_PASSWORD.FORM_VERIFY_CODE:
              return (
                <VerifyForgotPass
                  initialField={6}
                  email={email}
                  onSubmit={handleFinishInputCode}
                  onBack={() => setView(VIEW_RESET_PASSWORD.FORM_FORGOT)}
                />
              )
            case VIEW_RESET_PASSWORD.FORM_NEW_PASS:
              return (
                <FormResetPassword
                  email={email}
                  code={verifyCode}
                  onFinish={() => history.push('/login/email')}
                  onBack={() => setView(VIEW_RESET_PASSWORD.FORM_VERIFY_CODE)}
                />
              )
            default:
              break
          }
        })()}
      </div>
    </WrapperSignForm>
  )
}

export default ForgotPassword
