import React from 'react'
import { Button } from 'antd'
import './style.scss'
import { FormattedMessage } from 'react-intl'

const SignMessage = ({ loading, onSigninMessage, onDisconnect }) => (
  <div className="w-sign-message">
    <Button className="btn-sign-message" loading={loading} onClick={onSigninMessage}>
      <FormattedMessage id="Sign Message" />
    </Button>
    <Button className="btn-disconnect-wallet" onClick={onDisconnect}>
      <FormattedMessage id="Disconnect" />
    </Button>
  </div>
)

export default SignMessage
