import { Col, Row } from 'antd'
import './style.scss'
import InputCopyLink from 'components/FielCopy/InputCopy'
import { APP_URL } from 'constants/index'
import { FormattedMessage } from 'react-intl'

export default function ReferrerCard({ className, userInfo }) {
  const codeSponsor = userInfo?.ref_code
  const link = `${APP_URL}?sponsorKey=${userInfo?.ref_code}`

  return (
    <div className={`wrap-card-ref ${className}`}>
      <div className="box-info-sponserkey">
        <div className="password-left">
          <Row gutter={[50, 16]} style={{ marginBottom: 0 }}>
            <Col className="networks-ref" xs={24} sm={12}>
              <InputCopyLink code={link} title={<FormattedMessage id="Referrals Link" />} />
            </Col>
            <Col className="networks-ref" xs={24} sm={12}>
              <InputCopyLink code={codeSponsor} title={<FormattedMessage id="Referrals Code" />} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

ReferrerCard.defaultProps = {
  className: '',
}
