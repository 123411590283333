import React, { useEffect, useRef, useState } from 'react'
import './styles.scss'
import { DatePicker, Space } from 'antd'
import { CalendarIcon, DropdownIcon } from 'widgets/Svg'

const { RangePicker } = DatePicker

const RangePickerDateToDate = ({ width, maxWidth, ...props }) => {
  const [dropDown, setDropdown] = useState(0)
  const [isDropDown, setIsDropdown] = useState(false)
  const pickerRef = useRef(null)

  useEffect(() => {
    setIsDropdown(pickerRef?.current?.children[0].classList.contains('ant-picker-focused'))
  }, [dropDown])

  return (
    <Space className="wrapper-range-picker-date-to-date" {...props} style={{ width: `${width}`, maxWidth: `${maxWidth}` }}>
      <CalendarIcon className="calendar" />
      <DropdownIcon className="dropdown" style={{ transform: isDropDown ? 'rotate(180deg) translate(0, 63%)' : '' }} />
      <div ref={pickerRef} role="presentation">
        <RangePicker
          onFocus={() => setDropdown((prev) => prev + 1)}
          onBlur={() => setDropdown((prev) => prev + 1)}
          {...props}
          allowClear={false}
        />
      </div>
    </Space>
  )
}

export default RangePickerDateToDate
