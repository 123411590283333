import { useCallback, useEffect, useState } from 'react'
import { MORALIS_APP_API, MORALIS_APP_API_KEY } from 'config/constants/moralis'

const useTokenPrice = (address) => {
  const [price, setPrice] = useState()

  const fetchPrice = useCallback(() => {
    if (address) {
      try {
        fetch(`${MORALIS_APP_API}/erc20/${address}/price?chain=bsc`, {
          method: 'GET',
          headers: {
            'x-api-key': MORALIS_APP_API_KEY,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            setPrice(result?.nativePrice ? result : null)
          })
      } catch (error) {
        setPrice(null)
      }
    }
  }, [address])
  useEffect(() => {
    fetchPrice()
  }, [fetchPrice])

  return { price, fetchPrice }
}

export default useTokenPrice
