import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
import TableWrapper from 'components/TableWrapper'
import { roundNumber } from 'helpers/index'
import Loading from 'widgets/Loading/Loading'
import { DECIMALS } from 'constants/index'
import { FormattedMessage, injectIntl } from 'react-intl'
import FormatAmount from 'widgets/FormatAmount'

const TableWalletListDesktop = ({ walletList, searchCode }) => {
  const header = [
    {
      title: <FormattedMessage id="Currency" />,
      dataIndex: 'title',

      render: (text, record) => (
        <div className="coin-data">
          <img src={record.icon} alt="" />
          <div className="coin-data-label">
            <div className="data-label-text">{text}</div>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <FormattedMessage id="Amount" />
        </div>
      ),
      dataIndex: 'balance',

      render: (text) =>
        text !== undefined ? (
          <div style={{ textAlign: 'center' }}>
            <FormatAmount value={roundNumber(text, { decimals: DECIMALS })} />
          </div>
        ) : (
          <Loading />
        ),
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <FormattedMessage id="USD value" />
        </div>
      ),
      dataIndex: 'usdValue',
      mobileHidden: true,
      render: (text, record) =>
        record.balance !== undefined && record.balance !== null ? (
          <div className="" style={{ fontWeight: 'bold', textAlign: 'center' }}>
            <FormatAmount value={roundNumber(text)} suffix="$" />
          </div>
        ) : (
          <Loading />
        ),
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <FormattedMessage id="Actions" />
        </div>
      ),
      // width: 170,
      dataIndex: 'action',
      render: (_, record) => (
        <div className="actions">
          <Link to={`/wallet/deposit/${record.code}`}>
            <button type="button">
              <img src="/images/icons/deposit.png" alt="" />
              <FormattedMessage id="Deposit" />
            </button>
          </Link>
          <Link to={`/wallet/withdraw/${record.code}`}>
            <button type="button">
              <img src="/images/icons/withdraw.png" alt="" />
              <FormattedMessage id="Withdraw" />
            </button>
          </Link>
        </div>
      ),
    },
  ]
  return (
    <TableWrapper
      className="wallet-list-desktop"
      columns={header}
      dataSource={(() => {
        if (!walletList) return []
        if (!searchCode) return walletList
        if (searchCode) {
          return walletList.filter((entry) =>
            Object.values(entry).some((val) => typeof val === 'string' && val.includes(searchCode)),
          )
        }
      })()}
      rowKey={(record) => record._id}
      scroll={{ y: 300 }}
      pagination={false}
    />
  )
}
export default injectIntl(TableWalletListDesktop)
