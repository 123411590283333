/* eslint-disable no-nested-ternary */
import React from 'react'
import { List } from 'antd'
import './style.scss'
import Loading from 'widgets/Loading/Loading'
import ItemTransaction from '../itemRecentTransaction/index'
import Nodata from 'components/Nodata'
import PaginationCustomer from 'components/PaginationCustomer'

const responsiveList = { gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }
const TableTxhMobile = ({ txParams, data, total, current, setTxParams }) => {
  const NoIndex = txParams.page * txParams.pageSize - 10
  return (
    <div
      id="scrollableDiv"
      style={{
        position: 'relative',
        minHeight: 300,
      }}
    >
      {data?.length > 0 ? (
        <>
          <List
            dataSource={data || []}
            grid={responsiveList}
            renderItem={(item, index) => (
              <List.Item key={`list-${index}`}>
                <ItemTransaction NoIndex={NoIndex} data={item} index={index} key={index} />
              </List.Item>
            )}
            locale={{
              emptyText: total === 0 ? <div>Empty</div> : <></>,
            }}
          />
          <PaginationCustomer
            total={total}
            current={current}
            onChange={(page, pageSize) => {
              setTxParams((prev) => ({
                ...prev,
                page,
                pageSize,
              }))
            }}
          />
        </>
      ) : data === undefined ? (
        <List
          dataSource={[{}]}
          grid={responsiveList}
          renderItem={(_, index) => (
            <List.Item key={`list-${index}`}>
              <div className="loading-list-stake">
                <div style={{ display: 'flex', transform: 'translate(36%, 10px)' }}>
                  <span style={{ color: '#fff' }}>Loading...</span>
                  <Loading />
                </div>
              </div>
            </List.Item>
          )}
        />
      ) : (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Nodata />
        </div>
      )}
    </div>
  )
}

export default TableTxhMobile
