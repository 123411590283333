/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { List, Table } from 'antd'
import './style.scss'
import Nodata from 'components/Nodata'
import Loading from 'widgets/Loading/Loading'
import ItemInvestmentTransaction from '../ItemInvestmentTransaction'
import PaginationCustomer from 'components/PaginationCustomer'
import columns from './columns'

const responsiveList = { gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }

const InvestmentTransactionHistory = ({ isMobile, transactionsList, txInvestParams, setTxInvestParams }) => (
  // const { listStatus } = useSelector((state) => state.transactions)

  // const optionActions = useMemo(() => {
  //   if (listStatus?.actionTransaction) {
  //     return Object.keys(listStatus?.actionTransaction).map((item) => ({
  //       label: item,
  //       value: item,
  //     }))
  //   }
  //   return undefined
  // }, [listStatus])

  <div className="invest-transaction-history-container">
    {/* <div className="invest-transaction-filter">
          <ControlInputSelect dataSelect={optionActions} defaultValue="All Package" />
        </div> */}
    {isMobile ? (
      <div
        className="invest-transaction-history-item"
        id="scrollableDiv"
        style={{
          position: 'relative',
          height: 500,
          padding: transactionsList?.rows?.length > 0 ? '' : '50px 0',
        }}
      >
        {transactionsList?.rows?.length > 0 ? (
          <>
            <List
              grid={responsiveList}
              dataSource={transactionsList?.rows || []}
              renderItem={(item, index) => (
                <List.Item key={`list-${index}`}>
                  <ItemInvestmentTransaction
                    index={index}
                    NoIndex={txInvestParams.page * txInvestParams.pageSize - 10}
                    historyItem={item}
                  />
                </List.Item>
              )}
              locale={{
                emptyText: transactionsList?.total === 0 ? <div>Empty</div> : <></>,
              }}
            />
            <PaginationCustomer
              total={transactionsList?.total}
              current={transactionsList?.page}
              onChange={(page, pageSize) => {
                setTxInvestParams((prev) => ({
                  ...prev,
                  page,
                  pageSize,
                }))
              }}
            />
          </>
        ) : transactionsList?.rows === undefined ? (
          <List
            dataSource={[{}]}
            grid={responsiveList}
            renderItem={(_, index) => (
              <List.Item key={`list-${index}`}>
                <div className="loading-list-stake">
                  <div style={{ display: 'flex', transform: 'translate(36%, 10px)' }}>
                    <span style={{ color: '#fff' }}>Loading...</span>
                    <Loading />
                  </div>
                </div>
              </List.Item>
            )}
          />
        ) : (
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Nodata />
          </div>
        )}
      </div>
    ) : (
      <>
        <Table dataSource={transactionsList?.rows || []} columns={columns()} scroll={{ x: 450, y: 384 }} pagination={false} />
        <PaginationCustomer
          total={transactionsList?.total}
          current={transactionsList?.page}
          onChange={(page, pageSize) => {
            setTxInvestParams((prev) => ({
              ...prev,
              page,
              pageSize,
            }))
          }}
        />
      </>
    )}
  </div>
)

export default InvestmentTransactionHistory
