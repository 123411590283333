import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import { Form } from 'antd'
import './styles.scss'

import { setAuthToken } from 'helpers'
import AuthCard from 'components/Auth/AuthCard'
import WrapperSignForm from 'modules/auth/components/WrapperSignForm'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { FormattedMessage } from 'react-intl'
import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import VerifyAuthenCode from 'modules/auth/components/VerifyAuthenCode'
import SignInVerifyEmailCode from 'modules/auth/components/SignInVerifyEmailCode'
import { getProfile } from 'state/user/actions'
import useWalletAuth from 'hooks/useAppAuth'

const VIEW = {
  FORM_INPUT: 0,
  VERIFY_CODE: 1,
  VERIFY_2FA: 2,
}

const SignEmail = ({ history, location }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [view, setView] = useState({ view: VIEW.FORM_INPUT, dataView: null })
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const { login } = useWalletAuth()

  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      setLoading(true)
      login(
        values.email,
        values.password,
        (receipt) => {
          if (receipt.is_email) {
            setView({ view: VIEW.VERIFY_CODE, dataView: receipt })
          }
          if (receipt.is_2fa) {
            setView({ view: VIEW.VERIFY_2FA, dataView: receipt })
          }
          setLoading(false)
        },
        (error) => {
          if (error?.code === 'err_email_active_required') {
            history.push('/register', { view: 1, email: values.email })
          }
          setLoading(false)
        },
      )
    })
  }

  const onFinishVerifyCode = useCallback(
    (receipt) => {
      const authToken = setAuthToken(receipt?.token)
      if (authToken) {
        dispatch(
          getProfile({}, () => {
            if (get(location, 'state.from')) {
              const pathName = get(location, 'state.from.pathname')
              const search = get(location, 'state.from.search')
              history.push(`${pathName}${search}`)
            } else {
              history.push('/dashboard')
            }
          }),
        )
      }
    },
    [dispatch, history, location],
  )

  const onBack = useCallback(() => {
    setView({ view: 0, dataView: null })
  }, [])

  return (
    <WrapperSignForm className="wrapper-sign-email">
      {view.view === VIEW.FORM_INPUT && (
        <>
          <AuthCard className="form-login fadeIn">
            <Form layout="vertical" form={form}>
              <FormItem
                label={
                  <span className="form-title">
                    <FormattedMessage id="Email" />
                  </span>
                }
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Enter a valid email address!',
                  },
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter email field" />,
                  },
                ]}
              >
                <Input placeholder="example@gmail.com" />
              </FormItem>
              <FormItem
                label={
                  <div className="wrapper-label">
                    <span className="form-title">
                      <FormattedMessage id="Password" />
                    </span>
                    <Link to="/forgot-password" className="des-title">
                      <FormattedMessage id="Forgot Password ?" />
                    </Link>
                  </div>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter password field" />,
                  },
                  {
                    min: 10,
                    message: (
                      <FormattedMessage id={`The 'password' field length must be greater than or equal to 10 characters long.`} />
                    ),
                  },
                ]}
              >
                <Input placeholder="**********" type={show ? 'text' : 'password'} eyePassWord show={show} setShow={setShow} />
              </FormItem>
              <FormItem>
                <ButtonPrimary
                  type="submit"
                  className="form-action active"
                  style={{
                    maxWidth: 150,
                    height: window.innerWidth < 768 && 48,
                    borderRadius: 10,
                  }}
                  loading={loading}
                  onClick={onFormSubmit}
                >
                  <FormattedMessage id="Sign In" />
                </ButtonPrimary>
              </FormItem>
            </Form>
            <div className="form-links">
              <FormattedMessage id="I Don’t have an account?" />
              <Link to="/register">
                <FormattedMessage id="Sign up" />
              </Link>
            </div>
          </AuthCard>
        </>
      )}
      {view.view === VIEW.VERIFY_CODE && (
        <SignInVerifyEmailCode
          type="login"
          initialField={6}
          token={view.dataView?.token}
          onFinish={onFinishVerifyCode}
          onBack={onBack}
        />
      )}
      {view.view === VIEW.VERIFY_2FA && (
        <VerifyAuthenCode
          type="login"
          initialField={6}
          token={view.dataView?.token}
          onFinish={onFinishVerifyCode}
          onBack={onBack}
        />
      )}
    </WrapperSignForm>
  )
}

export default SignEmail
