/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import './styles.scss'

import { countryCode } from 'emoji-flags'
import Input from 'widgets/Input'
import CheckBox from 'widgets/CheckBox'
import FormItem from 'widgets/FormItem'
import WrapperSignOption from 'modules/auth/components/WrapperSignOption'
import CardSignMessage from 'components/CardSignMessage'
import WrapperSignForm from 'modules/auth/components/WrapperSignForm'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { postSignup } from 'state/auth/actions'
import VerifyActiveAccountCode from 'modules/auth/components/VerifyActiveAccountCode'
import { getListCountry } from 'state/app/actions'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { regexPhone } from 'utils/Regex'
import InputSelect from 'components/InputSelect'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { toast } from 'react-toastify'
import { EyeIcon, EyeSleepIcon } from 'widgets/Svg'
import AuthCard from 'components/Auth/AuthCard'
import { checkDevice } from 'helpers'

const ROOT_SPONSOR = ''

const Signup = ({ intl, history, location }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { listCountry } = useSelector((state) => state.app)
  const [view, setView] = useState(history.location.state?.view || 0)
  const [email, setEmail] = useState(history.location.state?.email || null)
  const [inputCountry, setInputCountry] = useState()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(true)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(true)

  const { account } = useActiveWeb3React()

  useEffect(() => {
    const url = new URLSearchParams(location.search)
    const sponsorKey = url.get('sponsorKey') || url.get('sponsorkey')
    form.setFieldsValue({
      wallet_address: account,
      sponsorKey: sponsorKey || ROOT_SPONSOR,
    })
  }, [location, account])

  useEffect(() => {
    dispatch(getListCountry())
  }, [dispatch])

  const optionsCountry = useMemo(() => {
    if (listCountry) {
      return listCountry.map((item) => {
        const countryEmoji = countryCode(item.code)
        return {
          ...item,
          label: countryEmoji && checkDevice ? `${countryEmoji?.emoji || ''} ${item.title}` : item.title,
          value: item.code,
        }
      })
    }
    return []
  }, [listCountry])

  const onFormSubmit = () => {
    if (!account) return toast.error('Please connect a wallet')

    form.validateFields().then((values) => {
      if (!inputCountry) return
      if (values.password !== values.confirm) return

      setLoading(true)

      delete values.confirm
      const formData = {
        ...values,
        country: inputCountry.code,
        phone_code: inputCountry.phone_code,
      }
      setEmail(values.email)
      executeRecaptcha((recaptcha) => {
        dispatch(
          postSignup(
            { ...formData, recaptcha },
            () => {
              setView(1)
              setLoading(false)
            },
            () => {
              setLoading(false)
            },
          ),
        )
      })
    })
  }

  if (!account) {
    return (
      <WrapperSignOption>
        <CardSignMessage account={account} />
      </WrapperSignOption>
    )
  }
  return (
    <WrapperSignForm>
      <div className="w-form-signUp">
        {view === 0 ? (
          <AuthCard className="form-signUp fadeIn" onBack={() => history.push('/login/email')}>
            <Form layout="vertical" form={form}>
              <FormItem
                label={
                  <span className="form-title">
                    <FormattedMessage id="Wallet Address" />
                  </span>
                }
                name="wallet_address"
              >
                <Input readOnly />
              </FormItem>
              <FormItem
                label={
                  <span className="form-title">
                    <FormattedMessage id="Email" />
                  </span>
                }
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Enter a valid email address!',
                  },
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter email field" />,
                  },
                ]}
              >
                <Input placeholder="example@gmail.com" />
              </FormItem>
              <FormItem
                label={
                  <span className="form-title">
                    <FormattedMessage id="Password" />
                  </span>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter password field" />,
                  },
                  {
                    min: 10,
                    message: (
                      <FormattedMessage id={`The 'password' field length must be greater than or equal to 10 characters long.`} />
                    ),
                  },
                ]}
              >
                <Input
                  placeholder="**********"
                  type={showPassword ? 'password' : 'text'}
                  prefix={
                    showPassword ? (
                      <EyeIcon style={{ cursor: 'pointer' }} fill="red" onClick={() => setShowPassword((prev) => !prev)} />
                    ) : (
                      <EyeSleepIcon style={{ cursor: 'pointer' }} fill="red" onClick={() => setShowPassword((prev) => !prev)} />
                    )
                  }
                />
              </FormItem>
              <FormItem
                label={
                  <span className="form-title">
                    <FormattedMessage id="Confirm Password" />
                  </span>
                }
                name="confirm"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please confirm your password!" />,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(intl.formatMessage({ id: 'The two passwords that you entered do not match!' })),
                      )
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="**********"
                  type={showPasswordConfirm ? 'password' : 'text'}
                  prefix={
                    showPasswordConfirm ? (
                      <EyeIcon style={{ cursor: 'pointer' }} fill="red" onClick={() => setShowPasswordConfirm((prev) => !prev)} />
                    ) : (
                      <EyeSleepIcon
                        style={{ cursor: 'pointer' }}
                        fill="red"
                        onClick={() => setShowPasswordConfirm((prev) => !prev)}
                      />
                    )
                  }
                />
              </FormItem>
              <FormItem
                label={
                  <span className="form-title">
                    <FormattedMessage id="First Name" />
                  </span>
                }
                name="fistname"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter fistname field" />,
                  },
                ]}
              >
                <Input placeholder={intl.formatMessage({ id: 'Please enter your first name' })} type="text" />
              </FormItem>
              <FormItem
                label={
                  <span className="form-title">
                    <FormattedMessage id="Last Name" />
                  </span>
                }
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter lastname field" />,
                  },
                ]}
              >
                <Input placeholder={intl.formatMessage({ id: 'Please enter your last name' })} type="text" />
              </FormItem>
              <FormItem
                label={
                  <span className="form-title-country">
                    <FormattedMessage id="Country" />
                  </span>
                }
                name="country"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter country field" />,
                  },
                ]}
              >
                <InputSelect
                  showSearch
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'Select Country' })}
                  options={optionsCountry}
                  onChange={(code) => {
                    const fCountry = listCountry?.find((country) => country.code === code)
                    setInputCountry(fCountry)
                  }}
                />
              </FormItem>
              <FormItem
                label={
                  <span className="form-title">
                    <FormattedMessage id="Phone Number" />
                  </span>
                }
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="Please enter phone number field" />,
                  },
                  { pattern: regexPhone, message: <FormattedMessage id="Invalid Phone number" /> },
                ]}
              >
                <Input placeholder="8158xxxxx" type="text" phoneCode={inputCountry?.phone_code} />
              </FormItem>
              <FormItem
                label={
                  <span className="form-title">
                    <FormattedMessage id="Referral Code" />
                  </span>
                }
                name="sponsorKey"
              >
                <Input placeholder={intl.formatMessage({ id: 'Sponsor key' })} type="text" />
              </FormItem>
              <FormItem
                className="form-item-checkbox"
                name="agreement"
                formType="checkbox"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement'))),
                  },
                ]}
              >
                <CheckBox>
                  <FormattedMessage id="I have read and agree to User Agreement and Privacy Policy." />
                </CheckBox>
              </FormItem>
              <FormItem>
                <ButtonPrimary
                  type="submit"
                  className="form-action active"
                  style={{
                    maxWidth: window.innerWidth > 768 ? '150px' : '100%',
                    height: window.innerWidth < 768 && '48px',
                    borderRadius: '10px',
                  }}
                  loading={loading}
                  onClick={onFormSubmit}
                >
                  <FormattedMessage id="Sign up" />
                </ButtonPrimary>
              </FormItem>
            </Form>

            <div className="form-links">
              <span>
                <FormattedMessage id="Already have Account." />
              </span>
              <Link to="/login">
                <FormattedMessage id="Sign In" />
              </Link>
            </div>
          </AuthCard>
        ) : (
          <VerifyActiveAccountCode email={email} token={view?.data?.token} />
        )}
      </div>
    </WrapperSignForm>
  )
}

export default injectIntl(Signup)
