import produce from 'immer'
import { CACHE_USER_INFO } from 'constants/index'
import * as types from './constants'

const initialState = {
  userInfo: JSON.parse(localStorage.getItem(CACHE_USER_INFO)),
  customerDetail: {},
  customerSummaryFloor: undefined,
}

export default function UserReducer(state = initialState, action) {
  const { payload, params } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_PROFILE_SUCCESS:
        localStorage.setItem(CACHE_USER_INFO, JSON.stringify(payload))
        draft.userInfo = payload
        break
      case types.GET_CUSTOMER_DETAILS_SUCCESS:
        draft.customerDetail[params.customer_id] = payload
        break
      case types.GET_CUSTOMER_SUMMARY_FLOOR_SUCCESS:
        draft.customerSummaryFloor = payload
        break

      default:
        return { ...state }
    }
  })
}
