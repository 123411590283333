import React, { useEffect, useState } from 'react'
import './styles.scss'

import TableWalletHistoryDesktop from '../TableWalletHistoryDesktop'
import ModalViewDetails from '../ModalViewDetails'
import { isMobile } from 'react-device-detect'
import WalletHistoryMobile from '../WalletHistoryMobile'
import { FormattedMessage } from 'react-intl'

const RecentTransaction = ({ txParams, setTxParams, transactionsList, setModalVerifyCode }) => {
  const [viewDetails, setViewDetails] = useState({ toggle: false, dataModal: null })
  const [loading] = useState(false)

  const loadMoreData = () => {
    if (loading) {
      return
    }

    setTxParams((prev) => ({
      ...prev,
      pageSize: prev.pageSize + 10,
    }))
  }
  useEffect(() => {
    loadMoreData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="wallet-box-recent-transaction">
      <h2 className="recent-transaction-title">
        <FormattedMessage id="Recent Transaction" />
      </h2>
      {isMobile ? (
        <WalletHistoryMobile
          txParams={txParams}
          setTxParams={setTxParams}
          total={transactionsList.total}
          current={transactionsList?.page}
          dataSource={transactionsList?.rows || []}
          loadMoreData={loadMoreData}
          setViewDetails={setViewDetails}
          setModalVerifyCode={setModalVerifyCode}
        />
      ) : (
        <TableWalletHistoryDesktop
          total={transactionsList.total}
          dataSource={transactionsList?.rows || []}
          txParams={txParams}
          setTxParams={setTxParams}
          setModalVerifyCode={setModalVerifyCode}
          setViewDetails={setViewDetails}
        />
      )}
      <ModalViewDetails visible={viewDetails.toggle} dataModal={viewDetails?.dataModal} close={setViewDetails} />
    </div>
  )
}

export default RecentTransaction
