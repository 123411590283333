/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { List, Table } from 'antd'
import './style.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from 'widgets/Loading/Loading'
import Nodata from 'components/Nodata'
import ItemTxhBoughtMobile from './ItemTxhBoughtMobile'
import columns from './columns'
import PaginationCustomer from 'components/PaginationCustomer'
import ControlInputSelect from 'components/ControlInputSelect'

const responsiveList = { gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }
const InvestmentBoughtTransaction = ({ isMobile, historyInvestment, txBoughtParams, setTxBoughtParams, onSelectPackage }) => {
  const { currency } = useSelector((state) => state.wallets)
  const { investmentList } = useSelector((state) => state.invest)

  const optionActions = useMemo(() => {
    if (investmentList?.rows) {
      return investmentList.rows.map((item) => ({
        label: item.title,
        value: item._id,
      }))
    }
    return undefined
  }, [investmentList])

  return (
    <div className="invest-bought-history-container">
      <div className="invest-transaction-filter">
        <ControlInputSelect
          dataSelect={optionActions}
          defaultValue="All Package"
          onSelect={(value) => onSelectPackage(JSON.parse(value))}
        />
      </div>
      {isMobile ? (
        <div
          id="scrollableDiv"
          style={{
            position: 'relative',
            height: 500,
            padding: historyInvestment?.rows?.length > 0 ? '10px' : '50px 0',
          }}
        >
          {historyInvestment?.rows?.length > 0 ? (
            <>
              <List
                dataSource={historyInvestment?.rows || []}
                grid={responsiveList}
                renderItem={(item, index) => (
                  <List.Item key={`list-${index}`}>
                    <ItemTxhBoughtMobile index={index} historyItem={item} currency={currency} />
                  </List.Item>
                )}
                locale={{
                  emptyText: historyInvestment?.total === 0 ? <div>Empty</div> : <></>,
                }}
              />
              <PaginationCustomer
                total={historyInvestment?.total}
                current={historyInvestment?.page}
                onChange={(page, pageSize) => {
                  setTxBoughtParams((prev) => ({
                    ...prev,
                    page,
                    pageSize,
                  }))
                }}
              />
            </>
          ) : historyInvestment?.rows === undefined ? (
            <List
              dataSource={[{}]}
              grid={responsiveList}
              renderItem={(_, index) => (
                <List.Item key={`list-${index}`}>
                  <div className="loading-list-stake">
                    <div style={{ display: 'flex', transform: 'translate(36%, 10px)' }}>
                      <span style={{ color: '#fff' }}>Loading...</span>
                      <Loading />
                    </div>
                  </div>
                </List.Item>
              )}
            />
          ) : (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Nodata />
            </div>
          )}
        </div>
      ) : (
        <>
          <Table
            dataSource={historyInvestment?.rows || []}
            columns={columns({ currency })}
            scroll={{ x: 450, y: 384 }}
            pagination={false}
          />
          <PaginationCustomer
            total={historyInvestment?.total}
            current={historyInvestment?.page}
            onChange={(page, pageSize) => {
              setTxBoughtParams((prev) => ({
                ...prev,
                page,
                pageSize,
              }))
            }}
          />
        </>
      )}
    </div>
  )
}

export default InvestmentBoughtTransaction
