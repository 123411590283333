import React from 'react'
import './style.scss'
import { Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from 'state/app/actions'

const { Option } = Select

const Languages = ({ ...props }) => {
  const dispatch = useDispatch()
  const { optionLanguage, language } = useSelector((state) => state.app)
  const handleChangeLang = (lang) => {
    dispatch(setLanguage({ language: lang }))
  }
  return (
    <Select
      className="header-language"
      defaultValue={language}
      onChange={handleChangeLang}
      dropdownClassName="header-language-dropdown"
      dropdownMatchSelectWidth={80}
      {...props}
    >
      {optionLanguage?.map((item, index) => (
        <Option value={item.id} key={`language-${index}`}>
          {item.id && <p>{item.label}</p>}
          <img className="header-language-img-default" src="/images/icons/language-earth.png" alt="" />
        </Option>
      ))}
    </Select>
  )
}

export default Languages
