import Nodata from 'components/Nodata'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import './style.scss'
import CurrencyFormat from 'react-currency-format'
import { roundNumber } from 'helpers/index'

const ItemMemberDesktop = ({ customerSummaryFloor }) => (
  <div className="table-item-member-desktop">
    <table className="table-item-member-desktop-table">
      <thead>
        <tr>
          <th className="table-heading-item-member">
            <FormattedMessage id="Type Member" />
          </th>
          <th className="table-heading-item-member">
            <FormattedMessage id="Total Member" />
          </th>
          <th className="table-heading-item-member">
            <FormattedMessage id="Total Volume" />
          </th>
          <th className="table-heading-item-member">
            <FormattedMessage id="Total Commission" />
          </th>
        </tr>
      </thead>

      <tbody className="network-wrap-table-tbody">
        {customerSummaryFloor?.map((item, index) => (
          <tr key={`item-${index}`}>
            <td className="table-td-item-member">{`${
              item?.floor === 1 ? 'Diamond' : (item?.floor === 2 && 'Platinum') || (item?.floor === 3 && 'Gold')
            } `}</td>
            <td className="table-td-item-member">{item?.total_member}</td>
            <td className="table-td-item-member">
              <span>
                <CurrencyFormat
                  value={roundNumber(item?.total_volume / 1e18, 2)}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </td>
            <td className="table-td-item-member">
              <CurrencyFormat
                value={roundNumber(item?.total_commission / 1e18, 2)}
                displayType="text"
                thousandSeparator
                prefix="$"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default ItemMemberDesktop
