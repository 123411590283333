import { Pagination } from 'antd'
import React from 'react'
import './style.scss'

const PaginationCustomer = ({ style = {}, ...props }) => (
  <div className="pagination-wrapper-customer" style={style}>
    <Pagination showQuickJumper showSizeChanger={false} {...props} />
  </div>
)

export default PaginationCustomer
