import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ProfileAccount from 'components/ProfileAccount'
import './styles.scss'
import TotalBalance from 'components/TotalBalance'
import MenuCollapse from '../MenuCollapse'

const HeaderMobile = ({ isMobile, toggleMenu }) => {
  const { userInfo } = useSelector((state) => state.user)

  return (
    <header className="w-header-mobile">
      <div className="header-body" style={{ height: isMobile ? 80 : 100 }}>
        <div className="header-left">
          <Link to="/">
            <img className="header-logo" src="/images/logo.png" alt="" />
          </Link>
        </div>
        <div className="header-right">
          {userInfo && window.innerWidth > 320 && <TotalBalance />}

          {userInfo && <ProfileAccount hideName={isMobile} style={{ marginLeft: window.innerWidth >= 768 ? '23px' : '10px' }} />}

          <MenuCollapse toggleMenu={toggleMenu} style={{ marginLeft: 10 }} />
        </div>
      </div>
    </header>
  )
}
export default HeaderMobile
