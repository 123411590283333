import { all, takeLatest, call, put } from 'redux-saga/effects'
import qs from 'query-string'
import { fetchHelper } from 'helpers'
import { ROOT_API_URL } from 'constants/index'
import * as types from './constants'
import * as actions from './actions'

function getInvestmentListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/investPackage/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getInvestmentList({ payload }) {
  try {
    const { data } = yield call(getInvestmentListFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getInvestmentListSuccess(data.data))
    }
  } catch (error) {
    console.error('InvestmentList', error)
    yield put(actions.getInvestmentListSuccess(null))
  }
}

/*  */
function investBuyNftFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/investment/invest`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* investBuyNft({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(investBuyNftFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    if (cbe) cbe(error)
  }
}

/*  */
function getHistoryInvestmentFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/investment/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getHistoryInvestment({ payload }) {
  try {
    const { data } = yield call(getHistoryInvestmentFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getHistoryInvestmentSuccess(data.data))
    }
  } catch (error) {
    console.error('getHistoryInvestment', error)
    yield put(actions.getHistoryInvestmentSuccess(null))
  }
}

/*  */
function getInvestmentDetailFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/investment/detail?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getInvestmentDetail({ payload }) {
  try {
    const { data } = yield call(getInvestmentDetailFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getInvestmentDetailSuccess(data.data))
    }
  } catch (error) {
    console.error('getInvestmentDetail', error)
    yield put(actions.getInvestmentDetailSuccess(null))
  }
}

/*  */
function signInvestFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/investment/signInvest`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* signInvest({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(signInvestFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    if (cbe) cbe(error)
  }
}

/*  */
function submitSAFTFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/investment/submitSAFT`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* submitSAFT({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(submitSAFTFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    if (cbe) cbe(error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.GET_INVESTMENT_LIST, getInvestmentList),
    takeLatest(types.INVEST_BUY_NFT, investBuyNft),
    takeLatest(types.GET_HISTORY_INVESTMENT, getHistoryInvestment),
    takeLatest(types.GET_INVESTMENT_DETAIL, getInvestmentDetail),
    takeLatest(types.SIGN_INVEST, signInvest),
    takeLatest(types.SUBMIT_SAFT, submitSAFT),
  ])
}
