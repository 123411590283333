import produce from 'immer'
import * as Types from './constants'

const initialState = {
  listF1: [],
  infoF1: null,
  totalReport: undefined,
  investmentStatisticReport: undefined,
}
export default function NetWorks(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_LIST_F1_SUCCESS:
        draft.listF1 = payload
        break
      case Types.GET_INFO_F1:
        draft.infoF1 = payload
        break
      case Types.GET_TOTAL_REPORT_SUCCESS:
        draft.totalReport = payload
        break
      case Types.INVESTMENT_STATISTIC_REPORT_SUCCESS:
        draft.investmentStatisticReport = payload
        break
      default:
        return { ...state }
    }
  })
}
