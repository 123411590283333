import React from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import './styles.scss'
import { Form } from 'antd'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import FormItem from 'widgets/FormItem'
import { requestUpdateWalletAddress } from 'state/auth/actions'
import Input from 'widgets/Input'
import ModalCustom from 'components/BaseModal'

const ModalUpdateWalletAddress = ({ visible, dataModal, modalType, onCancel, setModalNext, ...props }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        executeRecaptcha((recaptcha) => {
          dispatch(
            requestUpdateWalletAddress(
              {
                wallet_address: values.address_wallet,
                recaptcha,
              },
              () => {
                setModalNext((prev) => ({
                  ...prev,
                  toggle: true,
                  dataModal: {
                    address_wallet: values.address_wallet,
                  },
                }))
              },
              () => {
                onCancel()
              },
            ),
          )
        })
      })
      .then(() => onCancel())
  }

  const handleCancel = () => {
    onCancel()
  }

  return (
    <ModalCustom
      title={
        <div
          className="modal-update-wallet-address"
          style={{ textAlign: 'center', fontSize: window.innerWidth >= 768 ? '32px' : '24px' }}
        >
          <FormattedMessage id="Change wallet" />
        </div>
      }
      className="modal-update-wallet-address"
      visible={visible}
      onOke={handleOk}
      onCancel={handleCancel}
      {...props}
    >
      <Form layout="vertical" form={form} onFinish={handleOk}>
        <FormItem
          label={
            <span>
              <FormattedMessage id="Enter your new wallet" />
            </span>
          }
          name="address_wallet"
          rules={[
            {
              required: true,
              message: 'Please enter wallet address field',
            },
          ]}
        >
          <Input placeholder="0x000000000000000000000000000000000000" />
        </FormItem>
      </Form>
    </ModalCustom>
  )
}

export default ModalUpdateWalletAddress
