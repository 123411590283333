/* eslint-disable no-param-reassign */
import numeral from 'numeral'
import { roundTo } from 'round-to'
import BigNumber from 'bignumber.js'

export const isNumber = (value) => {
  if (value !== undefined && value !== null && !Number.isNaN(value)) {
    return true
  }
  return false
}

// ================================================================================
export function roundNumber(n, options) {
  const { scale = 2, decimals } = options || {
    scale: 2,
  }
  if (!isNumber(n)) return 0
  if (n && decimals) {
    n = new BigNumber(n).shiftedBy(-decimals).toNumber()
  }
  if (+n > 1e17) return Math.round(+n)
  const num = typeof +n !== 'number' ? 0 : parseFloat(n)
  if (!`${num}`.includes('e')) {
    return +`${Math.floor(`${num}e+${scale}`)}e-${scale}`
  }
  const arr = `${num}`.split('e')
  let sig = ''
  if (+arr[1] + scale > 0) {
    sig = '+'
  }
  return +`${Math.floor(`${+arr[0]}e${sig}${+arr[1] + scale}`)}e-${scale}`
}

// ================================================================================
export function formatNumber(nb, options) {
  const { scale = 3, decimals } = options || {
    scale: 3,
  }
  if (!isNumber(nb)) return 0
  if (nb && decimals) {
    nb = new BigNumber(nb).shiftedBy(-decimals).toNumber()
  }

  const n = roundTo.up(parseFloat(nb), scale)

  const sign = +n < 0 ? '-' : ''
  const toStr = n.toString()
  if (!/e/i.test(toStr)) {
    return n
  }
  const [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./)
  return +pow < 0
    ? `${sign}0.${'0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0))}${lead}${decimal}`
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : `${decimal.slice(0, +pow)}.${decimal.slice(+pow)}`)
}

// ================================================================================
// Returns first 2 digits after first non-zero decimal
// i.e. 0.001286 -> 0.0012, 0.9845 -> 0.98, 0.0102 -> 0.010, etc
// Intended to be used for tokens whose value is less than $1
// https://stackoverflow.com/a/23887837
export const getFirstThreeNonZeroDecimals = (value) => value.toFixed(9).match(/^-?\d*\.?0*\d{0,2}/)[0]

// export type formatAmountNotation = 'compact' | 'standard'

/**
 * This function is used to format token prices, liquidity, amount of tokens in TX, and in general any numbers on info section
 * @param amount - amount to be formatted
 * @param notation - whether to show 1M or 1,000,000
 * @param displayThreshold - threshold below which it will return simply <displayThreshold instead of actual value, e.g. if 0.001 -> returns <0.001 for 0.0005
 * @param tokenPrecision - set to true when you want precision to be 3 decimals for values < 1 and 2 decimals for values > 1
 * @param isInteger - if true the values will contain decimal part only if the amount is > 1000
 * @returns formatted string ready to be displayed
 */
export const formatAmount = (amount, options) => {
  const { notation = 'compact', displayThreshold, tokenPrecision, isInteger, roundUp, decimals, scale } = options || {
    notation: 'compact',
  }
  if (amount === 0) {
    if (isInteger) {
      return '0'
    }
    return '0.00'
  }
  if (!amount) return '--'
  if (amount && decimals) {
    amount = new BigNumber(amount).shiftedBy(-decimals).toNumber()
  }
  if (displayThreshold && amount < displayThreshold) {
    return `<${displayThreshold}`
  }
  if (amount < 1 && !tokenPrecision) {
    return getFirstThreeNonZeroDecimals(amount)
  }

  let precision = 2
  if (tokenPrecision) {
    precision = amount < 1 ? 3 : 2
  }

  let format = `0,0.${'0'.repeat(precision)}a`

  if (notation === 'standard') {
    format = `0,0.${'0'.repeat(precision)}`
  }

  if (scale) {
    format = `0,0.[${'0'.repeat(precision)}]a`
  }

  if (isInteger && amount < 1000) {
    format = '0'
  }

  /**
   * @dev Check round down
   * Not working with number => 1e20
   */
  if (amount > 999 && !roundUp) {
    const amountStr = Math.trunc(amount).toString()
    const i = Math.floor(Math.log(amount) / Math.log(1000))
    const fistCount = Math.floor(amount / 1000 ** i).toString().length + precision
    amount = Number(amountStr.substring(0, fistCount) + '0'.repeat(amountStr.substring(fistCount, amountStr.length).length))
  }

  const amountWithPrecision = parseFloat(amount?.toFixed(precision))

  // toUpperCase is needed cause numeral doesn't have support for capital K M B out of the box
  return numeral(amountWithPrecision).format(format).toUpperCase()
}
