import { FormattedMessage } from 'react-intl'
import {
  WalletIcon,
  DashboardIcon,
  InvestmentIcon,
  StakingIcon,
  ProfileIcon,
  NetworkIcon,
  AccountIcon,
  KeyIcon,
  MyNftIcon,
  ExchangeIcon,
  SecurityIcon,
} from 'widgets/Svg'

export const linksPublic = [
  {
    label: <FormattedMessage id="Dashboard" />,
    href: '/dashboard',
    target: '_blank',
  },
  {
    label: <FormattedMessage id="Service" />,
    href: '/service',
    target: '',
  },
  {
    label: <FormattedMessage id="About Us" />,
    href: 'https://linktr.ee/openlivenft.com',
    target: '_blank',
  },
  {
    label: <FormattedMessage id="Contact Us" />,
    href: '',
    target: '_blank',
  },
]

export const linksPrivate = [
  {
    label: 'Dashboard',
    href: '/dashboard',
    target: '',
    icon: DashboardIcon,
  },
  {
    label: 'Investment',
    href: '/investment',
    icon: InvestmentIcon,
    children: [
      {
        label: 'My NFTs',
        href: '/investment/my-nft',
        target: '',
        icon: MyNftIcon,
      },
    ],
  },
  {
    label: 'Exchange',
    href: '/exchange',
    target: '',
    icon: ExchangeIcon,
  },
  {
    label: 'Stake OPV',
    href: 'https://dapp.openlivenft.io/staking',
    target: '_blank',
    icon: '/images/icons/stake.png',
  },
  {
    label: 'Network',
    href: '/network',
    target: '',
    icon: NetworkIcon,
  },
  {
    label: 'Wallet',
    href: '/wallet',
    target: '',
    icon: WalletIcon,
  },
  {
    label: 'Profile',
    href: '#',
    target: '',
    icon: ProfileIcon,
    children: [
      {
        label: 'Account',
        href: '/profile/account',
        target: '',
        icon: AccountIcon,
      },
      {
        label: 'Security',
        href: '/profile/security',
        target: '',
        icon: KeyIcon,
      },
    ],
  },
]

export const linksNavigatorMobile = [
  {
    label: 'Dashboard',
    href: '/dashboard',
    target: '',
    icon: DashboardIcon,
  },
  {
    label: 'Investment',
    href: '/investment',
    target: '',
    icon: InvestmentIcon,
  },
  {
    label: 'Stake OPV',
    href: 'https://dapp.openlivenft.io/staking',
    target: '_blank',
    icon: '/images/icons/stake.png',
  },
  {
    label: 'Exchange',
    href: '/exchange',
    target: '',
    icon: ExchangeIcon,
  },
  {
    label: 'Network',
    href: '/network',
    target: '',
    icon: NetworkIcon,
  },
  {
    label: 'Wallet',
    href: '/wallet',
    target: '',
    icon: WalletIcon,
  },
  {
    label: 'Profile',
    href: '/profile',
    target: '',
    icon: ProfileIcon,
  },
]

export const linkDropdownAccount = [
  {
    label: <FormattedMessage id="Profile" />,
    href: '/profile',
    target: '',
    icon: ProfileIcon,
    subMenu: [],
  },
  {
    label: <FormattedMessage id="Wallet" />,
    href: '/wallet',
    target: '',
    icon: WalletIcon,
    subMenu: [],
  },
  {
    label: <FormattedMessage id="My NFTs" />,
    href: '/investment/my-nft',
    target: '',
    icon: MyNftIcon,
    subMenu: [],
  },
  {
    label: <FormattedMessage id="Security" />,
    href: '/security',
    target: '',
    icon: SecurityIcon,
    subMenu: [],
  },
]
