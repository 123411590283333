import { all, takeLatest, call } from 'redux-saga/effects'
import { ROOT_API_URL } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'
import * as types from './constants'

function postExchangeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/transaction/exchange`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* postExchange({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(postExchangeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) {
      cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('postExchange', error)
  }
}
export default function* rootSaga() {
  yield all([takeLatest(types.POST_EXCHANGE, postExchange)])
}
