import React from 'react'
import './Wbody.style.scss'

const WBody = ({ className, children, ...props }) => (
  <div className={`wBody ${className}`} {...props}>
    {children}
  </div>
)
WBody.defaultProps = {
  className: '',
}

export default WBody
