import React from 'react'
import { Modal } from 'antd'
import './styles.scss'
import ButtonPrimary from 'widgets/ButtonPrimary'

const ModalCustom = ({ className, onOke, onCancel, loading, children, buttonLabel, buttonLabelDone, ...props }) => (
  <Modal
    className={`wCustomModal ${className}`}
    centered
    footer={
      onOke ? (
        <>
          <ButtonPrimary className="modal-2fa-cancel" roles="presentation" loading={loading} onClick={onCancel}>
            {buttonLabel}
          </ButtonPrimary>
          <ButtonPrimary className="active modal-2fa-done" roles="presentation" loading={loading} onClick={onOke}>
            {buttonLabelDone}
          </ButtonPrimary>
        </>
      ) : (
        ''
      )
    }
    onCancel={onCancel}
    {...props}
  >
    {children}
  </Modal>
)

ModalCustom.defaultProps = {
  loading: false,
  buttonLabel: 'BACK',
  buttonLabelDone: 'DONE',
}

export default ModalCustom
