import React from 'react'
import './style.scss'

const TxStatus = ({ status, title, ...props }) => (
  <div className="wTxStatus" data-status={status} {...props}>
    {title}
  </div>
)

export default TxStatus
