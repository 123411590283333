import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import QRcode from 'qrcode.react'
import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import InputCode from 'components/InputCode'
import ModalCustom from 'components/BaseModal'
import { update2FA, verify2FA } from 'state/auth/actions'
import { getProfile } from 'state/user/actions'
import InputCopy from 'components/InputCopy'
import { FormattedMessage } from 'react-intl'

export const MODAlTYPE = {
  CREATE: 'create',
  VERIFY: 'verify',
  UPDATE: 'update',
}
const initialField = 6

/**
 * @param {*} dataModal.code if is required for show QR Code
 * @param {*} modalType type of dispatch
 */

const ModalTurnOn2FA = ({ visible, dataModal, modalType, onCancel, ...props }) => {
  const dispatch = useDispatch()

  const { userInfo } = useSelector((state) => state.user)
  const [code, onChangeCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')

  const handleCancel = () => {
    setErrorMess('')
    setLoading(false)
    onChangeCode('')
    onCancel()
  }

  const handleOk = () => {
    if (code.length < initialField) return setErrorMess(<FormattedMessage id="An occurred error, Please try again late." />)
    setLoading(true)
    setErrorMess('')

    switch (modalType) {
      case MODAlTYPE.VERIFY:
        executeRecaptcha((recaptcha) => {
          dispatch(
            verify2FA(
              {
                token: code,
                recaptcha,
              },
              () => {
                setLoading(false)
                handleCancel()
                dispatch(getProfile())
              },
              () => {
                setLoading(false)
              },
            ),
          )
        })
        break
      case MODAlTYPE.UPDATE:
        executeRecaptcha((recaptcha) => {
          dispatch(
            update2FA(
              {
                token: code,
                status: userInfo.status_2fa === 2,
                recaptcha,
              },
              () => {
                setLoading(false)
                handleCancel()
                dispatch(getProfile())
              },
              () => {
                setLoading(false)
                dispatch(getProfile())
              },
            ),
          )
        })
        break
      default:
        setLoading(false)
        dispatch(getProfile())
        handleCancel()
        break
    }
  }

  const renderModalDescription = (type) => {
    switch (type) {
      case MODAlTYPE.VERIFY:
        return (
          <div className="gg-authenticator-notice">
            <FormattedMessage id="Please enter 6 characters in the Authenticator app to " />
            <span className="gg-authenticator-notice-enable">
              <FormattedMessage id="enable" />
            </span>
          </div>
        )
      case MODAlTYPE.UPDATE:
        return (
          <>
            <div className="gg-authenticator-notice">
              <FormattedMessage id="Please enter 6 characters in the Authenticator app to " />
              <span className="gg-authenticator-notice-enable">
                {userInfo?.status_2fa === 1 ? <FormattedMessage id="disable" /> : <FormattedMessage id="enable" />}
              </span>
            </div>
          </>
        )
      default:
        return <FormattedMessage id="Get code from your email" />
    }
  }

  return (
    <ModalCustom
      title={
        <div
          className="modal-verify-cody-header"
          style={{ textAlign: 'center', fontSize: window.innerWidth >= 768 ? '32px' : '24px' }}
        >
          <FormattedMessage id="Google Authenticator" />
        </div>
      }
      className="modal-2fa"
      visible={visible}
      loading={loading}
      onOke={handleOk}
      onCancel={handleCancel}
      {...props}
    >
      {dataModal?.data && (
        <>
          <div className="canvas-qrcode">
            <QRcode size={200} value={dataModal.data?.toString()} />
          </div>
          <p>
            <FormattedMessage id="Powered by" /> <span className="powered-by">OPENLIVE</span>
          </p>
          <div className="heading-input-coppy">
            <FormattedMessage id="Save your secret key" />
          </div>
        </>
      )}

      {dataModal?.code && <InputCopy value={dataModal.code} />}
      <div className="text-note">{renderModalDescription(modalType)}</div>
      <InputCode value={code} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
    </ModalCustom>
  )
}

export default ModalTurnOn2FA
