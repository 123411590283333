import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from 'components/Menu/Footer'
import './style.scss'

const Body = ({ isLogin, isMobile, showMenu, linksPublic, toggleMenu, children }) => {
  const location = useLocation()
  return (
    <div className="app-body-container">
      <div className="app-body-body">
        <div className="app-body-content">{children}</div>
        {!isMobile && (
          <Footer
            links={linksPublic}
            location={location}
            isMobile={isMobile}
            isLogin={isLogin}
            showMenu={showMenu}
            toggleMenu={toggleMenu}
          />
        )}
      </div>
    </div>
  )
}

export default Body
