import './styles.scss'
import React from 'react'

const WalletLogin = ({ pathNameLogin, pathNameRegister, history }) => (
  <div className="auth-content-left-btn-img-wrapper">
    {!pathNameLogin && (
      <img
        className="auth-content-left-btn-img"
        src="/images/icons/returnBtn.png"
        alt=""
        role="presentation"
        onClick={() => history.push('/login')}
      />
    )}
    <div className="box-wallet" style={{ display: pathNameRegister && window.innerWidth < 768 && 'none' }}>
      <div className="wrap-logo">
        <img className="lamp" src="/images/lamp/lamp.png" alt="" />
        <img className="lamp-light" src="/images/lamp/light.png" alt="" />
        <img className="lamp-glasses" src="/images/lamp/glasses.png" alt="" />
      </div>

      {!pathNameRegister && (
        <div className="wrap-logo-mb">
          <img className="logo" src="/images/logo-collabrx-psd.png" alt="" />
        </div>
      )}
    </div>
  </div>
)

export default React.memo(WalletLogin)
