import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './styles.scss'
import { Form } from 'antd'
import FormItem from 'widgets/FormItem'
import AuthCard from 'components/Auth/AuthCard'
import { forgotPassword } from 'state/auth/actions'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import Input from 'widgets/Input'

const FormResetPassword = ({ intl, email, code, onFinish, ...props }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    form.validateFields().then((values) => {
      setLoading(true)
      executeRecaptcha((recaptcha) => {
        dispatch(
          forgotPassword(
            {
              ...values,
              email: `${email}`,
              code,
              recaptcha,
            },
            () => {
              setLoading(false)
              onFinish()
            },
            () => {
              setLoading(false)
            },
          ),
        )
      })
    })
  }

  return (
    <AuthCard className="forgot-reset-pass fadeIn" {...props}>
      <h2 className="verify-title">New Password</h2>
      <Form layout="vertical" form={form}>
        <FormItem
          label={<span className="title-verify-code-forgot">New Password</span>}
          name="new_password"
          rules={[
            {
              required: true,
              message: <FormattedMessage id="Please enter password field" />,
            },
            {
              min: 10,
              message: (
                <FormattedMessage id={`The 'password' field length must be greater than or equal to 10 characters long.`} />
              ),
            },
          ]}
        >
          <Input
            placeholder="**********"
            type="password"
            // prefix={
            //   showPassword ? (
            //     <EyeIcon style={{ cursor: 'pointer' }} fill="red" onClick={() => setShowPassword((prev) => !prev)} />
            //   ) : (
            //     <EyeSleepIcon style={{ cursor: 'pointer' }} fill="red" onClick={() => setShowPassword((prev) => !prev)} />
            //   )
            // }
          />
        </FormItem>

        <FormItem
          label={
            <span className="title-verify-code-forgot">
              <FormattedMessage id="Confirm password" />
            </span>
          }
          name="confirm"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: <FormattedMessage id="Please confirm your password!" />,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(intl.formatMessage({ id: 'The two passwords that you entered do not match!' })))
              },
            }),
          ]}
        >
          <Input
            placeholder="**********"
            type="password"
            // prefix={
            //   showPasswordConfirm ? (
            //     <EyeIcon style={{ cursor: 'pointer' }} fill="red" onClick={() => setShowPasswordConfirm((prev) => !prev)} />
            //   ) : (
            //     <EyeSleepIcon
            //       style={{ cursor: 'pointer' }}
            //       fill="red"
            //       onClick={() => setShowPasswordConfirm((prev) => !prev)}
            //     />
            //   )
            // }
          />
        </FormItem>
        <div className="verify-action-forgot">
          <ButtonPrimary className="active" loading={loading} onClick={onSubmit}>
            Change
          </ButtonPrimary>
        </div>
      </Form>
    </AuthCard>
  )
}

export default FormResetPassword
