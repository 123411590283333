import produce from 'immer'
import * as Types from './constants'

const initialState = {
  investmentList: undefined,
  historyInvestment: undefined,
  investmentDetail: {},
}

export default function Investment(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_INVESTMENT_LIST_SUCCESS:
        draft.investmentList = payload
        break
      case Types.GET_HISTORY_INVESTMENT_SUCCESS:
        draft.historyInvestment = payload
        break
      case Types.GET_INVESTMENT_DETAIL_SUCCESS:
        draft.investmentDetail = {
          ...draft.investmentDetail,
          [payload._id]: payload,
        }
        break
      default:
        return { ...state }
    }
  })
}
