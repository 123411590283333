import React, { useState } from 'react'
import './style.scss'
import { linksPublic } from 'components/Menu/config'
import HeaderDesktop from 'components/Menu/HeaderDesktop'
import useIsMobile from 'hooks/useIsMobile'
import HeaderMobile from 'components/Menu/HeaderMobile'
import Footer from 'components/Menu/Footer'
import { useLocation } from 'react-router-dom'

const PublicLayout = ({ isLogin, children }) => {
  const isMobile = useIsMobile()
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    // document.querySelector('body').style.overflow = showMenu ? 'unset' : 'hidden'
    setShowMenu((prev) => !prev)
  }

  return (
    <div className="w-layout-public">
      {/* <img className="linear-block-1" src="/images/linear-block.png " alt="" /> */}
      {/* <img className="linear-block-2" src="/images/linear-block-1.png " alt="" /> */}
      {isMobile ? (
        <HeaderMobile links={linksPublic} isMobile={isMobile} isLogin={isLogin} showMenu={showMenu} toggleMenu={toggleMenu} />
      ) : (
        <HeaderDesktop links={linksPublic} isMobile={isMobile} isLogin={isLogin} showMenu={showMenu} toggleMenu={toggleMenu} />
      )}
      <div className="public-layout-body">{children}</div>
      {!isMobile && (
        <Footer
          links={linksPublic}
          location={location}
          isMobile={isMobile}
          isLogin={isLogin}
          showMenu={showMenu}
          toggleMenu={toggleMenu}
        />
      )}
    </div>
  )
}

export default PublicLayout
