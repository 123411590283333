import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="17" height="10" viewBox="0 0 17 10" fill="none" {...props}>
    <path
      d="M0.374196 0.382399C0.613866 0.137549 0.938885 1.6416e-07 1.27778 2.23414e-07C1.61667 2.82668e-07 1.94169 0.137549 2.18136 0.382399L8.50772 6.84743L14.8341 0.382401C15.0751 0.144491 15.398 0.0128462 15.7331 0.015822C16.0682 0.0187978 16.3887 0.156156 16.6257 0.398311C16.8626 0.640467 16.997 0.968044 17 1.31049C17.0029 1.65294 16.874 1.98285 16.6412 2.22918L9.4113 9.6176C9.17163 9.86245 8.84661 10 8.50772 10C8.16882 10 7.8438 9.86245 7.60413 9.6176L0.374196 2.22918C0.134598 1.98425 -2.88864e-07 1.65211 -2.28311e-07 1.30579C-1.67758e-07 0.959466 0.134598 0.627323 0.374196 0.382399Z"
      fill="white"
    />
  </Svg>
)

export default Icon
