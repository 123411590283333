import * as types from './constants'

export const getInvestmentList = (payload) => ({
  type: types.GET_INVESTMENT_LIST,
  payload,
})
export const getInvestmentListSuccess = (payload) => ({
  type: types.GET_INVESTMENT_LIST_SUCCESS,
  payload,
})

export const getInvestmentDetail = (payload) => ({
  type: types.GET_INVESTMENT_DETAIL,
  payload,
})
export const getInvestmentDetailSuccess = (payload) => ({
  type: types.GET_INVESTMENT_DETAIL_SUCCESS,
  payload,
})

export const getHistoryInvestment = (payload) => ({
  type: types.GET_HISTORY_INVESTMENT,
  payload,
})
export const getHistoryInvestmentSuccess = (payload) => ({
  type: types.GET_HISTORY_INVESTMENT_SUCCESS,
  payload,
})

export const investBuyNft = (payload, cbs, cbe) => ({
  type: types.INVEST_BUY_NFT,
  payload,
  cbs,
  cbe,
})

export const signInvest = (payload, cbs, cbe) => ({
  type: types.SIGN_INVEST,
  payload,
  cbs,
  cbe,
})

export const submitSAFT = (payload, cbs, cbe) => ({
  type: types.SUBMIT_SAFT,
  payload,
  cbs,
  cbe,
})
