import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerDetail } from './actions'

const useCustomerDetail = (customerId) => {
  const dispatch = useDispatch()
  const customerDetail = useSelector((state) => state.user.customerDetail[customerId])
  const fetchCustomerDetail = useCallback(() => {
    if (customerId) {
      dispatch(getCustomerDetail({ customer_id: customerId }))
    }
  }, [customerId, dispatch])
  useEffect(() => {
    if (!customerDetail) {
      fetchCustomerDetail()
    }
  }, [fetchCustomerDetail, customerDetail])
  return { customerDetail, fetchCustomerDetail }
}

export default useCustomerDetail
