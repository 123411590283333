import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { logout, login, verifyEmail } from 'state/auth/actions'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'

const useWalletAuth = () => {
  const dispatch = useDispatch()

  const handleLogin = useCallback(
    async (email, password, cbs, cbe) => {
      executeRecaptcha((tokenCaptcha) => {
        dispatch(
          login(
            { email, password, recaptcha: tokenCaptcha },
            (receipt) => {
              if (cbs) cbs(receipt)
            },
            (error) => {
              if (cbe) cbe(error)
            },
          ),
        )
      })
    },
    [dispatch],
  )

  const handleVerifyCode = useCallback(
    (token, code, cbs, cbe) => {
      executeRecaptcha((recaptcha) => {
        dispatch(
          verifyEmail(
            {
              token,
              code,
              os: window.navigator.platform,
              device_id: window.navigator.appVersion,
              recaptcha,
            },
            (receipt) => {
              if (cbs) cbs(receipt)
            },
            (error) => {
              if (cbe) cbe(error)
            },
          ),
        )
      })
    },
    [dispatch],
  )

  const handleLogout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  return { login: handleLogin, logout: handleLogout, verifyCode: handleVerifyCode }
}

export default useWalletAuth
