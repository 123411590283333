import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { roundNumber } from 'helpers/Number'
import { Field } from './const'

export function useExchangeState() {
  return useSelector((state) => state.exchange)
}

function useTradeAmount(typedValue, independentField, inputCurrency, outputCurrency) {
  console.log(typedValue, independentField, inputCurrency, outputCurrency)
  return useMemo(() => {
    let outputAmount
    if (inputCurrency && outputCurrency) {
      if (inputCurrency.code === 'USDT' || outputCurrency.code === 'USDT') {
        if (inputCurrency.code === 'USDT') {
          if (independentField === Field.INPUT) {
            outputAmount = typedValue / outputCurrency?.usd_rate
          } else {
            outputAmount = typedValue * outputCurrency?.usd_rate
          }
        } else if (inputCurrency.code === 'USDT') {
          if (independentField === Field.INPUT) {
            outputAmount = typedValue * inputCurrency?.usd_rate
          } else {
            outputAmount = typedValue / inputCurrency?.usd_rate
          }
        }
      }

      if (inputCurrency.code !== 'USDT' && outputCurrency.code !== 'USDT') {
        if (independentField === Field.INPUT) {
          outputAmount = (typedValue * inputCurrency?.usd_rate) / outputCurrency?.usd_rate
        } else {
          outputAmount = (typedValue * outputCurrency?.usd_rate) / inputCurrency?.usd_rate
        }
      }
    }
    return {
      inputAmount: typedValue,
      outputAmount: outputAmount ? roundNumber(outputAmount, { scale: 3 }) : undefined,
    }
  }, [independentField, inputCurrency, outputCurrency, typedValue])
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedExchangeInfo(typedValue, independentField, inputCurrency, outputCurrency) {
  const parsedAmount = typedValue ?? ''

  const { inputAmount, outputAmount } = useTradeAmount(typedValue, independentField, inputCurrency, outputCurrency)

  const currencies = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  }

  let inputError
  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount'
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token'
  }

  return {
    currencies,
    inputAmount,
    outputAmount,
    inputError,
  }
}
