export const CACHE_CHAIN_ID = 'defaultActiveChainId'

export const walletLocalStorageKey = 'wallet'
export const connectorLocalStorageKey = 'connectorId'

export const ConnectorNames = {
  Injected: 'injected',
  WalletConnect: 'walletconnect',
  // BSC: 'bsc',
  // Blocto: 'blocto',
  // WalletLink: 'coinbaseWallet',
}
