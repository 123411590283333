import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 17 17" fill="none" {...props}>
    <path fill="none" stroke="#fff" d="M1.5625 8L6.43652 14L15.4365 2" strokeWidth="3" strokeLinecap="round" />
  </Svg>
)

export default Icon
