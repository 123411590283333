import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'antd'
import './style.scss'
import { isNumber, roundNumber } from 'helpers'
import CurrencyFormat from 'react-currency-format'
import { orderInvestmentReport } from 'state/package/actions'

const NetWorkInfoDetail = ({ investmentStatisticReport }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(orderInvestmentReport())
  }, [dispatch])

  const totalTeamVolume = investmentStatisticReport?.reduce((total, curr) => total + curr.ref_sum_team_volumn / 1e18, 0)
  const totalSelfBought = investmentStatisticReport?.reduce((total, curr) => total + curr.total_self_bought / 1e18, 0)
  const totalRefCountMember = investmentStatisticReport?.reduce((total, curr) => total + curr.ref_count_member, 0)
  return (
    <div className="network-info-detail">
      <Row gutter={[48, 12]} style={{ padding: '0' }}>
        <Col xs={12} md={8} style={{ paddingLeft: '0', paddingRight: window.innerWidth > 768 ? '24px' : '0' }}>
          <div className="network-info-wrap-item">
            <div className="network-info-item network-info-item-firstchild">
              <div className="network-info-item-image">
                <img src="/images/dashboard/total-buy.png" alt="" />
              </div>
              <div className="network-info-item-info">
                <p>
                  {isNumber(totalTeamVolume) ? (
                    <CurrencyFormat value={roundNumber(totalTeamVolume, 2)} displayType="text" thousandSeparator />
                  ) : (
                    '--'
                  )}
                </p>
                <p>
                  <FormattedMessage id="Team Volume" />
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={16} style={{ padding: '0' }}>
          <Row gutter={[48, 12]}>
            <Col xs={24} md={12} style={{ padding: window.innerWidth > 768 ? '0 12px' : '0' }}>
              <div className="network-info-wrap-item">
                <div className="network-info-item">
                  <div className="network-info-item-image">
                    <img src="/images/dashboard/total-member.png" alt="" />
                  </div>
                  <div className="network-info-item-info">
                    <p>{isNumber(totalRefCountMember) ? totalRefCountMember : '--'}</p>
                    <p>
                      <FormattedMessage id="Total Member" />
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={12} style={{ paddingRight: '0', paddingLeft: window.innerWidth < 768 ? '0' : '24px' }}>
              <div className="network-info-wrap-item">
                <div className="network-info-item">
                  <div className="network-info-item-image">
                    <img src="/images/referals/logo-dark.png" alt="" />
                  </div>
                  <div className="network-info-item-info">
                    <p>
                      {isNumber(totalSelfBought) ? (
                        <CurrencyFormat value={roundNumber(totalSelfBought, 2)} displayType="text" thousandSeparator />
                      ) : (
                        '--'
                      )}
                    </p>
                    <p>
                      <FormattedMessage id="Self Bought" />
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default NetWorkInfoDetail
