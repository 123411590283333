import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import './styles.scss'
import WrapperSignOption from 'modules/auth/components/WrapperSignOption'
import ButtonOption from 'modules/auth/components/ButtonOption'
import { FormattedMessage } from 'react-intl'

const SignOption = () => {
  const history = useHistory()
  return (
    <WrapperSignOption>
      <div className="card-option">
        <ButtonOption className="btn-sign-in btn-sign-in-wallet" onClick={() => history.push('/login/wallet')}>
          <span style={{ color: '#28B7B9', marginRight: '5px' }}>
            <FormattedMessage id="Sign In" />
          </span>
          <FormattedMessage id="by Your Wallet" />
        </ButtonOption>
        <ButtonOption className="btn-sign-in btn-sign-in-email" onClick={() => history.push('/login/email')}>
          <span style={{ color: '#28B7B9', marginRight: '5px' }}>
            <FormattedMessage id="Sign In" />
          </span>
          <FormattedMessage id="by Your Email" />
        </ButtonOption>
        <div className="signup-now">
          <FormattedMessage id="Don’t have an account?" />{' '}
          <Link to="/register">
            <FormattedMessage id="Sign up now" />
          </Link>
        </div>
      </div>
    </WrapperSignOption>
  )
}

export default SignOption
