export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'

export const UPDATE_PROFILE = 'UPDATE_PROFILE'

export const UPDATE_KYC = 'UPDATE_KYC'

export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS'
export const GET_CUSTOMER_DETAILS_SUCCESS = 'GET_CUSTOMER_DETAILS_SUCCESS'

export const GET_CUSTOMER_SUMMARY_FLOOR = 'GET_CUSTOMER_SUMMARY_FLOOR'
export const GET_CUSTOMER_SUMMARY_FLOOR_SUCCESS = 'GET_CUSTOMER_SUMMARY_FLOOR_SUCCESS'
