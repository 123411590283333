import React from 'react'
import './styles.scss'
import SignDecorate01Image from 'assets/images/sign_decorate01.png'
import { isMobile } from 'react-device-detect'

const WrapperSignForm = ({ className, children, ...props }) => (
  <div className={`wrapper-sign-form ${className}`} {...props}>
    <div className="sign-email-content">
      {isMobile ? (
        <div className="decorate-sign-left-mobile">
          <img src="/images/decorate-sign-mobile.png" alt="" />
        </div>
      ) : (
        <div className="decorate-sign-left">
          <img src={SignDecorate01Image} alt="" />
        </div>
      )}

      <div className="card-sign-right">{children}</div>
    </div>
  </div>
)
WrapperSignForm.defaultProps = {
  className: '',
}
export default WrapperSignForm
