import produce from 'immer'
import { sortBy } from 'lodash'
import * as Types from './constants'

const initialState = {
  customePackageDetail: [],
  orderReport: [],
  levelCustomerList: '',
  reportTotalList: '',
}

export default function StakeReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_ORDER_INVESTMENT_REPORT_SUCCESS:
        draft.orderReport = payload
        break
      case Types.GET_CUSTOMER_PACKAGE_DETAIL_SUCCESS:
        draft.customePackageDetail = payload
        break
      case Types.GET_LEVEL_CUSTOMER_SUCCESS:
        draft.levelCustomerList = sortBy(payload, ['level'])
        break
      case Types.REPORT_TOTAL_SUCCESS:
        draft.reportTotalList = payload
        break
      default:
        return { ...state }
    }
  })
}
