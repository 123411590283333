import * as types from './constants'

export const toggleModalConnect = (payload) => ({
  type: types.TOGGLE_MODAL_WALLET,
  payload,
})

export const getChainList = (payload) => ({
  type: types.GET_CHAIN_LIST,
  payload,
})
export const getChainListSuccess = (payload) => ({
  type: types.GET_CHAIN_LIST_SUCCESS,
  payload,
})

export const getCurrency = (payload) => ({
  type: types.GET_CURRENCY,
  payload,
})
export const getCurrencySuccess = (payload) => ({
  type: types.GET_CURRENCY_SUCCESS,
  payload,
})
export const fetchWalletBalance = (payload) => ({
  type: types.FETCH_WALLET_BALANCE,
  payload,
})
export const fetchWalletBalanceSuccess = (payload) => ({
  type: types.FETCH_WALLET_BALANCE_SUCCESS,
  payload,
})
export const getCurrencyAttr = (payload, cbs, cbe) => ({
  type: types.GET_CURRENCY_ATTR,
  payload,
  cbs,
  cbe,
})
export const getCurrencyAttrSuccess = (payload) => ({
  type: types.GET_CURRENCY_ATTR_SUCCESS,
  payload,
})

export const createWallet = (payload, cbs, cbe) => ({
  type: types.CREATE_WALLET_ADDRESS,
  payload,
  cbs,
  cbe,
})

export const getWalletAddress = (payload) => ({
  type: types.GET_WALLET_ADDRESS,
  payload,
})
export const getWalletAddressSuccess = (payload) => ({
  type: types.GET_WALLET_ADDRESS_SUCCESS,
  payload,
})

export const withdraw = (payload, cbs, cbe) => ({
  type: types.WITHDRAW,
  payload,
  cbs,
  cbe,
})
export const verifyWithdraw = (payload, cbs, cbe) => ({
  type: types.VERIFY_WITHDRAW,
  payload,
  cbs,
  cbe,
})

export const resendVerifyWithdrawCode = (payload, cbs, cbe) => ({
  type: types.RESEND_VERIFY_WITHDRAW_CODE,
  payload,
  cbs,
  cbe,
})
