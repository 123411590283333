/* eslint-disable no-nested-ternary */
import React from 'react'
import Nodata from 'components/Nodata'
import ItemActive from '../itemActive'
import { List } from 'antd'
import { FormattedMessage } from 'react-intl'
import Loading from 'widgets/Loading/Loading'
import PaginationCustomer from 'components/PaginationCustomer'
import './styles.scss'

const TableHistoryLoginMobile = ({ total, historyLogin, setParamsHistory }) => {
  const responsiveList = { gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }
  return (
    <div
      className="history-table"
      id="scrollableDiv"
      style={{
        position: 'relative',
      }}
    >
      {historyLogin?.rows?.length > 0 ? (
        <>
          <List
            className="list-history-login"
            dataSource={historyLogin?.rows || []}
            grid={responsiveList}
            renderItem={(item, index) => (
              <List.Item key={`list-${index}`}>
                <ItemActive data={item} index={index} key={item.id} />
              </List.Item>
            )}
            locale={{
              emptyText:
                total === 0 ? (
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <FormattedMessage id="Empty" />
                  </div>
                ) : (
                  <></>
                ),
            }}
          />
          <PaginationCustomer
            total={total}
            current={historyLogin?.page}
            onChange={(page, pageSize) => {
              setParamsHistory((prev) => ({
                ...prev,
                page,
                pageSize,
              }))
            }}
          />
        </>
      ) : historyLogin === undefined ? (
        <List
          dataSource={[{}]}
          grid={responsiveList}
          renderItem={(_, index) => (
            <List.Item key={`list-${index}`}>
              <div className="loading-list-stake">
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <span style={{ color: '#fff' }}>
                    <FormattedMessage id="Loading..." />
                  </span>
                  <Loading />
                </div>
              </div>
            </List.Item>
          )}
        />
      ) : (
        <Nodata />
      )}
    </div>
  )
}

export default TableHistoryLoginMobile
