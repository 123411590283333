import Cookies from 'js-cookie'
import { CACHE_TOKEN, CACHE_USER_INFO } from 'constants/index'
import { fetchHelper } from 'helpers'

export function getAuthToken() {
  const authCode = Cookies.get(CACHE_TOKEN)
  if (authCode) {
    return authCode
  }
  Cookies.remove(CACHE_TOKEN)
  localStorage.removeItem(CACHE_USER_INFO)
  return undefined
}

export function setAuthToken(token) {
  if (!token) return false
  fetchHelper.addDefaultHeader('Authorization', `Bearer ${token}`)
  const setToken = Cookies.set(CACHE_TOKEN, token)
  return setToken ? token : false
}

export function deleteAuthToken(args) {
  localStorage.removeItem(CACHE_USER_INFO)
  Cookies.remove(CACHE_TOKEN)
  if (args?.redirectPath) {
    window.location.href = window.location.origin + args.redirectPath
  }
  return true
}
