import React from 'react'
import { Button } from 'antd'
import './style.scss'

const ButtonOption = ({ className, onClick, children, ...props }) => (
  <div className={`wOption-sign ${className}`} onClick={onClick} role="presentation">
    <Button {...props}>{children}</Button>
  </div>
)
ButtonOption.defaultProp = {
  className: '',
  onClick: () => {},
}
export default ButtonOption
