/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link, useLocation } from 'react-router-dom'
import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ModalTurnOn2FA, { MODAlTYPE } from 'components/ModalTurnOn2FA'
import ModalUpdateWalletAddress from 'components/ModalUpdateWalletAddress'
import { create2FA, getHistoryLogin } from 'state/auth/actions'
import { FormattedMessage } from 'react-intl'
import WBody from 'modules/WBody'
import { BackLinkIcon } from 'widgets/Svg'
import BackLink from 'components/BackLink'
import ModalVerificationCode, { MODAL_VERIFY_TYPE } from 'components/ModalVerificationCode'

const SECURITY_TYPE = {
  EMAIL: 'email',
  AUTHY: 'authy',
}
const Security = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.user)
  const [modal2FA, setModal2FA] = useState({ toggle: false, dataModal: null, type: '' })
  const [modalVerifyUpdateWallet, setModalVerifyUpdateWallet] = useState({
    toggle: false,
    dataModal: null,
    type: MODAL_VERIFY_TYPE.VERIFY_CHANGE_WALLET_ADDRESS,
  })
  const [modalUpdateWallet, setModalUpdateWallet] = useState({ toggle: false, dataModal: null, type: '' })

  const [paramsHistory] = useState({
    page: 1,
    pageSize: 100,
  })
  useEffect(() => {
    dispatch(getHistoryLogin())
  }, [dispatch, paramsHistory])

  const handleTurnOnTypeVerify = (type) => {
    switch (type) {
      case SECURITY_TYPE.EMAIL:
        break
      case SECURITY_TYPE.AUTHY:
        if (userInfo?.status_2fa === 0) {
          executeRecaptcha((recaptcha) => {
            dispatch(
              create2FA(
                { recaptcha },
                (receipt) => {
                  setModal2FA({ toggle: true, dataModal: receipt, type: MODAlTYPE.VERIFY })
                },
                (e) => {
                  toast.error(e?.message || 'Has an error, Please try again late.')
                },
              ),
            )
          })
        } else {
          setModal2FA({ toggle: true, dataModal: {}, type: MODAlTYPE.UPDATE })
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    const url = new URLSearchParams(location.search)
    const twoFaModal = url.get('2fa') || url.get('2fa')
    if (twoFaModal) {
      handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)
    }
  }, [dispatch])

  const handleChangeAddressWallet = () => {
    setModalUpdateWallet({ toggle: true, dataModal: null, type: MODAlTYPE.VERIFY })
  }

  const handleDisableAccount = () => {
    // Soon
  }

  return (
    <>
      {window.innerWidth <= 991 && (
        <div style={{ padding: '0 16px' }}>
          <BackLink
            className="back-link title-page-account-activity"
            label={<FormattedMessage id="Security" />}
            icon={<BackLinkIcon />}
            to="/profile"
          />
        </div>
      )}

      <WBody className="warp-page-security">
        <div className="page-security-content">
          <div className="page-security-content-body">
            {window.innerWidth > 991 && (
              <h2 className="page-security-content-heading">
                <FormattedMessage id="Security" />
              </h2>
            )}

            <div className="security-item">
              <p>
                <FormattedMessage id="Email Verification" />
                <ButtonPrimary className="active" disabled hoverAble>
                  <FormattedMessage id="Default" />
                </ButtonPrimary>
              </p>
              <p>
                <FormattedMessage id="Increase your password strength to enhance account security" />
              </p>
            </div>

            <div className="security-item">
              <p>
                <FormattedMessage id="Openlive/Google Authenticator" />

                {/* 0: chưa 1: active 2: disable */}
                {userInfo?.status_2fa === 1 ? (
                  <ButtonPrimary className="danger" onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)}>
                    <FormattedMessage id="Disable" />
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary className="active" onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)}>
                    <FormattedMessage id="Verify" />
                  </ButtonPrimary>
                )}
              </p>
              <p>
                <FormattedMessage id="Set up your Openlive /Google Authenticator to provide an extra security" />
              </p>
            </div>

            <div className="security-item">
              <p>
                <FormattedMessage id="Primary KYC" />
                <Link to={window.innerWidth > 768 ? '/profile/primary-kyc' : '/security/primary-kyc'}>
                  <ButtonPrimary className="active">
                    {(() => {
                      if (userInfo?.status_kyc === 0) {
                        return <FormattedMessage id="Verify" />
                      }
                      if (userInfo?.status_kyc === 1) {
                        return <FormattedMessage id="Submited" />
                      }
                      if (userInfo?.status_kyc === 2) {
                        return <FormattedMessage id="Actived" />
                      }
                      return <FormattedMessage id="Reject" />
                    })()}
                  </ButtonPrimary>
                </Link>
              </p>
              <p>
                <FormattedMessage id="Increase your 24-hour withdrawal unlimited" />
              </p>
            </div>

            <div className="security-item">
              <p>
                <FormattedMessage id="Change Address Wallet" />
                <ButtonPrimary className="active" onClick={() => handleChangeAddressWallet()}>
                  <FormattedMessage id="Change" />
                </ButtonPrimary>
              </p>
              <p>
                <FormattedMessage id="Update your address" />
              </p>
            </div>

            <div className="security-item">
              <p>
                <FormattedMessage id="Account Activity" />
                <Link to={window.innerWidth > 768 ? '/profile/account-activity' : '/security/account-activity'}>
                  <ButtonPrimary className="active">
                    <FormattedMessage id="View" />
                  </ButtonPrimary>
                </Link>
              </p>
              <p>
                <FormattedMessage id="Last login" />: 2022-03-18 09:21
              </p>
            </div>

            <div className="security-item">
              <p>
                <FormattedMessage id="Disable Account" />
                <ButtonPrimary disabled hoverAble={false} onClick={handleDisableAccount}>
                  <FormattedMessage id="Soon" />
                </ButtonPrimary>
              </p>
              <p>
                <FormattedMessage id="Disable your account immediately" />
              </p>
            </div>
          </div>
        </div>
        <ModalTurnOn2FA
          visible={modal2FA.toggle}
          dataModal={modal2FA.dataModal}
          modalType={modal2FA.type}
          onCancel={() => setModal2FA({ toggle: false, dataModal: null })}
        />

        <ModalUpdateWalletAddress
          visible={modalUpdateWallet.toggle}
          dataModal={modalUpdateWallet.dataModal}
          modalType={modalUpdateWallet.type}
          setModalNext={setModalVerifyUpdateWallet}
          onCancel={() => setModalUpdateWallet({ toggle: false, dataModal: null })}
        />

        <ModalVerificationCode
          visible={modalVerifyUpdateWallet.toggle}
          dataModal={modalVerifyUpdateWallet.dataModal}
          modalType={modalVerifyUpdateWallet.type}
          onCancel={() => {
            setModalVerifyUpdateWallet({ toggle: false, dataModal: null })
            setModalUpdateWallet({ toggle: false, dataModal: null })
          }}
          onFinish={() => {
            console.log(123)
          }}
        />
      </WBody>
    </>
  )
}

export default Security
