import React from 'react'
import './styles.scss'

const Input = ({ size, suffix, prefix, phoneCode, ...props }) => (
  <div className="wrapper-input" data-size={size || 'standard'}>
    {suffix}
    {phoneCode && <div className="phone-suffix">{phoneCode}</div>}
    <input {...props} />
    {prefix}
  </div>
)
Input.defaultProps = {
  suffix: '',
  prefix: '',
}
export default Input
