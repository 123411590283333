import { all, takeLatest, call, put } from 'redux-saga/effects'
import qs from 'query-string'
import { fetchHelper } from 'helpers'
import { ROOT_API_URL } from 'constants/index'
import * as types from './constants'
import * as actions from './actions'

/**
 * Get Transaction list buy currency
 */
function getTransactionListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/transaction/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getTransactionList({ payload }) {
  try {
    const { data } = yield call(getTransactionListFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getTransactionListSuccess(data.data))
    }
  } catch (error) {
    console.error('getTransactionList', error)
  }
}

function getListStatusFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/staticData/data?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListStatus({ payload }) {
  try {
    const { data } = yield call(getListStatusFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getListStatusSuccess(data.data))
    }
  } catch (error) {
    console.error('getListStatus', error)
  }
}

export default function* rootSaga() {
  yield all([takeLatest(types.GET_TRANSACTIONS_LIST, getTransactionList), takeLatest(types.GET_LIST_STATUS, getListStatus)])
}
