import React, { useEffect, useState, useMemo } from 'react'
import ReferrerCard from 'components/ReferrerCard'
import { useDispatch, useSelector } from 'react-redux'
import TableTransactionHistory from './components/TableTransactionHistory'
import { orderInvestmentReport, getReportTotal } from 'state/package/actions'
import { getTransactionList } from 'state/transactions/actions'
import './style.scss'
import { isNumber, roundNumber } from 'helpers'
import { FormattedMessage } from 'react-intl'
import { getInvestmentStatisticReport } from 'state/networks/actions'
import { getCustomerSummaryFloor } from 'state/user/actions'
import FormatAmount from 'widgets/FormatAmount'

const Dashboard = () => {
  const dispatch = useDispatch()

  const { currency } = useSelector((state) => state.wallets)
  const { userInfo } = useSelector((state) => state.user)
  const { transactionsList } = useSelector((state) => state.transactions)
  const { reportTransaction } = useSelector((state) => state.package.reportTotalList)
  const { orderReport } = useSelector((state) => state.package)
  const { rows, total } = useSelector((state) => state.transactions.transactionsList)
  const { investmentStatisticReport } = useSelector((state) => state.networks)
  const { customerSummaryFloor } = useSelector((state) => state.user)

  useEffect(() => {
    dispatch(getCustomerSummaryFloor())
  }, [dispatch])

  const ItemMemberDiamond = customerSummaryFloor?.find((item) => item.floor === 1) // Diamond
  const totalDiamondMember = ItemMemberDiamond?.total_member

  const [paramReportTotal] = useState({
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    dispatch(getInvestmentStatisticReport())
  }, [dispatch])

  useEffect(() => {
    dispatch(orderInvestmentReport())
  }, [dispatch])

  useEffect(() => {
    dispatch(getReportTotal(paramReportTotal))
  }, [dispatch, paramReportTotal])

  /* Transaction history */
  const [paramsTxhHistory, setParamsTxhHistory] = useState({
    page: 1,
    pageSize: 10,
  })
  useEffect(() => {
    dispatch(getTransactionList(paramsTxhHistory))
  }, [dispatch, paramsTxhHistory])

  const orderReportCustomer = orderReport && orderReport?.filter((item) => item.customer_id === userInfo?.profile.customer_id)
  const totalTeamVolume = investmentStatisticReport?.reduce((total, curr) => total + curr.ref_sum_team_volumn / 1e18, 0)
  const totalRefCountMember = orderReportCustomer?.reduce((total, curr) => total + curr.ref_count_member, 0)

  const totalEarn = useMemo(() => {
    if (!reportTransaction?.length || !currency) return '-'
    return reportTransaction.reduce((total, curr) => {
      const directCom = curr?.DIRECT_COMMISSION_INVEST
      const findCurr = currency?.find((it) => it.code === curr.currency)
      if (findCurr) {
        const totalBonusByCurr = (directCom / 1e18) * findCurr.usd_rate
        return totalBonusByCurr + total
      }
      return total
    }, 0)
  }, [reportTransaction, currency])

  const TotalVesting = useMemo(() => {
    if (!reportTransaction?.length) return 0
    return reportTransaction.reduce((total, curr) => {
      const bonusTokenInvest = curr?.BONUS_TOKEN_INVEST
      const totalBonusByCurr = bonusTokenInvest / 1e18
      return totalBonusByCurr + total
    }, 0)
  }, [reportTransaction])

  return (
    <div className="dashboard-container">
      <div className="dashboard-info">
        <div className="dashboard-info-wrap-item">
          <div className="dashboard-info-item">
            <div className="dashboard-info-item-image">
              <img src="/images/dashboard/total-buy.png" alt="" />
            </div>
            <div className="dashboard-info-item-info">
              <p>
                <FormatAmount value={roundNumber(totalTeamVolume)} nullValue="--" />
              </p>
              <p>
                <FormattedMessage id="Team Volume" />
              </p>
            </div>
          </div>
        </div>

        <div className="dashboard-info-wrap-item">
          <div className="dashboard-info-item">
            <div className="dashboard-info-item-image">
              <img src="/images/dashboard/total-earn.png" alt="" />
            </div>
            <div className="dashboard-info-item-info">
              <p>
                <FormatAmount value={roundNumber(totalEarn)} nullValue="--" />
              </p>
              <p>
                <FormattedMessage id="Total Earn" />
              </p>
            </div>
          </div>
        </div>

        <div className="dashboard-info-wrap-item">
          <div className="dashboard-info-item">
            <div className="dashboard-info-item-image">
              <img src="/images/dashboard/total-investing.png" alt="" />
            </div>
            <div className="dashboard-info-item-info">
              <p>
                <FormatAmount value={roundNumber(TotalVesting)} nullValue="--" />
              </p>
              <p>
                <FormattedMessage id="Total Vesting" />
              </p>
            </div>
          </div>
        </div>

        <div className="dashboard-info-wrap-item">
          <div className="dashboard-info-item">
            <div className="dashboard-info-item-image">
              <img src="/images/dashboard/total-member.png" alt="" />
            </div>
            <div className="dashboard-info-item-info">
              <p>{isNumber(totalRefCountMember) ? totalRefCountMember : '--'}</p>
              <p>
                <FormattedMessage id="Total Member" />
              </p>
            </div>
          </div>
        </div>

        <div className="dashboard-info-wrap-item">
          <div className="dashboard-info-item">
            <div className="dashboard-info-item-image">
              <img src="/images/dashboard/total-f1.png" alt="" />
            </div>
            <div className="dashboard-info-item-info">
              <p>{isNumber(totalDiamondMember) ? totalDiamondMember : '--'}</p>
              <p>
                <FormattedMessage id="Diamond Member" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <ReferrerCard className="ref-view" userInfo={userInfo} />

      <TableTransactionHistory
        rows={rows}
        total={total}
        transactionsList={transactionsList}
        txParams={paramsTxhHistory}
        setTxParams={setParamsTxhHistory}
      />
    </div>
  )
}

export default Dashboard
