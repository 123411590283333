import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { isNumber } from 'lodash'
import { Row } from 'antd'
import { isMobile } from 'react-device-detect'
import './styles.scss'
import { DECIMALS } from 'constants/index'
import {
  createWallet,
  fetchWalletBalance,
  getCurrencyAttr,
  getWalletAddress,
  resendVerifyWithdrawCode,
  withdraw,
} from 'state/wallets/actions'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { getTransactionList } from 'state/transactions/actions'
import ControlInputSelect from 'components/ControlInputSelect'
import RecentTransaction from 'components/History/RecentTransaction'
import ModalVerificationCode from 'components/ModalVerificationCode'
import ControlInputText from 'components/ControlInputText'
import BackLink from 'components/BackLink'
import Amount from './component/amount'
import Address from './component/address'
import { FormattedMessage, injectIntl } from 'react-intl'

const Withdraw = ({ intl }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { userInfo } = useSelector((state) => state.user)
  const { chainList, chainMapCode, currency, currencyAttr, walletsAddress } = useSelector((state) => state.wallets)
  const { rows, total } = useSelector((state) => state.transactions.transactionsList)
  const { transactionsList } = useSelector((state) => state.transactions)
  const [modalVerifyCode, setModalVerifyCode] = useState({ toggle: false, dataModal: null })
  const [loadingWithdraw, setLoadingWithdraw] = useState(false)
  const [errorMess, setErrorMess] = useState('')

  const [amount, setAmount] = useState('')
  const [selectCurrency, setSelectCurrency] = useState('')
  const [selectChain, setSelectChain] = useState('')
  const [addressTag, setAddressTag] = useState('')
  const [userTokenAddress, setUserTokenAddress] = useState('')
  const [walletAddressSelect, setWalletAddressSelect] = useState()

  const optionCurrency = useMemo(() => {
    if (currency) {
      return currency.map((item) => ({
        label: item.title,
        value: item.code,
        type: item.type,
        currency_code: item.code,
        image: item.icon,
      }))
    }
    return undefined
  }, [currency])

  const optionChain = useMemo(() => {
    if (chainList) {
      return chainList.map((item) => ({
        label: item.title,
        value: item.chainid,
        chain_code: item.code,
        image: item.icon,
        _id: item._id,
      }))
    }
    return undefined
  }, [chainList])
  /**
   * @dev Check url params
   */
  useEffect(() => {
    const { id } = params
    if (optionChain) {
      setSelectChain(JSON.stringify(optionChain[0]))
    }
    if (optionCurrency && id) {
      const fCurrency = optionCurrency.find((o) => o.value === id)
      setSelectCurrency(JSON.stringify(fCurrency))
    }
  }, [optionChain, optionCurrency, params])
  // Wallet currency  default select (any TOKEN)
  const baseCurrency = useMemo(() => {
    if (selectCurrency) {
      return currency?.find((item) => item.code === JSON.parse(selectCurrency).currency_code)
    }
    return undefined
  }, [currency, selectCurrency])

  // Chain attribute for get fee of chain
  const baseChainAttr = useMemo(() => {
    if (selectChain) {
      return currencyAttr?.find((item) => item.blockchain === JSON.parse(selectChain)._id)
    }
    return undefined
  }, [currencyAttr, selectChain])

  // Wallet default token of chain (BNB or MATIC)
  const baseNativeToken = useMemo(() => {
    if (currency && baseChainAttr) {
      return currency.find((curr) => curr.code === baseChainAttr.native_token)
    }
    return undefined
  }, [currency, baseChainAttr])

  /**
   * @dev Fetch txh history
   */
  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    currency: undefined,
    type: 'WITHDRAW',
  })
  useEffect(() => {
    if (selectCurrency) {
      setTxParams((prev) => ({
        ...prev,
        currency: JSON.parse(selectCurrency).currency_code,
      }))
    }
  }, [selectCurrency])
  useEffect(() => {
    if (txParams.currency) {
      dispatch(getTransactionList(txParams))
    }
  }, [dispatch, txParams])

  /**
   * @dev Fetch attr for get fee by code of currency
   */
  useEffect(() => {
    if (baseCurrency?._id) {
      dispatch(
        getCurrencyAttr({
          currency_id: baseCurrency._id,
        }),
      )
    }
  }, [baseCurrency, dispatch])
  useEffect(() => {
    if (walletsAddress && selectCurrency && selectChain) {
      const pCurrency = JSON.parse(selectCurrency)
      const pChain = JSON.parse(selectChain)

      const fWallet = walletsAddress.find((item) => {
        if (item.currency === pCurrency.currency_code && item.chain === pChain.chain_code) {
          return true
        }
        return false
      })

      if (fWallet) {
        setWalletAddressSelect(fWallet)
      } else {
        /**
         * Create new wallet if wallet is not available
         * Select wallet will be rerender and checked
         * After create new wallet this will make actions in the saga for fetch all new wallet (Search keyword: {yield put(actions.getWalletAddress})
         */
        executeRecaptcha((recaptcha) => {
          dispatch(
            createWallet(
              {
                currency_code: pCurrency.currency_code,
                chain_code: pChain.chain_code,
                type: 'CRYPTO',
                recaptcha,
              },
              () => {
                dispatch(getWalletAddress({ page: 1, pageSize: 100 }))
              },
              (error) => {
                if (error.code === 'err_not_found') {
                  setWalletAddressSelect(undefined)
                }
              },
            ),
          )
        })
      }
    }
  }, [dispatch, walletsAddress, selectCurrency, selectChain])

  /**
   * Re verify code for withdraw and resend code
   */
  const handleReVerifyWithdraw = useCallback(
    (item) => {
      executeRecaptcha((recaptcha) => {
        dispatch(
          resendVerifyWithdrawCode({
            transaction_id: item._id,
            recaptcha,
          }),
        )
      })
      setModalVerifyCode({ toggle: true, dataModal: item })
    },
    [dispatch],
  )

  /**
   * Verify code success withdraw
   */
  const handleFinishWithdraw = useCallback(() => {
    fetchWalletBalance([{ code: JSON.parse(selectCurrency).currency_code }])
    dispatch(getTransactionList(txParams))
  }, [dispatch, selectCurrency, txParams])

  const handleWithdraw = () => {
    if (!baseCurrency) return setErrorMess(<FormattedMessage id="An occurred error, Please try again" />)
    if (!walletAddressSelect || !baseChainAttr) {
      return setErrorMess(`${JSON.parse(selectChain).label} is not support ${JSON.parse(selectCurrency).label}.`)
    }

    if (!selectChain) return setErrorMess(<FormattedMessage id="Please select network" />)
    if (!selectCurrency) return setErrorMess(<FormattedMessage id="Please select currency" />)
    if (!userTokenAddress) return setErrorMess(<FormattedMessage id="Please enter receiver address" />)
    if (walletAddressSelect?.addressTag && !addressTag) return setErrorMess(<FormattedMessage id="Please enter memo" />)
    if (!isNumber(+amount) || +amount <= 0) return setErrorMess(<FormattedMessage id="Please enter amount" />)
    if (!userInfo?.kycInfo) {
      if (baseCurrency.usd_rate * amount > 10000) {
        return setErrorMess(
          <FormattedMessage id="Please complete KYC to increase your daily withdrawal limits to up to $100 000" />,
        )
      }
    }
    if (baseCurrency.usd_rate * amount > 1000000) {
      return setErrorMess(<FormattedMessage id="Maximum amount (per transaction) $1000000" />)
    }

    const parseAmount = amount * 10 ** DECIMALS
    if (parseAmount < baseCurrency.min_withdraw) {
      return setErrorMess(`Minimum amount (per transaction) ${baseCurrency.min_withdraw / 10 ** DECIMALS}`)
    }
    if (parseAmount > baseCurrency.max_withdraw) {
      return setErrorMess(`Maximum amount (per transaction) ${baseCurrency.max_withdraw / 10 ** DECIMALS}`)
    }
    if (parseAmount > baseCurrency.balance) return setErrorMess('Balance is not enough')

    const feeChain = baseChainAttr.withdraw_fee_chain
    const feeToken = (baseChainAttr.withdraw_fee_token * parseAmount) / 100
    if (chainMapCode.includes(JSON.parse(selectCurrency).currency_code)) {
      if (feeChain + feeToken > baseCurrency.balance)
        return setErrorMess(<FormattedMessage id="Balance is not enough for pay fee" />)
    } else if (baseNativeToken && feeChain > baseNativeToken.balance) {
      return setErrorMess(`${baseNativeToken.title} is not enough for pay fee`)
    }

    setErrorMess('')
    setLoadingWithdraw(true)
    executeRecaptcha((recaptcha) => {
      dispatch(
        withdraw(
          {
            amount: parseAmount,
            address: userTokenAddress,
            addressTag,
            chain_code: JSON.parse(selectChain).chain_code,
            currency_code: JSON.parse(selectCurrency).currency_code,
            recaptcha,
          },
          (receipt) => {
            dispatch(getTransactionList(txParams))
            setModalVerifyCode({ toggle: true, dataModal: { _id: receipt.TransactionCode } })
            setLoadingWithdraw(false)
          },
          (error) => {
            setLoadingWithdraw(false)
            if (error.code === 'err_data_existed') {
              const txHistory = rows.find((item) => item.status === 'CREATED')
              if (txHistory) {
                executeRecaptcha((recaptcha) => {
                  dispatch(
                    resendVerifyWithdrawCode({
                      transaction_id: txHistory._id,
                      recaptcha,
                    }),
                  )
                })
                setModalVerifyCode({ toggle: true, dataModal: txHistory })
                return
              }
            }
            dispatch(getTransactionList(txParams))
            return null
          },
        ),
      )
    })
  }

  return (
    <div className="warp-page-withdraw">
      <div className="container page-withdraw-content">
        <BackLink label={<FormattedMessage id="Withdraw" />} to="/wallet" />

        <div className="withdraw-content-form">
          <div className="box-input-select">
            {optionCurrency && selectCurrency && (
              <ControlInputSelect
                title={<FormattedMessage id="Select currency" />}
                dataSelect={optionCurrency}
                defaultValue={selectCurrency}
                onChange={(value) => {
                  history.replace(`/wallet/withdraw/${JSON.parse(value).value}`)
                  setSelectCurrency(value)
                }}
              />
            )}
            {optionChain && (
              <ControlInputSelect
                title={<FormattedMessage id="Select chain" />}
                dataSelect={optionChain}
                defaultValue={JSON.stringify(optionChain[0])}
                onChange={setSelectChain}
              />
            )}
          </div>

          <div className="box-input-text">
            <Address
              label={<FormattedMessage id="Address" />}
              placeholder={intl.formatMessage({ id: 'Please enter your address to withdraw' })}
              value={userTokenAddress}
              onChange={setUserTokenAddress}
            />
          </div>
          {walletAddressSelect?.addressTag && (
            <div className="memo-section-wrapper">
              <Row gutter={[16, 16]} className="memo-section-content">
                <ControlInputText label="MEMO" placeholder="Optional" onChange={setAddressTag} />
                <div className="note-warning-meno">
                  <span>
                    <img className="img-warning" src="/images/icons/warring.png" alt="" />
                  </span>
                  <p>
                    <FormattedMessage id="Most exchange requires MEMO for your XRP to be correctly credited. Please ensure that you have inputted the correct MEMO for your withdrawal." />
                  </p>
                </div>
              </Row>
            </div>
          )}
          <div className="withdraw-box-attention">
            <ul>
              <li>
                <p>
                  <FormattedMessage id="Enter the correct address to withdraw" />
                </p>
              </li>
              <li>
                <p>
                  <FormattedMessage id="Ensure the network is" />
                  &nbsp;
                  {selectChain && <span>{JSON.parse(selectChain).label} </span>}
                </p>
              </li>
            </ul>
          </div>
          <Amount
            userInfo={userInfo}
            type="number"
            value={amount}
            baseCurrency={baseCurrency}
            baseChainAttr={baseChainAttr}
            baseNativeToken={baseNativeToken}
            is2Fa={userInfo?.status_2fa === 1}
            feeChain={baseChainAttr?.withdraw_fee_chain !== undefined ? baseChainAttr?.withdraw_fee_chain / 1e18 : 0}
            feeToken={baseChainAttr?.withdraw_fee_token}
            minimumAmount={baseChainAttr ? baseChainAttr.min_withdraw / 10 ** DECIMALS : 0}
            onChange={setAmount}
          />
          <div className="box-discamler">
            <img className="box-discamler-ico" src="/images/icons/warning.png" alt="" />
            {selectChain && (
              <div className="box-discamler-content">
                <p className="box-discamler-content-title">
                  <FormattedMessage id="Disclaimer" />
                </p>
                <p className="box-discamler-content-note">
                  <FormattedMessage id="The network you have selected is" /> <span>{JSON.parse(selectChain).chain_code}</span>
                  ,&nbsp;
                  <FormattedMessage id="please make sure your withdrawal address supports the" />{' '}
                  <span>{JSON.parse(selectChain).label}</span>{' '}
                  <FormattedMessage id="network. In case the other platform does not support this network, your assets may be lost. If you are unsure if the receiving platform supports this network, you can click the button below to verify it yourself." />
                </p>
              </div>
            )}
          </div>

          <div className="withdraw-actions text-center">
            <p className="error-mess">{errorMess}</p>
            <ButtonPrimary style={{ maxWidth: '150px' }} className="active" loading={loadingWithdraw} onClick={handleWithdraw}>
              <FormattedMessage id="WITHDRAW" />
            </ButtonPrimary>
          </div>
          {!isMobile && (
            <RecentTransaction
              txParams={txParams}
              rows={rows}
              total={total}
              transactionsList={transactionsList}
              setTxParams={setTxParams}
              setModalVerifyCode={handleReVerifyWithdraw}
            />
          )}
        </div>
      </div>

      <ModalVerificationCode
        visible={modalVerifyCode.toggle}
        dataModal={modalVerifyCode.dataModal}
        onCancel={setModalVerifyCode}
        onFinish={handleFinishWithdraw}
      />
    </div>
  )
}

export default injectIntl(Withdraw)
