import React from 'react'
import './styles.scss'
import { formatDate, isNumber, roundNumber } from 'helpers'
import { FormattedMessage } from 'react-intl'
import CurrencyFormat from 'react-currency-format'
import BigNumber from 'bignumber.js'
import Nodata from 'components/Nodata'
import TxStatus from 'components/TxStatus'

const ItemPacKageTypeDesktop = ({ historyInvestment }) => (
  <div className="network-wrap-table package-wrap-table">
    <table className="network-table-package">
      <thead>
        <tr>
          <th className="network-table-package-th">
            <FormattedMessage id="Package" />
          </th>
          <th className="network-table-package-th">
            <FormattedMessage id="Value (USD)" />
          </th>
          <th className="network-table-package-th">
            <FormattedMessage id="Status" />
          </th>
          <th className="network-table-package-th">
            <FormattedMessage id="Time" />
          </th>
        </tr>
      </thead>

      <tbody className={historyInvestment?.length > 0 ? 'network-wrap-table-tbody-package' : 'table-tbody-package-empty'}>
        {historyInvestment?.length > 0 ? (
          historyInvestment?.map((item, index) => (
            <tr key={`item-${index}`}>
              <td className="network-table-package-td item-package">{item?.package?.title}</td>
              <td className="network-table-package-td">
                {isNumber(item?.price_usd_invest) ? (
                  <CurrencyFormat
                    value={roundNumber(new BigNumber(item?.price_usd_invest).shiftedBy(-18).toNumber())}
                    displayType="text"
                    thousandSeparator
                  />
                ) : (
                  '--'
                )}
              </td>
              <td className="network-table-package-td" style={{ textTransform: 'capitalize' }}>
                <p>
                  <TxStatus
                    status={item?.status === true ? 'COMPLETED' : 'PENDING'}
                    title={item?.status === true ? 'Completed' : 'Pending'}
                  />
                </p>
              </td>
              <td className="network-table-package-td">{formatDate(item.createdAt * 1000, 'YYYY/MM/DD')}</td>
            </tr>
          ))
        ) : (
          <div className="network-table-mon-data">
            <Nodata />
          </div>
        )}
      </tbody>
    </table>
  </div>
)

export default ItemPacKageTypeDesktop
