import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 2L3.54639 7.91337C2.33566 8.6707 2.2859 10.4164 3.45154 11.2414L13 18"
      stroke="#069CC9"
      strokeWidth="4"
      strokeLinecap="round"
      fill="none"
    />
  </Svg>
)

export default Icon
