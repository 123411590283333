import { Provider } from 'react-redux'
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from 'utils/web3React'
import configureStore from './state/store'

const initialState = {}
const store = configureStore(initialState)

const Providers = ({ children }) => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>{children}</Provider>
  </Web3ReactProvider>
)
export default Providers
