/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Table } from 'antd'
import './style.scss'
import { formatDate, isNumber, roundNumber } from 'helpers'
import { FormattedMessage } from 'react-intl'
import RangePicker from 'widgets/RangePicker'
import SelectPackage from 'widgets/SelectPackage'
import { useDispatch, useSelector } from 'react-redux'
import TableTxhMobile from './TableTxhMobile'
import TxAmount from 'components/TxAmount'
import CurrencyFormat from 'react-currency-format'
import { getListStatus } from 'state/transactions/actions'
import { useHistory, useLocation } from 'react-router-dom'
import PaginationCustomer from 'components/PaginationCustomer'

const TableTransactionHistory = ({ rows, total, txParams, setTxParams, transactionsList, ...props }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [loading] = useState(false)
  const { listStatus } = useSelector((state) => state.transactions)

  useEffect(() => {
    dispatch(getListStatus())
  }, [dispatch])

  const handleSelectAction = (value) => {
    setTxParams((prev) => ({
      ...prev,
      type: value ? JSON.parse(value)?.value : '',
    }))
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    queryParams.set('pageSize', 10)
    if (value) {
      queryParams.set('action', JSON.parse(value)?.value)
    } else {
      queryParams.delete('action')
    }
    history.replace(`?${queryParams.toString()}`)
  }

  const optionActions = useMemo(() => {
    if (listStatus?.actionTransaction) {
      return Object.keys(listStatus?.actionTransaction)
        .filter(
          (item) =>
            item !== 'PREMIUM_BONUS' &&
            item !== 'RANK_REWARD' &&
            item !== 'DIVIDEND_COMMISSION' &&
            item !== 'DIVIDEND' &&
            item !== 'AIRDROP_INVEST' &&
            item !== 'BONUS_FIRST_DEPOSIT' &&
            item !== 'BONUS_KYC' &&
            item !== 'INTEREST' &&
            item !== 'RECEVICE_TOKEN_INVEST' &&
            item !== 'BONUS_TOKEN_INVEST' &&
            item !== 'DIRECT_COMMISSION_INVEST' &&
            item !== 'REVERSE' &&
            item !== 'STAKE' &&
            item !== 'UNSTAKE',
        )
        .map((i) => ({
          label: i,
          value: i,
        }))
    }
    return undefined
  }, [listStatus])

  const columns = useMemo(
    () => [
      {
        title: (
          <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            <FormattedMessage id="No." />
          </div>
        ),
        width: '10%',
        render: (text, _, index) => (
          <div>
            {txParams.page === 1
              ? (index + 1 < 10 && `0${index + 1}`) || index + 1
              : txParams.page * txParams.pageSize - 10 + index + 1}
          </div>
        ),
      },
      {
        title: (
          <div style={{ whiteSpace: 'nowrap' }}>
            <FormattedMessage id="Amount" />
          </div>
        ),
        dataIndex: 'amount',
        width: '15%',
        render: (text, record) => (
          <p>
            {isNumber(+text) ? (
              <TxAmount
                action={record?.action}
                title={
                  <CurrencyFormat
                    value={roundNumber(text, { decimals: 18 })}
                    displayType="text"
                    thousandSeparator
                    renderText={(t) => t}
                  />
                }
              />
            ) : (
              '--'
            )}
          </p>
        ),
      },
      {
        title: (
          <div style={{ whiteSpace: 'nowrap' }}>
            <FormattedMessage id="Type" />
          </div>
        ),
        dataIndex: 'currency',
        width: '20%',
        render: (item, record) => (
          <div className="coin-data" style={{ justifyContent: 'center', display: 'flex' }}>
            <img src={record.icon} alt="" />
            <div className="coin-data-label">
              <div>{item}</div>
            </div>
          </div>
        ),
      },
      {
        title: (
          <div style={{ whiteSpace: 'nowrap' }}>
            <FormattedMessage id="Action" />
          </div>
        ),
        dataIndex: 'action',
        render: (text) => <p className="recent-transaction-table-action">{text}</p>,
      },
      {
        title: (
          <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            <FormattedMessage id="Time" />
          </div>
        ),
        dataIndex: 'createdAt',
        render: (text) => (
          <p style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{formatDate(text * 1000, 'YYYY-MM-DD hh:mm')}</p>
        ),
      },
    ],
    [txParams.page],
  )
  const loadMoreData = () => {
    if (loading) {
      return
    }

    setTxParams((prev) => ({
      ...prev,
      pageSize: prev.pageSize + 10,
    }))
  }
  useEffect(() => {
    loadMoreData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Filter date
  const onChange = (dates, dateStrings) => {
    if (dates) {
      setTxParams((prev) => ({
        ...prev,
        from_date: new Date(dateStrings[0]).getTime() / 1000 ? new Date(dateStrings[0]).getTime() / 1000 : '',
        to_date: new Date(dateStrings[1]).getTime() / 1000 ? new Date(dateStrings[1]).getTime() / 1000 : '',
        status_url: true,
      }))
    } else {
      setTxParams((prev) => ({
        ...prev,
        from_date: '',
        to_date: '',
        status_url: true,
      }))
    }
  }

  return (
    <div className="table-transaction-history-container" {...props}>
      <h2 className="recent-transaction-title">
        <FormattedMessage id="Transactions History" />
      </h2>
      <div className="recent-transaction-sort">
        <div className="sort-filter-action-left">
          <SelectPackage
            dataSelect={optionActions}
            placeholder={<FormattedMessage id="Filter By Action" />}
            onChange={(value) => handleSelectAction(value)}
          />
        </div>
        <div className="sort-filter-action-right">
          <RangePicker
            width="100%"
            maxWidth="100%"
            onChange={onChange}
            placeholder={['1 Jan 2021', '3 May 2022']}
            inputReadOnly
          />
        </div>
      </div>
      {isMobile ? (
        <TableTxhMobile
          txParams={txParams}
          data={transactionsList?.rows}
          total={transactionsList?.total}
          current={transactionsList?.page}
          loadMoreData={loadMoreData}
          setTxParams={setTxParams}
        />
      ) : (
        <>
          <Table dataSource={transactionsList?.rows || []} columns={columns} scroll={{ x: 450, y: 384 }} pagination={false} />
          <PaginationCustomer
            total={transactionsList?.total}
            current={transactionsList?.page}
            onChange={(page, pageSize) => {
              setTxParams((prev) => ({
                ...prev,
                page,
                pageSize,
              }))
            }}
          />
        </>
      )}
    </div>
  )
}

export default TableTransactionHistory
