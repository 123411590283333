import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Carousel from 'react-multi-carousel'
import { Col, Row } from 'antd'
import { isMobile } from 'react-device-detect'
import 'react-multi-carousel/lib/styles.css'
import './style.scss'
import CardInvestmentItem from '../CardInvestmentItem'
import { getInvestmentList } from 'state/invest/actions'

const responsive = {
  tablet: {
    breakpoint: { max: 3000, min: 500 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
}

const InvestMentList = ({ setModalBuy }) => {
  const dispatch = useDispatch()
  const { investmentList } = useSelector((state) => state.invest)

  const [paramsInvestmentList] = useState({
    page: 1,
    pageSize: 100,
  })

  useEffect(() => {
    dispatch(getInvestmentList(paramsInvestmentList))
  }, [dispatch, paramsInvestmentList])

  return (
    <div className="wrapper-investment-list">
      {isMobile ? (
        <>
          {investmentList?.rows && (
            <Carousel responsive={responsive} showDots itemClass="carousel-item-padding">
              {investmentList?.rows?.map((item) => (
                <div key={item?._id}>
                  <CardInvestmentItem investmentItem={item} onBuy={() => setModalBuy({ toggle: true, dataModal: item })} />
                </div>
              ))}
            </Carousel>
          )}
        </>
      ) : (
        <Row gutter={[16, 16]}>
          {investmentList?.rows?.map((item) => (
            <Col xs={24} sm={12} lg={8} key={item?._id}>
              <CardInvestmentItem investmentItem={item} onBuy={() => setModalBuy({ toggle: true, dataModal: item })} />
            </Col>
          ))}
        </Row>
      )}
    </div>
  )
}

export default InvestMentList
