import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 31 31" {...props}>
    <g id="Icon_feather-copy" data-name="Icon feather-copy" transform="translate(1.5 1.5)">
      <path
        id="Path_4"
        data-name="Path 4"
        d="M16.376,13.5h12.94a2.876,2.876,0,0,1,2.876,2.876v12.94a2.876,2.876,0,0,1-2.876,2.876H16.376A2.876,2.876,0,0,1,13.5,29.316V16.376A2.876,2.876,0,0,1,16.376,13.5Z"
        transform="translate(-3.435 -3.435)"
        fill="none"
        stroke="#fcfcfd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M7.313,21.692H5.876A2.876,2.876,0,0,1,3,18.816V5.876A2.876,2.876,0,0,1,5.876,3h12.94a2.876,2.876,0,0,1,2.876,2.876V7.313"
        transform="translate(-3 -3)"
        fill="none"
        stroke="#fcfcfd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </Svg>
)

export default Icon
