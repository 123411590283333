import produce from 'immer'
import * as Types from './constants'

const initialState = {
  STT_IN_OUT: {
    STT_IN: [
      'AIRDROP_INVEST',
      'BINARY_COMMISSION',
      'BONUS_ACTIVE',
      'BONUS_ACTIVE_24H',
      'BONUS_FIRST_DEPOSIT',
      'BONUS_KYC',
      'BONUS_TOKEN_INVEST',
      'COMMISSION',
      'DIRECT_COMMISSION_INVEST',
      'DIVIDEND',
      'DIVIDEND_COMMISSION',
      'INTEREST',
      'INVESTMENT_DAILY_REWARD',
      'PREMIUM_BONUS',
      'RANK_REWARD',
      'RECEVICE_TOKEN_INVEST',
      'REVERSE',
      'UNSTAKE',
      'DEPOSIT',
      'MINT',
      'BONUS_OP_INVEST',
      'EXCHANGE_IN',
    ],
    STT_OUT: ['BUY', 'FEE', 'INVESTMENT', 'STAKE', 'TRANSFER', 'WITHDRAW', 'EXCHANGE_OUT'],
  },
  transactionsList: {
    data: undefined,
    page: 0,
    total: 0,
  },
  listStatus: null,
}

export default function Transaction(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_TRANSACTIONS_LIST_SUCCESS:
        draft.transactionsList = payload
        break
      case Types.GET_LIST_STATUS_SUCCESS:
        draft.listStatus = payload
        break
      default:
        return { ...state }
    }
  })
}
