import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Menu } from 'antd'
import { FormattedMessage } from 'react-intl'
import './styles.scss'
import { linksPrivate } from 'components/Menu/config'
import { toggleModalSoon } from 'state/app/actions'

const { SubMenu } = Menu

const Panel = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <div className="panel-container">
      <div className="panel-body">
        <div className="panel-top">
          {linksPrivate.map((item, index) => {
            const isHttp = item?.href?.startsWith('http')
            const Tag = isHttp ? 'a' : Link
            const propsLink = isHttp ? { href: item?.href, target: item.target } : { to: item?.href }
            const Icon = item.icon
            if (item.type === 'soon') {
              return (
                <li
                  key={`panel-mb-${index}`}
                  className={history.location.pathname?.includes(item.label.toLowerCase()) ? 'active' : ''}
                >
                  <Tag {...propsLink} onClick={() => dispatch(toggleModalSoon({ toggle: true }))} role="presentation">
                    {item.icon && <Icon />}
                    <FormattedMessage id={item.label} />
                  </Tag>
                </li>
              )
            }

            const submenu = item.children
            return (
              <Menu
                key={`panel-menu-${index}`}
                className="panel-link"
                defaultOpenKeys={['1']}
                defaultSelectedKeys={['1']}
                style={{ background: 'none', color: '#fff' }}
                mode="inline"
              >
                <SubMenu
                  rel="noreferrer"
                  key={index}
                  className={history.location.pathname?.includes(item.label.toLowerCase()) ? 'active' : ''}
                  title={
                    <Tag {...propsLink} className="panel-link-label-title">
                      <div className="panel-link-icon">
                        <div className="panel-link-icon-icon">
                          {typeof Icon === 'string' ? <img src={Icon} alt="" /> : item.icon && <Icon />}
                        </div>
                      </div>
                      <span>
                        <FormattedMessage id={item.label} />
                      </span>
                    </Tag>
                  }
                >
                  {submenu &&
                    submenu.map((itemSub) => {
                      const propsLink = isHttp ? { href: itemSub?.href, target: itemSub.target } : { to: itemSub?.href }
                      const checkUrl = history.location.pathname?.includes(
                        itemSub.href.split('/')?.[itemSub.href.split('/').length - 1].toLowerCase(),
                      )
                      return (
                        <Menu.Item
                          key={itemSub.href}
                          className={!checkUrl ? 'panel-link-label-title-child active' : 'panel-link-label-title-child'}
                        >
                          <Tag {...propsLink} className="panel-link-label-title-child-link">
                            {itemSub.icon && <itemSub.icon />}
                            <span>
                              <FormattedMessage id={itemSub.label} />
                            </span>
                          </Tag>
                        </Menu.Item>
                      )
                    })}
                </SubMenu>
              </Menu>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Panel
