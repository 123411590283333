import React from 'react'
import { Select } from 'antd'
import './styles.scss'

const { Option } = Select

const SelectPackage = ({ dataSelect, maxWidth, suffix, prefix, placeholder, defaultValue, ...props }) => (
  <div className="package-wrap-select" style={{ maxWidth: `${maxWidth}` }}>
    {suffix || ''}
    <Select defaultValue={defaultValue} placeholder={placeholder} dropdownClassName="select-with-image-dropdown" {...props}>
      <Option value="">All</Option>
      {dataSelect &&
        dataSelect
          ?.sort((a, b) => a?.label.localeCompare(b?.label))
          ?.map((item, index) => (
            <Option value={JSON.stringify(item)} key={index}>
              <span className="select-label">{item.label}</span>
            </Option>
          ))}
    </Select>

    <img src="/images/icons/filter-square.png" alt="" />
    {prefix || ''}
  </div>
)

export default SelectPackage
