import React from 'react'
import './styles.scss'
import BackLink from 'components/BackLink'
import RecentTransaction from 'components/History/RecentTransaction'
import CardExchange from './components/CardExchange'
import { useFetchTransactions } from 'state/transactions/fetchTransaction'

const Exchange = () => {
  const { transactions, paramsTxs, setParamsTxs } = useFetchTransactions({
    is_exchange: true,
  })
  return (
    <div className="wExchange fadeIn">
      <div className="exchange-body">
        <div className="exchange-top">
          <BackLink label="Exchange" showBackIcon={false} />
        </div>
        <div className="exchange-content">
          <CardExchange setParamsTxs={setParamsTxs} />
          <RecentTransaction txParams={paramsTxs} setTxParams={setParamsTxs} transactionsList={transactions} />
        </div>
      </div>
    </div>
  )
}

export default Exchange
