import { all } from 'redux-saga/effects'

import App from './app/sagas'
import User from './user/sagas'
import Home from './home/sagas'
import Auth from './auth/sagas'
import Wallet from './wallets/sagas'
import Stake from './stake/sagas'
import Transactions from './transactions/sagas'
import Networks from './networks/sagas'
import File from './file/sagas'
import Package from './package/sagas'
import Invest from './invest/sagas'
import ExchangeSaga from './exchange/sagas'

export default function* rootSaga() {
  yield all([
    App(),
    User(),
    Home(),
    Auth(),
    Wallet(),
    Stake(),
    Transactions(),
    Networks(),
    File(),
    Package(),
    Invest(),
    ExchangeSaga(),
  ])
}
