import React from 'react'
import './styles.scss'
import { EyeIcon, EyeSleepIcon } from 'widgets/Svg'

const InputConfirm = ({ size, suffix, prefix, eyePassWord, showConfirm, setShowConfirm, ...props }) => (
  <div className="input-confirm" data-size={size || 'standard'}>
    {suffix || ''}
    <input {...props} />
    {eyePassWord &&
      (showConfirm ? (
        <EyeIcon style={{ cursor: 'pointer' }} onClick={() => setShowConfirm((prev) => !prev)} />
      ) : (
        <EyeSleepIcon style={{ cursor: 'pointer' }} onClick={() => setShowConfirm((prev) => !prev)} />
      ))}
    {prefix || ''}
  </div>
)

export default InputConfirm
