import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

const Footer = ({ links, location }) => (
  <div className="wFooter">
    <ul>
      {links.map((item, index) => {
        const isHttp = item?.href?.startsWith('http')
        const Tag = isHttp ? 'a' : Link
        const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
        return (
          <li key={`menu-mb-${index}`} className={location.pathname === item.href ? 'active' : ''} role="presentation">
            <Tag {...propsLink} rel="noreferrer">
              {item.label}
            </Tag>
          </li>
        )
      })}
    </ul>
  </div>
)

export default Footer
