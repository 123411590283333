import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 28 24" fill="white" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.75 2.25V21.375C2.75 23.2425 4.2575 24.75 6.125 24.75H25.25"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.125 19.125L11.2888 13.095C12.1438 12.105 13.6625 12.0375 14.585 12.9712L15.6538 14.04C16.5763 14.9625 18.095 14.9062 18.95 13.9162L24.125 7.875"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
)

export default Icon
