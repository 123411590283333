import React from 'react'
import { useSelector } from 'react-redux'
import './style.scss'

const TxAmount = ({ className = '', action, title, ...props }) => {
  const { STT_IN } = useSelector((state) => state.transactions.STT_IN_OUT)
  if (STT_IN.includes(action)) {
    return (
      <div className={`amount-in ${className}`} data-action={action} {...props}>
        +{title}
      </div>
    )
  }
  return (
    <div className={`amount-out ${className}`} data-action={action} {...props}>
      -{title}
    </div>
  )
}

export default TxAmount
