/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import './style.scss'
import { linksNavigatorMobile } from 'components/Menu/config'
import { toggleModalSoon } from 'state/app/actions'

const AVAILABLE_LINK = []
const AVAILABLE_LABEL = []
linksNavigatorMobile.forEach((item) => {
  AVAILABLE_LINK.push(item.href)
  AVAILABLE_LABEL.push(item.label.toLowerCase())
})
const NavigationMobile = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const path = history.location.pathname
  const [activePath, setActivePath] = useState(path)

  useEffect(() => {
    if (AVAILABLE_LINK.includes(path)) {
      setActivePath(path)
    }
  }, [path, setActivePath])
  return (
    <div className="navigation-wrapper">
      <div className="navigation-mobile">
        <ul>
          {linksNavigatorMobile.map((item, index) => {
            const isHttp = item?.href?.startsWith('http')
            const Tag = isHttp ? 'a' : Link
            const propsLink = isHttp ? { href: item.href, target: item.target, relL: 'noreferrer' } : { to: item.href }
            const Icon = item.icon
            if (item.type === 'soon') {
              return (
                <li
                  className={`list ${path.includes(item.href.toLowerCase()) ? 'active' : ''}`}
                  key={`navigator-${index}`}
                  role="presentation"
                >
                  <Tag {...propsLink} onClick={() => dispatch(toggleModalSoon({ toggle: true }))} role="presentation">
                    {typeof Icon === 'string' ? <img src={Icon} alt="" /> : item.icon && <Icon />}
                    <span>{item.label}</span>
                  </Tag>
                  <span className="soon">(Soon)</span>
                </li>
              )
            }
            return (
              <li
                className={`list ${path.includes(item.href.toLowerCase()) ? 'active' : ''}`}
                key={`navigator-${index}`}
                role="presentation"
              >
                <Tag {...propsLink}>
                  {typeof Icon === 'string' ? <img src={Icon} alt="" /> : item.icon && <Icon />}
                  <span>{item.label}</span>
                </Tag>
              </li>
            )
          })}
          <div className="indicator" />
        </ul>
      </div>
    </div>
  )
}

export default NavigationMobile
