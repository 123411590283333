import './styles.scss'
import React, { useEffect, useState } from 'react'
import RefChild from './component/RefChild'
import NetWorkInfo from './component/NetworkInfo'
import ReferrerCard from 'components/ReferrerCard'
import { useSelector, useDispatch } from 'react-redux'
import { getInvestmentStatisticReport, getTotalReport } from '../../state/networks/actions'

const Network = () => {
  const dispatch = useDispatch()
  const { totalReport, investmentStatisticReport } = useSelector((state) => state.networks)
  const { userInfo } = useSelector((state) => state.user)
  useEffect(() => {
    dispatch(getInvestmentStatisticReport())
  }, [dispatch])

  /* Fetch total report */
  const [getTotalReportParams] = useState({
    from_date: '',
    to_date: '',
  })
  useEffect(() => {
    dispatch(getTotalReport(getTotalReportParams))
  }, [dispatch, getTotalReportParams])

  return (
    <div className="container warp-page-network">
      <NetWorkInfo totalReport={totalReport} investmentStatisticReport={investmentStatisticReport} />
      <ReferrerCard className="ref-view" userInfo={userInfo} />
      <RefChild />
    </div>
  )
}

export default Network
