import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { DECIMALS } from 'constants/index'
import { roundNumber } from 'helpers'
import './styles.scss'
import { FormattedMessage } from 'react-intl'

const Amount = ({
  baseCurrency,
  baseNativeToken,
  baseChainAttr,
  feeChain,
  feeToken,
  minimumAmount,
  is2Fa,
  value,
  onChange,
  userInfo,
  ...props
}) => (
  <div className="box-amount">
    <div className="w-input-label">
      <p className="input-label">
        <FormattedMessage id="Amount" />
      </p>
      <p className="balance-label">
        <FormattedMessage id="Available:" />{' '}
        <span>
          {baseCurrency?.balance !== undefined ? (
            <>
              <CurrencyFormat
                value={roundNumber(baseCurrency.balance / 10 ** DECIMALS)}
                displayType="text"
                thousandSeparator
                renderText={(v) => v}
              />
              &nbsp;
              {baseCurrency.title}
            </>
          ) : (
            '--'
          )}
        </span>
      </p>
    </div>

    <div className="box-input-amount">
      <input
        value={value}
        placeholder={`Minimum amount (per transaction) ${minimumAmount}`}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      />
      {baseCurrency?.balance !== undefined && (
        <button type="button" onClick={() => onChange(roundNumber(baseCurrency.balance / 10 ** DECIMALS))}>
          <FormattedMessage id="Max" />
        </button>
      )}
    </div>
    <div className="wrapper-availabel-fee">
      {(baseNativeToken && feeChain) || (baseCurrency && feeToken) ? (
        <>
          <div />
          <div className="amount-fee">
            <p>
              <FormattedMessage id="Fee" />:
            </p>
            &nbsp;
            <p>
              {baseNativeToken && feeChain ? (
                <span>
                  {feeChain}&nbsp;{baseNativeToken.code}
                </span>
              ) : (
                ''
              )}
              {baseCurrency && feeToken ? (
                <span>
                  {(() => {
                    if (baseChainAttr?.withdraw_fee_token_type === 0) {
                      return `${roundNumber(feeToken, { scale: 2 })}% ${baseCurrency?.code}`
                    }
                    if (baseChainAttr?.withdraw_fee_token_type === 1) {
                      return `${roundNumber(feeToken / 1e18, { scale: 2 })} ${baseCurrency?.code}`
                    }
                    return ''
                  })()}
                </span>
              ) : (
                ''
              )}
            </p>
          </div>
        </>
      ) : (
        <span />
      )}
    </div>
  </div>
)

export default Amount
