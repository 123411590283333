import React from 'react'
import './style.scss'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { isNumber, roundNumber } from 'helpers'
import { useSelector } from 'react-redux'
import MediaCard from 'components/MediaCard'
import { FormattedMessage } from 'react-intl'
import FormatAmount from 'widgets/FormatAmount'

const CardInvestmentItem = ({ investmentItem, onBuy }) => {
  const { currency } = useSelector((state) => state.wallets)
  const fCurrencyInvest = currency?.find((curr) => curr._id === investmentItem?.currency_invest)
  const fCurrencyReward = currency?.find((curr) => curr._id === investmentItem?.currency_bonus_token)

  const opvReward = investmentItem?.meta_data?.find((item) => item.key === 'OPV Reward')

  if (!investmentItem) return <></>
  return (
    <div className="investment-card-item">
      <div className="investment-card-item-dup-logo">
        <MediaCard fileUrl={investmentItem?.video || investmentItem?.avatar} />
      </div>
      <div className="investment-item-content">
        <h3>{investmentItem.title}</h3>
        <div className="investment-content">
          <p>
            <span>
              <FormattedMessage id="Price" />:
            </span>
            <span>
              <FormatAmount
                value={roundNumber(investmentItem.price_invest, { decimals: 18 })}
                nullValue="--"
                suffix={fCurrencyInvest ? ` ${fCurrencyInvest.code}` : ''}
              />
            </span>
          </p>
          <p>
            <span>
              <FormattedMessage id="OPV Bonus" />:
            </span>
            <span>
              <FormatAmount
                value={roundNumber(investmentItem.bonus_token, { decimals: 18 })}
                nullValue="--"
                suffix={fCurrencyReward ? ` ${fCurrencyReward.code}` : ''}
              />
            </span>
          </p>
          <p>
            <span>
              <FormattedMessage id="Daily Reward" />:
            </span>
            <span>{opvReward && opvReward?.value}</span>
          </p>
          <p>
            <span>
              <FormattedMessage id="Dividend" />:
            </span>
            <span>{isNumber(investmentItem?.dividend_rate) ? `${investmentItem.dividend_rate}%` : '--'}</span>
          </p>
        </div>
      </div>
      <div className="investment-card-item-actions">
        <ButtonPrimary onClick={() => onBuy(investmentItem)}>
          <FormattedMessage id="Buy NFT" />
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default CardInvestmentItem
