import TxAmount from 'components/TxAmount'
import TxStatus from 'components/TxStatus'
import { formatDate, isNumber, roundNumber } from 'helpers'
import CurrencyFormat from 'react-currency-format'

const columns = ({ currency }) => [
  {
    title: <div className="invest-bought-package">Package</div>,
    dataIndex: 'package',
    render: (_, record) => <div className="invest-bought-package">{record?.package ? record.package.title : ''}</div>,
  },
  {
    title: <div className="invest-bought-amount">Amount</div>,
    dataIndex: 'price_usd_invest',
    render: (_, record) => {
      const fCurrency = currency?.find((curr) => curr._id === record.currency_invest)
      return (
        <div className="investment-bought-amount">
          {isNumber(+record?.price_usd_invest) ? (
            <TxAmount
              action={record.action}
              title={
                <CurrencyFormat
                  value={roundNumber(record.price_usd_invest, { decimals: 18 })}
                  displayType="text"
                  thousandSeparator
                  suffix={fCurrency ? ` ${fCurrency?.code}` : ''}
                  renderText={(t) => t}
                />
              }
            />
          ) : (
            '--'
          )}
        </div>
      )
    },
  },
  {
    title: <div className="invest-bought-status">Status</div>,
    dataIndex: 'status',
    render: () => (
      <div className="invest-bought-status">
        <TxStatus status="COMPLETED" title="COMPLETED" />
      </div>
    ),
  },
  {
    title: <div className="invest-bought-time">Time</div>,
    dataIndex: 'createdAt',
    render: (text) => <div>{text ? formatDate(text * 1000) : ''}</div>,
  },
]

export default columns
