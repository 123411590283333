import { MetamaskIcon, TrustWalletIcon, WalletConnectIcon } from 'widgets/Svg'
import { ConnectorNames } from 'connectors'
import { APP_URL } from 'constants/index'

const appUrl = APP_URL.slice(8)

export const connectorsList = [
  {
    title: 'Metamask',
    icon: MetamaskIcon,
    installed: typeof window !== 'undefined' && Boolean(window.ethereum?.isMetaMask),
    connectorId: ConnectorNames.Injected,
    priority: 1,
    href: `https://metamask.app.link/dapp/${appUrl}/`,
  },
  {
    title: 'Trust Wallet',
    icon: TrustWalletIcon,
    connectorId: ConnectorNames.Injected,
    installed:
      typeof window !== 'undefined' &&
      (Boolean(window.ethereum?.isTrust) ||
        // @ts-ignore
        Boolean(window.ethereum?.isTrustWallet)),
    priority: 4,
    href: `https://link.trustwallet.com/open_url?coin_id=20000714&url=https://${appUrl}/`,
    downloadLink: {
      desktop: 'https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph/related',
    },
  },
  {
    title: 'WalletConnect',
    icon: WalletConnectIcon,
    connectorId: ConnectorNames.WalletConnect,
    priority: 5,
  },
]
