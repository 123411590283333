export const GET_PACKAGE_LIST = 'GET_PACKAGE_LIST'
export const GET_INVESTMENT_LIST = 'GET_INVESTMENT_LIST'

export const GET_ORDER_INVESTMENT_REPORT = 'GET_ORDER_INVESTMENT_REPORT'
export const GET_ORDER_INVESTMENT_REPORT_SUCCESS = 'GET_ORDER_INVESTMENT_REPORT_SUCCESS'

export const GET_CUSTOMER_PACKAGE_DETAIL = 'GET_CUSTOMER_PACKAGE_DETAIL'
export const GET_CUSTOMER_PACKAGE_DETAIL_SUCCESS = 'GET_CUSTOMER_PACKAGE_DETAIL_SUCCESS'

export const GET_LEVEL_CUSTOMER = 'GET_LEVEL_CUSTOMER'
export const GET_LEVEL_CUSTOMER_SUCCESS = 'GET_LEVEL_CUSTOMER_SUCCESS'

export const REPORT_TOTAL = 'REPORT_TOTAL'
export const REPORT_TOTAL_SUCCESS = 'REPORT_TOTAL_SUCCESS'
