import { BASE_SCAN_URLS } from 'connectors'
import getLocaleChainId from './getLocaleChainIId'

/**
 * getBlockExploreLink
 * @param {*} data string
 * @param {*} type 'transaction' | 'token' | 'address' | 'block' | 'countdown'
 * @param {*} chainId number
 * @returns https link
 */
export function getBlockExploreLink(data, type, forceChainId) {
  const localeChainId = getLocaleChainId()
  const chainId = forceChainId || localeChainId
  switch (type) {
    case 'transaction': {
      return `${BASE_SCAN_URLS[chainId]}/tx/${data}`
    }
    case 'token': {
      return `${BASE_SCAN_URLS[chainId]}/token/${data}`
    }
    case 'block': {
      return `${BASE_SCAN_URLS[chainId]}/block/${data}`
    }
    case 'countdown': {
      return `${BASE_SCAN_URLS[chainId]}/block/countdown/${data}`
    }
    default: {
      return `${BASE_SCAN_URLS[chainId]}/address/${data}`
    }
  }
}
export function getBscScanLinkForNft(collectionAddress, tokenId, forceChainId) {
  const localeChainId = getLocaleChainId()
  const chainId = forceChainId || localeChainId
  return `${BASE_SCAN_URLS[chainId]}/token/${collectionAddress}?a=${tokenId}`
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function toLocaleString(x) {
  return x.toLocaleString('fullwide', { useGrouping: false })
}
