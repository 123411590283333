import produce from 'immer'
import { orderBy } from 'lodash'
import { OPTIONS_LANG, CACHE_LANGUAGE, DEFAULT_LANGUAGE } from 'language/config'
import * as types from './constants'

const languageLocal = localStorage.getItem(CACHE_LANGUAGE)
const language = languageLocal || DEFAULT_LANGUAGE || 'en'
const selectLanguage = OPTIONS_LANG.find(({ id }) => id === language)

const initialState = {
  modalSoon: {
    toggle: false,
  },
  staticData: undefined,
  listCountry: undefined,
  language: selectLanguage ? selectLanguage.id : DEFAULT_LANGUAGE,
  optionLanguage: OPTIONS_LANG,
}

export default function AppReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SET_LANGUAGE: {
        localStorage.setItem(CACHE_LANGUAGE, payload || DEFAULT_LANGUAGE)
        draft.language = payload || DEFAULT_LANGUAGE
        break
      }

      case types.TOGGLE_MODAL_SOON:
        draft.modalSoon = payload
        break
      case types.GET_LIST_COUNTRY_SUCCESS:
        draft.listCountry = orderBy(payload, [(c) => c.title.toLowerCase()], ['asc'])
        break
      default:
        return { ...state }
    }
  })
}
