import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'
import CardBalance from './components/CardBalance'
import './style.scss'
import WalletsList from './components/WalletsList'

const Wallet = () => {
  const { currency } = useSelector((state) => state.wallets)
  return (
    <div className=" wallet-container">
      <div className="wallet-body">
        <div className="wallet-content">
          <Row gutter={[30, 10]}>
            <Col xs={24} sm={24} md={12}>
              <CardBalance currency={currency} />
            </Col>
            <Col xs={0} sm={0} md={12}>
              <div className="wallet-right">
                <img className="wallet-decorate" src="/images/wallets/wallet_decorate.png" alt="" />
              </div>
            </Col>
          </Row>
          <hr className="wallet-br-01" />
          <WalletsList currency={currency} />
        </div>
      </div>
    </div>
  )
}

export default Wallet
