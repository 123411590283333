import { useDispatch, useSelector } from 'react-redux'
import ModalCustom from 'components/BaseModal'
import React, { useEffect, useMemo, useState } from 'react'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './style.scss'
import { investBuyNft } from 'state/invest/actions'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { FormattedMessage } from 'react-intl'
import { Select } from 'antd'
import FormatAmount from 'widgets/FormatAmount'
import { roundNumber } from 'helpers'

const ModalInvest = ({ visible, dataModal, userInfo, closeModal, onSuccess }) => {
  const dispatch = useDispatch()
  const wallets = useSelector((state) => state.wallets.currency)
  const fCurrencyInvest = wallets?.find((curr) => curr._id === dataModal?.currency_invest)
  const fCurrencyOPV = wallets?.find((curr) => curr.code === 'OPV')

  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [selectCurrency, setSelectCurrency] = useState()

  useEffect(() => {
    if (fCurrencyInvest) {
      setSelectCurrency(fCurrencyInvest.code)
    }
  }, [fCurrencyInvest])

  // const { price: opvPrice, fetchPrice } = useTokenPrice(TOKEN)

  const optionsCurrency = useMemo(() => {
    if (!dataModal) return []
    return dataModal?.currency_method_pay?.map((symbol) => ({
      label: symbol,
      value: symbol,
    }))
  }, [dataModal])

  const selectWallet = useMemo(() => {
    if (wallets && selectCurrency) {
      return wallets.find((wallet) => wallet.code === selectCurrency)
    }
    return undefined
  }, [wallets, selectCurrency])

  const handleInvest = () => {
    if (!dataModal) return false
    if (!selectCurrency) return setErrorMess('Please select currency')
    setLoading(true)
    executeRecaptcha((recaptcha) => {
      dispatch(
        investBuyNft(
          { invest_package_id: dataModal._id, currency_method_pay_code: selectCurrency, recaptcha },
          () => {
            closeModal()
            setLoading(false)
            onSuccess()
            // fetchPrice()
          },
          (error) => {
            setErrorMess(error?.message)
            setLoading(false)
            // fetchPrice()
          },
        ),
      )
    })
  }

  const handleClose = () => {
    closeModal()
    setErrorMess('')
  }

  return (
    <ModalCustom width={540} className="modal-confirm-buy-nft" buttonLabel="Create" visible={visible}>
      <div className="modal-content">
        <div className="modal-confirm-heading">
          <FormattedMessage id="Confirm Buy NFT" />
        </div>
        <div className="modal-confirm-body">
          <p>
            <FormattedMessage id="NFT will automatically mint to your wallet !" />
          </p>
          <p>
            <FormattedMessage id="Your wallet" />: <span>{userInfo?.wallet_address}</span>
          </p>
          <div className="options-currency">
            <div className="options-currency-label">Payment buy: </div>
            <Select dropdownClassName="options-currency-dropdown" value={selectCurrency} onChange={setSelectCurrency}>
              {optionsCurrency.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="package-detail-price">
            {fCurrencyInvest?.code === 'OPV' ? (
              <p>
                <FormatAmount
                  value={roundNumber(dataModal?.price_invest * fCurrencyInvest?.usd_rate, { decimals: 18 })}
                  nullValue="--"
                  suffix={` USDT`}
                />{' '}
                = <FormatAmount value={roundNumber(dataModal?.price_invest, { decimals: 18 })} nullValue="--" suffix={` OPV`} />
              </p>
            ) : (
              <p>
                <FormatAmount
                  value={roundNumber(dataModal?.price_invest, { decimals: 18 })}
                  nullValue="--"
                  suffix={` ${fCurrencyInvest?.code}`}
                />{' '}
                ={' '}
                <FormatAmount
                  value={roundNumber(dataModal?.price_invest / fCurrencyOPV?.usd_rate, { decimals: 18 })}
                  nullValue="--"
                  suffix={` OPV`}
                />
              </p>
            )}
            <p>
              Balance:{' '}
              <FormatAmount
                value={roundNumber(selectWallet?.balance, { decimals: 18 })}
                nullValue="--"
                suffix={` ${selectWallet?.code}`}
              />
            </p>
          </div>
        </div>
        {errorMess && <div className="error-mess">{errorMess}</div>}
        <div className="modal-confirm-button">
          <ButtonPrimary className="modal-confirm-button-cancel" onClick={handleClose}>
            <FormattedMessage id="Cancel" />
          </ButtonPrimary>
          <ButtonPrimary loading={loading} onClick={handleInvest}>
            <FormattedMessage id="Confirm" />
          </ButtonPrimary>
        </div>
      </div>
    </ModalCustom>
  )
}

export default ModalInvest
