/* eslint-disable no-nested-ternary */
import React from 'react'
import { List } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import './styles.scss'
import Nodata from 'components/Nodata'
import ItemRecentTransaction from '../itemRecentTransaction'
import { FormattedMessage } from 'react-intl'
import PaginationCustomer from 'components/PaginationCustomer'

const WalletHistoryMobile = ({ total, setTxParams, dataSource, current, setViewDetails, setModalVerifyCode }) => {
  const responsiveList = { gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }
  return (
    <>
      {dataSource.length > 0 ? (
        <div
          id="scrollableDiv"
          className="recent-txh-mobile"
          style={{
            position: 'relative',
            minHeight: 700,
          }}
        >
          <>
            <List
              dataSource={dataSource || []}
              grid={responsiveList}
              renderItem={(item, index) => (
                <List.Item key={`list-${index}`}>
                  <ItemRecentTransaction
                    data={item}
                    index={index}
                    key={index}
                    setViewDetails={setViewDetails}
                    setModalVerifyCode={setModalVerifyCode}
                  />
                </List.Item>
              )}
              locale={{
                emptyText:
                  total === 0 ? (
                    <div>
                      <FormattedMessage id="Empty" />
                    </div>
                  ) : (
                    <></>
                  ),
              }}
            />
            <PaginationCustomer
              total={total}
              current={current}
              onChange={(page, pageSize) => {
                setTxParams((prev) => ({
                  ...prev,
                  page,
                  pageSize,
                }))
              }}
            />
          </>
        </div>
      ) : dataSource === undefined ? (
        <h4>Loading...</h4>
      ) : (
        <div>
          <Nodata />
        </div>
      )}
    </>
  )
}

export default WalletHistoryMobile
