import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.scss'
import ButtonPrimary from 'widgets/ButtonPrimary'
import InputCode from 'components/InputCode'
import AuthCard from '../AuthCard'
import { VERIFY_TYPE } from '../const'

const VerifyEmailCode = ({
  type,
  initialField,
  buttonLabel,
  countdown,
  loading,
  errorMess,
  onFinish,
  onAutoFinish,
  onResendCode,
  ...props
}) => {
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(countdown)

  const onChangeCode = (value) => {
    const code = Object.values(value).filter((o) => o)
    if (code.length >= initialField) {
      if (onAutoFinish) onAutoFinish(value)
    }
    setCodeValue(value)
  }

  useEffect(() => {
    if (type === VERIFY_TYPE.EMAIL) {
      const countDownInterval = setInterval(() => {
        setTimeCountDown((prev) => {
          if (prev > 0) {
            const down = prev - 1
            return down
          }
          return 0
        })
      }, 1000)

      return () => {
        clearInterval(countDownInterval)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetCountdownCallback = () => {
    setTimeCountDown(countdown)
  }

  return (
    <AuthCard className="verify-active-account-code fadeIn" {...props}>
      <h2 className="verify-title">
        {type === VERIFY_TYPE.EMAIL && <FormattedMessage id="Verification code" />}
        {type === VERIFY_TYPE.AUTHEN && <FormattedMessage id="Google Authenticator" />}
      </h2>
      <div className="verify-input">
        <InputCode value={codeValue} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
      </div>
      {onResendCode && (
        <div className="verify-resend">
          {timeCountDown <= 0 ? (
            <a onClick={() => onResendCode(resetCountdownCallback)} role="presentation">
              <FormattedMessage id="Resend" />
            </a>
          ) : (
            <div>
              <FormattedMessage id="Resend in" /> {timeCountDown}s
            </div>
          )}
          <a
            onClick={async () => {
              onChangeCode(await navigator.clipboard.readText())
            }}
            role="presentation"
          >
            <FormattedMessage id="Paste" />
          </a>
        </div>
      )}
      <div className="verify-error">{errorMess}</div>
      <div className="verify-action">
        <ButtonPrimary className="active" loading={loading} onClick={() => onFinish(codeValue)} style={{ maxWidth: '150px' }}>
          {buttonLabel || <FormattedMessage id="Done" />}
        </ButtonPrimary>
      </div>
    </AuthCard>
  )
}

VerifyEmailCode.defaultProps = {
  type: VERIFY_TYPE.EMAIL,
  initialField: 6,
  countdown: 50,
  onFinish: () => null,
}

export default VerifyEmailCode
