/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { Form, Upload, DatePicker, Row, Col, Select, message } from 'antd'

import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ControlInputSelect from 'components/ControlInputSelect'
import ControlInputText from 'components/ControlInputText'
import { checkDevice, dummyRequest, getBase64 } from 'helpers'
import { getListCountry } from 'state/app/actions'
import { getProfile, updateKyc } from 'state/user/actions'
import { uploadFile } from 'state/file/actions'
import moment from 'moment'
import BackLink from 'components/BackLink'
import { FormattedMessage, injectIntl } from 'react-intl'
import { BackLinkIcon } from 'widgets/Svg'
import { countryCode } from 'emoji-flags'
import InputSelect from 'components/InputSelect'

const { Option } = Select

const { Dragger } = Upload
export const ID_TYPE = [
  {
    label: 'Passport',
    value: 'PASSPORT',
  },
  {
    label: 'ID Card',
    value: 'INDENTITY_CARD',
  },
]

const DOCUMENT_IMAGE_TYPE = {
  FRONT: 'FRONT',
  BACK: 'BACK',
  SELFIE: 'SELFIE',
}

const PrimaryKYC = ({ intl }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const listCountry = useSelector((state) => state.app.listCountry)
  const userInfo = useSelector((state) => state.user.userInfo)
  const defaultIdType = userInfo?.kycInfo?.type ? userInfo?.kycInfo?.type : ID_TYPE[0].value

  const [idType, setIdType] = useState(defaultIdType)
  const [loading, setLoading] = useState(false)
  const [frontImageData, setFrontImageData] = useState(null)
  const [frontImageError, setFrontImageError] = useState('')

  const [backImageData, setBackImageData] = useState(null)
  const [backImageError, setBackImageError] = useState(null)

  const [selfieImageData, setSelfieImageData] = useState(null)
  const [selfieImageError, setSelfieImageError] = useState(null)
  const [inputBirthday, setInputBirthday] = useState(
    `${moment(userInfo?.profile?.birthday)._d}`.toUpperCase() !== 'Invalid Date'.toUpperCase()
      ? userInfo?.profile?.birthday
      : undefined,
  )
  const handleOnChangeImage = (e, type) => {
    if (e.file.status === 'uploading') {
      return
    }
    if (e.file.status === 'done') {
      getBase64(e.file.originFileObj).then(() => {
        switch (type) {
          case DOCUMENT_IMAGE_TYPE.FRONT: {
            setFrontImageError('')

            dispatch(
              uploadFile(
                {
                  key: 1,
                  image: get(e, 'file.originFileObj', null),
                },
                (receipt) => {
                  setFrontImageData({
                    ...receipt,
                    image: receipt.full_link,
                  })
                },
              ),
            )
            break
          }
          case DOCUMENT_IMAGE_TYPE.BACK: {
            dispatch(
              uploadFile(
                {
                  key: 2,
                  image: get(e, 'file.originFileObj', null),
                },
                (receipt) => {
                  setBackImageData({
                    ...receipt,
                    image: receipt.full_link,
                  })
                },
              ),
            )
            setBackImageError('')
            break
          }
          case DOCUMENT_IMAGE_TYPE.SELFIE: {
            dispatch(
              uploadFile(
                {
                  key: 3,
                  image: get(e, 'file.originFileObj', null),
                },
                (receipt) => {
                  setSelfieImageData({
                    ...receipt,
                    image: receipt.full_link,
                  })
                },
              ),
            )
            setSelfieImageError('')
            break
          }
          default:
            break
        }
      })
    }
  }
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }

    const isLt20M = file.size / 1024 / 1024 < 20
    if (!isLt20M) {
      message.error('Image size cannot exceed 20MB')
    }

    return isJpgOrPng && isLt20M
  }

  const handleSubmitForm = () => {
    try {
      if (!frontImageData) setFrontImageError(intl.formatMessage({ id: 'Error' }))
      if (!backImageData) setBackImageError(intl.formatMessage({ id: 'Error' }))
      if (!selfieImageData) setSelfieImageError(intl.formatMessage({ id: 'Error' }))
      form.validateFields().then((values) => {
        if (!frontImageData || !backImageData || !selfieImageData) return

        setLoading(true)
        const country = JSON.parse(values.country_code)
        const payload = {
          ...values,
          image_front: frontImageData._id,
          image_back: backImageData._id,
          image_selfie: selfieImageData._id,
          country_code: country.value,
          type: JSON.parse(values.type).value,
          birthday: inputBirthday,
        }
        executeRecaptcha((recaptcha) => {
          dispatch(
            updateKyc(
              { ...payload, recaptcha },
              () => {
                dispatch(getProfile())
                setLoading(false)
                history.push('/profile/security')
              },
              () => {
                setLoading(false)
              },
            ),
          )
        })
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const countryOptions = useMemo(() => {
    if (listCountry) {
      return listCountry.map((item) => {
        const countryEmoji = countryCode(item.code)
        return {
          ...item,
          label: countryEmoji && checkDevice ? `${countryEmoji?.emoji} ${item.title}` : item.title,
          value: item.code,
        }
      })
    }
    return []
  }, [listCountry])

  useEffect(() => {
    dispatch(getListCountry())
  }, [dispatch])

  // kyc: 0 not upload, 1 upload, 2 active, 3 inject,deactive
  useEffect(() => {
    if (userInfo?.kycInfo && countryOptions) {
      const fCountry = countryOptions.find((country) => country.value === userInfo.profile.country)
      const fType = ID_TYPE.find((type) => type.value === userInfo.kycInfo.type)
      form.setFieldsValue({
        country_code: JSON.stringify(fCountry),
        type: JSON.stringify(fType),
        number: userInfo.kycInfo.number,
        fistname: userInfo.profile.fistname,
        lastname: userInfo.profile.lastname,
        birthday: moment(userInfo.profile.birthday),
      })

      if ([1, 2].includes(userInfo?.status_kyc)) {
        setFrontImageData({
          full_link: userInfo.kycInfo.image_front,
        })
        setBackImageData({
          full_link: userInfo.kycInfo.image_back,
        })
        setSelfieImageData({
          full_link: userInfo.kycInfo.image_selfie,
        })
      }
    }
  }, [form, userInfo, countryOptions])
  const handleChangeDate = (date, dateString) => {
    setInputBirthday(dateString)
  }

  return (
    <div className="container warp-page-primary-kyc">
      <div className="page-primary-kyc-content">
        <div className="go-back">
          <BackLink label={<FormattedMessage id="Primary KYC" />} icon={<BackLinkIcon />} to="/profile/security" />
        </div>

        <Form form={form}>
          <div className="primary-kyc-content-form">
            {/*  */}
            <div className="box-input-select">
              <Form.Item
                name="country_code"
                rules={[
                  {
                    required: true,
                    message: 'Please Select nationality',
                  },
                ]}
              >
                <ControlInputSelect
                  showSearch
                  style={{ fontSize: '16px' }}
                  label={<FormattedMessage id="Nationality" />}
                  placeholder={intl.formatMessage({ id: 'Select nationality' })}
                  dataSelect={countryOptions}
                  readOnly={userInfo?.status_kyc === 1}
                />
              </Form.Item>
              {/*  */}
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Please Select ID Type',
                  },
                ]}
              >
                <ControlInputSelect
                  label={<FormattedMessage id="ID Type" />}
                  placeholder={intl.formatMessage({ id: 'Select ID type' })}
                  dataSelect={ID_TYPE}
                  readOnly={userInfo?.status_kyc === 1}
                  onChange={(v) => setIdType(JSON.parse(v).value)}
                />
              </Form.Item>
            </div>
            {/*   */}
            <div className="box-input-text">
              <Form.Item name="number" rules={[{ required: true, message: <FormattedMessage id="Please enter ID Number" /> }]}>
                <ControlInputText
                  label={<FormattedMessage id="ID Number" />}
                  placeholder={intl.formatMessage({ id: 'Please enter your ID number' })}
                  readOnly={userInfo?.status_kyc === 1}
                />
              </Form.Item>
              {/*  */}
              <Row style={{ width: '100%' }}>
                <Col lg={{ span: 12 }} span={24}>
                  <Form.Item
                    name="fistname"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="Please enter first name" />,
                      },
                    ]}
                  >
                    <ControlInputText
                      label={<FormattedMessage id="First Name" />}
                      placeholder={intl.formatMessage({ id: 'Please enter your First Name' })}
                      readOnly={userInfo?.status_kyc === 1}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }} span={24}>
                  <Form.Item
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="Please enter last name" />,
                      },
                    ]}
                  >
                    <ControlInputText
                      label={<FormattedMessage id="Last Name" />}
                      placeholder={intl.formatMessage({ id: 'Please enter your Last Name' })}
                      readOnly={userInfo?.status_kyc === 1}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/*  */}
              <Form.Item
                rules={[
                  {
                    message: <FormattedMessage id="Please enter Date of birth" />,
                  },
                ]}
                className="form-item-calendar"
              >
                <p className="form-label">
                  <FormattedMessage id="Date of Birth" />
                </p>
                <div>
                  <DatePicker
                    placeholder={intl.formatMessage({ id: 'Please enter your Date of birth' })}
                    autoComplete="true"
                    format="YYYY-MM-DD"
                    onChange={(date, dateString) => handleChangeDate(date, dateString)}
                    defaultValue={userInfo?.profile?.birthday ? moment(userInfo?.profile?.birthday) : undefined}
                    disabled={userInfo?.status_kyc === 1}
                  />
                </div>
              </Form.Item>
            </div>
            {/*  */}
            <div className="box-input-file">
              <div className="primary-kyc-update-card-photo-title">
                {idType === ID_TYPE[0].value ? (
                  <>
                    <p className="input-file-item-title">
                      <FormattedMessage id="ID Card Photo" />
                    </p>
                    <p className="input-file-item-description">
                      <FormattedMessage id="Please make sure the content of the photo is complete and clearly visible" /> <br />
                      <FormattedMessage id="Only supports" />
                      &nbsp;
                      <span>
                        <FormattedMessage id="JPG, JPEF, PNG," />
                      </span>
                      &nbsp;
                      <FormattedMessage id="image formats" /> <br />
                      <FormattedMessage id="Image size cannot exceed" />
                      &nbsp;
                      <span>
                        <FormattedMessage id="20MB" />
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="input-file-item-title">
                      <FormattedMessage id="Passport Photo" />
                    </p>
                    <p className="input-file-item-description">
                      <FormattedMessage id="Please make sure the content of the photo is complete and clearly visible" /> <br />
                      <FormattedMessage id="Only supports" />
                      &nbsp;
                      <span>
                        <FormattedMessage id="JPG, JPEF, PNG," />
                      </span>
                      &nbsp;
                      <FormattedMessage id="image formats" /> <br />
                      <FormattedMessage id="Image size cannot exceed" />
                      &nbsp;
                      <span>
                        <FormattedMessage id="20MB" />
                      </span>
                    </p>
                  </>
                )}
              </div>
              <div className="primary-kyc-update-card-photo">
                {idType === ID_TYPE[0].value ? (
                  <div className="input-file-item" style={{ maxWidth: '100%' }}>
                    <Dragger
                      disabled={userInfo?.status_kyc === 1}
                      customRequest={dummyRequest}
                      showUploadList={false}
                      accept=".jpg,.jpeg,.png"
                      beforeUpload={beforeUpload}
                      onChange={(e) => {
                        handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.FRONT)
                      }}
                      style={{ border: frontImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                    >
                      {frontImageData ? (
                        <img src={frontImageData.full_link} alt="" />
                      ) : (
                        <>
                          {/* <p className="top-sub">Passport photo Front</p> */}
                          <p className="ant-upload-drag-icon">
                            <img src="/images/upload-file.png" alt="" />
                          </p>
                          <p className="ant-upload-text">
                            <span>
                              <FormattedMessage id="Click me " />
                            </span>
                            <FormattedMessage id="to upload files!" />
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                ) : (
                  <div className="input-file-item">
                    <Dragger
                      disabled={userInfo?.status_kyc === 1}
                      customRequest={dummyRequest}
                      showUploadList={false}
                      accept=".jpg,.jpeg,.png"
                      beforeUpload={beforeUpload}
                      onChange={(e) => {
                        handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.FRONT)
                      }}
                      style={{ border: frontImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                    >
                      {frontImageData ? (
                        <img src={frontImageData.full_link} alt="" />
                      ) : (
                        <>
                          <p className="top-sub">
                            <FormattedMessage id="ID photo Front" />
                          </p>
                          <p className="ant-upload-drag-icon">
                            <img src="/images/upload-file.png" alt="" />
                          </p>
                          <p className="ant-upload-text">
                            <span>
                              <FormattedMessage id="Click me " />
                            </span>
                            <FormattedMessage id="to upload files!" />
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                )}

                {idType === ID_TYPE[0].value ? (
                  <div className="input-file-item">
                    <Dragger
                      disabled={userInfo?.status_kyc === 1}
                      customRequest={dummyRequest}
                      showUploadList={false}
                      accept=".jpg,.jpeg,.png"
                      beforeUpload={beforeUpload}
                      onChange={(e) => {
                        handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.BACK)
                      }}
                      style={{ border: backImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                    >
                      {backImageData ? (
                        <img src={backImageData.full_link} alt="" />
                      ) : (
                        <>
                          {/* <p className="top-sub">Passport photo Back</p> */}
                          <p className="ant-upload-drag-icon">
                            <img src="/images/upload-file.png" alt="" />
                          </p>
                          <p className="ant-upload-text">
                            <span>
                              <FormattedMessage id="Click me " />
                            </span>
                            <FormattedMessage id="to upload files!" />
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                ) : (
                  <div className="input-file-item">
                    <Dragger
                      disabled={userInfo?.status_kyc === 1}
                      customRequest={dummyRequest}
                      showUploadList={false}
                      accept=".jpg,.jpeg,.png"
                      beforeUpload={beforeUpload}
                      onChange={(e) => {
                        handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.BACK)
                      }}
                      style={{ border: backImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                    >
                      {backImageData ? (
                        <img src={backImageData.full_link} alt="" />
                      ) : (
                        <>
                          <p className="top-sub">
                            <FormattedMessage id="ID photo Back" />
                          </p>
                          <p className="ant-upload-drag-icon">
                            <img src="/images/upload-file.png" alt="" />
                          </p>
                          <p className="ant-upload-text">
                            <span>
                              <FormattedMessage id="Click me " />
                            </span>
                            <FormattedMessage id="to upload files!" />
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                )}
              </div>

              <div className="input-file-item">
                <p className="input-file-item-title">
                  <FormattedMessage id="Portrait Photo" />
                </p>
                <p className="input-file-item-description">
                  <FormattedMessage id="Please make sure the content of the photo is complete and clearly visible" /> <br />
                  <FormattedMessage id="Only supports" />
                  &nbsp;
                  <span>
                    <FormattedMessage id="JPG, JPEF, PNG," />
                  </span>
                  &nbsp;
                  <FormattedMessage id="image formats" /> <br />
                  <FormattedMessage id="Image size cannot exceed" />
                  &nbsp;
                  <span>
                    <FormattedMessage id="20MB" />
                  </span>
                </p>
                <div className="box-input">
                  <Dragger
                    disabled={userInfo?.status_kyc === 1}
                    customRequest={dummyRequest}
                    showUploadList={false}
                    accept=".jpg,.jpeg,.png"
                    beforeUpload={beforeUpload}
                    onChange={(e) => {
                      handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.SELFIE)
                    }}
                    style={{ border: selfieImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                  >
                    {selfieImageData ? (
                      <img src={selfieImageData.full_link} alt="" />
                    ) : (
                      <>
                        {/* <p className="ant-upload-text">Portrait Photo</p> */}
                        <p className="ant-upload-drag-icon">
                          {window.innerWidth >= 768 ? (
                            <img src="/images/upload-file.png" alt="" />
                          ) : (
                            <img src="/images/file-input-non-text.png" alt="" />
                          )}
                        </p>
                        <p className="ant-upload-text">
                          <span>
                            <FormattedMessage id="Click me " />
                          </span>
                          <FormattedMessage id="to upload files!" />
                        </p>
                      </>
                    )}
                  </Dragger>

                  <div className="image-example">
                    <img src="/images/file-input.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            {userInfo?.kycInfo?.reason && userInfo?.status_kyc !== 2 && (
              <div className="text-center">
                <div className="reject-reason">
                  <FormattedMessage id="Reject with reason" />: {userInfo?.kycInfo?.reason}
                </div>
              </div>
            )}
            {/*  */}
            <div className="text-center">
              {(() => {
                if ([0, 3].includes(userInfo?.status_kyc)) {
                  return (
                    <ButtonPrimary className="active" style={{ maxWidth: '150px' }} loading={loading} onClick={handleSubmitForm}>
                      <FormattedMessage id="Submit" />
                    </ButtonPrimary>
                  )
                }
                if ([1].includes(userInfo?.status_kyc)) {
                  return (
                    <ButtonPrimary style={{ maxWidth: '150px' }} disabled>
                      <FormattedMessage id="Processing" />
                    </ButtonPrimary>
                  )
                }
                return (
                  <ButtonPrimary style={{ maxWidth: '150px' }} disabled className="active">
                    <FormattedMessage id="Approved" />
                  </ButtonPrimary>
                )
              })()}
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default injectIntl(PrimaryKYC)
