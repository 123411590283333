import React, { useMemo } from 'react'
import { Table } from 'antd'
import { isMobile } from 'react-device-detect'
import './style.scss'

const TableWrapper = ({ className, columns, ...props }) => {
  const handleColumns = useMemo(() => {
    if (isMobile) {
      return columns.filter((item) => item.mobileHidden !== true)
    }
    return columns
  }, [columns])
  return (
    <Table
      className={['wCustomTable', className].join(' ')}
      columns={handleColumns}
      rowKey={(record) => `${record?.id || record?.title}`}
      {...props}
    />
  )
}

export default React.memo(TableWrapper)
