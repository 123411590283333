/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getInfoF1, getListF1 } from 'state/networks/actions'
import './styles.scss'

const RefBinaryChildItem = ({ treeInfo, levelCustomerList }) => {
  const dispatch = useDispatch()
  const [dataTree, setDataTree] = React.useState()
  const [showTree, setShowTree] = React.useState(false)
  const getIcon = (item) => [...levelCustomerList]?.find((item2) => item2?.level === item?.level_commission)?.icon

  const openTree = (id) => {
    if (dataTree === null) return

    setShowTree((prev) => !prev)
    if (!dataTree) {
      dispatch(
        getListF1(
          {
            customer_id: id,
          },
          (res) => {
            setDataTree(res)
          },
        ),
      )
    }
  }
  const hanldeInfoF1 = () => {
    dispatch(getInfoF1(treeInfo))
  }
  return (
    <div className="binary-tree">
      <div className="tree-parent">
        <img className="tree-parent-img-start" src="/images/referals/star-parent-ref.png" alt="" />
        <Link to={`/network/detail/${treeInfo?._id}`} onClick={hanldeInfoF1} className="tree-parent-name">
          <span className="tree-parent-name-left">
            <span className="tree-parent-parent-info">{treeInfo?.email}</span>
          </span>
          <span className="tree-parent-name-right" />
        </Link>
      </div>
      {dataTree && (
        <div className="tree-children">
          {showTree && dataTree?.map((item) => <RefBinaryChildItem levelCustomerList={levelCustomerList} key={item.email} />)}
        </div>
      )}
    </div>
  )
}

export default RefBinaryChildItem
