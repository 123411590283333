import { useDispatch } from 'react-redux'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { toggleModalConnect } from 'state/wallets/actions'

const ConnectWalletButton = ({ children, ...props }) => {
  const dispatch = useDispatch()
  const handleOpenModalConnect = () => {
    dispatch(toggleModalConnect({ visible: true }))
  }
  return (
    <ButtonPrimary onClick={handleOpenModalConnect} {...props}>
      {children || 'Connect Wallet'}
    </ButtonPrimary>
  )
}

export default ConnectWalletButton
