import React, { useState } from 'react'
import './style.scss'
import useWalletAuth from 'hooks/useWalletAuth'
import { connectorsList } from 'connectors'
import WalletCard from './WalletCard'
import { useSignMessage } from 'utils/web3React'
import { toast } from 'react-toastify'
import SignMessage from 'components/Auth/SignMessage'
import { FormattedMessage } from 'react-intl'
import getPreferredConfig from 'utils/getPreferredConfig'

const CardSignMessage = ({ account, displayCount = 3, onFinish }) => {
  const { login, logout } = useWalletAuth()
  const [showMore] = useState(false)
  const sortedConfig = getPreferredConfig(connectorsList)
  // Filter out WalletConnect if user is inside TrustWallet built-in browser
  const walletsToShow =
    window.ethereum?.isTrust && !window?.ethereum?.isSafePal
      ? sortedConfig.filter((wallet) => wallet.title !== 'WalletConnect')
      : sortedConfig
  const displayListConfig = showMore ? walletsToShow : walletsToShow.slice(0, displayCount)

  const { signMessageAsync } = useSignMessage()

  const [signLoading, setSignLoading] = useState(false)
  const handleConfirm = () => {
    setSignLoading(true)
    const message = Math.round(new Date().getTime() / 1000)
    signMessageAsync({ message: message.toString() })
      .then((signature) => {
        onFinish({ signature, message })
        setSignLoading(false)
      })
      .catch((error) => {
        if ('ACTION_REJECTED'.indexOf(error)) {
          toast.error('User rejected signing')
        } else {
          toast.error('Error by rejected signing')
        }
        setSignLoading(false)
      })
  }

  if (!account) {
    return (
      <div className="card-sign-wallet">
        <div className="card-content">
          <h2>
            <FormattedMessage id="Select Your Wallet" />
          </h2>
          <div className="wallet-list">
            {displayListConfig.map((wallet) => (
              <WalletCard key={wallet?.title} walletConfig={wallet} login={login} />
            ))}
          </div>
        </div>
      </div>
    )
  }
  return <SignMessage loading={signLoading} onSigninMessage={handleConfirm} onDisconnect={logout} />
}

export default CardSignMessage
