import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './styles.scss'
import ModalScan from 'components/ModalScan'
import { FormattedMessage } from 'react-intl'

const ShareLink = ({ text, notica, ...props }) => {
  const [showNoti, setShowNoti] = useState(false)
  const [open, setOpen] = useState(false)

  return (
    <div className="deposit-box-show-address-text" {...props}>
      <div>
        <p>{text ?? '--'}</p>
      </div>
      <CopyToClipboard
        text={text}
        onCopy={() => {
          setShowNoti(true)
          setTimeout(() => {
            setShowNoti(false)
          }, 1000)
          if (showNoti) setShowNoti(false)
        }}
      >
        <div>
          <img src="/images/icons/icon-copy.png" alt="" />
          <img src="/images/icons/icon-qr.png" role="presentation" onClick={() => setOpen(true)} alt="" />
          {showNoti && <div className="tooltip">{notica || 'Copied'}</div>}
        </div>
      </CopyToClipboard>

      <ModalScan
        type="deposit"
        title={<FormattedMessage id="Scan to Deposit" />}
        value={text ?? '--'}
        visible={open}
        setOpen={setOpen}
      />
    </div>
  )
}

export default ShareLink
