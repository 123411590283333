/* eslint-disable no-nested-ternary */
import BackLink from 'components/BackLink'
import Nodata from 'components/Nodata'
import { List } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import './styles.scss'
import { BackLinkIcon } from 'widgets/Svg'
import { isMobile } from 'react-device-detect'
import ItemPacKageTypeMobile from '../component/ItemPacKageTypeMobile'
import ItemMemberMobile from '../component/ItemMemberMobile'
import ItemMemberDesktop from '../component/ItemMemberDesktop'
import ItemPacKageTypeDesktop from '../component/ItemPacKageTypeDesktop'
import NetWorkInfoDetail from '../component/NetworkInfoDetail'
import { getHistoryInvestment } from '../../../state/invest/actions'
import { getCustomerSummaryFloor } from '../../../state/user/actions'
import PaginationCustomer from 'components/PaginationCustomer'
import { FormattedMessage } from 'react-intl'
import Loading from 'widgets/Loading/Loading'
import { getInvestmentStatisticReport, getTotalReport } from 'state/networks/actions'
import useCustomerDetail from 'state/user/hooks'

const responsiveList = { gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }
const NetWorkDetail = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const { userInfo } = useSelector((state) => state.user)
  const { historyInvestment } = useSelector((state) => state.invest)
  const { customerSummaryFloor } = useSelector((state) => state.user)
  const { investmentStatisticReport } = useSelector((state) => state.networks)

  const { customerDetail } = useCustomerDetail(params.id)

  useEffect(() => {
    if (params.id) {
      dispatch(getInvestmentStatisticReport({ customer_id: params.id }))
    }
  }, [dispatch, params.id])

  /* Fetch total report */
  const [getTotalReportParams] = useState({
    from_date: '',
    to_date: '',
  })
  useEffect(() => {
    dispatch(getTotalReport(getTotalReportParams))
  }, [dispatch, getTotalReportParams])

  const [paramDataInvestment, setParamDataInvestment] = useState({
    page: 1,
    pageSize: 10,
    customer_id: params.id,
  })

  useEffect(() => {
    dispatch(getHistoryInvestment(paramDataInvestment))
  }, [dispatch, paramDataInvestment])

  useEffect(() => {
    dispatch(getCustomerSummaryFloor({ customer_id: params.id }))
  }, [dispatch, params.id])

  return (
    <div className="container network-container">
      <div className="network-content">
        <div className="network-content-network">
          <div className="go-back">
            {!isMobile && (
              <BackLink
                label={userInfo._id === params.id ? userInfo?.email : customerDetail?.email}
                icon={<BackLinkIcon />}
                to="/network"
              />
            )}
          </div>
          <div className="netword-detail-wrapper">
            <NetWorkInfoDetail investmentStatisticReport={investmentStatisticReport} />
          </div>
          <h2 className="network-title">Statistic</h2>
          <div className="network-wrap-table wrapper-table-container">
            {window.innerWidth > 768 ? (
              <>
                <ItemMemberDesktop customerSummaryFloor={customerSummaryFloor} />
              </>
            ) : (
              <div className="table-static-list-member-mobile" style={{ marginBottom: '32px' }}>
                {customerSummaryFloor?.map((item, index) => (
                  <ItemMemberMobile key={`list-${index}`} item={item} />
                ))}
              </div>
            )}
          </div>
          {/*  */}
          {window.innerWidth > 768 ? (
            <div className="wrapper-table-container">
              <ItemPacKageTypeDesktop historyInvestment={historyInvestment?.rows} />
              <PaginationCustomer
                total={historyInvestment?.total}
                current={historyInvestment?.page}
                onChange={(page, pageSize) => {
                  setParamDataInvestment((prev) => ({
                    ...prev,
                    page,
                    pageSize,
                  }))
                }}
              />
            </div>
          ) : (
            <>
              <div
                id="scrollableDiv"
                style={{
                  width: '100%',
                  position: 'relative',
                  minHeight: 500,
                }}
              >
                {historyInvestment?.rows?.length > 0 ? (
                  <>
                    <List
                      dataSource={historyInvestment?.rows || []}
                      grid={responsiveList}
                      renderItem={(item, index) => (
                        <List.Item key={`list-history-${index}`}>
                          <ItemPacKageTypeMobile data={item} key={item.id} />
                        </List.Item>
                      )}
                      locale={{
                        emptyText:
                          historyInvestment?.total === 0 ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                              <FormattedMessage id="Empty" />
                            </div>
                          ) : (
                            <></>
                          ),
                      }}
                    />
                    <PaginationCustomer
                      total={historyInvestment?.total}
                      current={historyInvestment?.page}
                      onChange={(page, pageSize) => {
                        setParamDataInvestment((prev) => ({
                          ...prev,
                          page,
                          pageSize,
                        }))
                      }}
                    />
                  </>
                ) : historyInvestment?.rows === undefined ? (
                  <List
                    dataSource={[{}]}
                    grid={responsiveList}
                    renderItem={(_, index) => (
                      <List.Item key={`list-${index}`}>
                        <div>
                          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <span style={{ color: '#fff' }}>
                              <FormattedMessage id="Loading..." />
                            </span>
                            <Loading />
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                ) : (
                  <div className="wrapper-table-investment-package-empty">
                    <div className="invest-packge-empty-data">
                      <Nodata />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {/*  */}
        </div>
      </div>
    </div>
  )
}

export default NetWorkDetail
