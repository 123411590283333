import React from 'react'
import './styles.scss'
import { formatDate, isNumber, roundNumber } from 'helpers/index'
import { FormattedMessage } from 'react-intl'
import CurrencyFormat from 'react-currency-format'
import TxAmount from 'components/TxAmount'
import TxStatus from 'components/TxStatus'

const ItemTxhBoughtMobile = ({ historyItem, currency }) => {
  const fCurrency = currency?.find((curr) => curr._id === historyItem?.currency_invest)
  if (!historyItem) return <></>
  return (
    <div className="itemTxhBoughtMobile">
      <div>
        <p>
          <FormattedMessage id="Action" />
        </p>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {historyItem.action} <img style={{ width: '32px', marginLeft: '5px' }} src="/images/icons/download-pdf.png" alt="" />
        </div>
      </div>
      <div>
        <p>
          <FormattedMessage id="Amount" />
        </p>
        <p>
          {isNumber(+historyItem?.price_usd_invest) ? (
            <TxAmount
              action={historyItem.action}
              title={
                <CurrencyFormat
                  value={roundNumber(historyItem?.price_usd_invest, { decimals: 18 })}
                  displayType="text"
                  thousandSeparator
                  suffix={fCurrency ? ` ${fCurrency?.code}` : ''}
                  renderText={(t) => t}
                />
              }
            />
          ) : (
            '--'
          )}
        </p>
      </div>
      <div>
        <p>
          <FormattedMessage id="Package" />
        </p>
        <p>{historyItem._id}</p>
      </div>
      <div>
        <p>
          <FormattedMessage id="Status" />
        </p>
        <p>
          <TxStatus status="COMPLETED" title="COMPLETED" />
        </p>
      </div>
      <div>
        <p>
          <FormattedMessage id="Time" />
        </p>
        <p>{historyItem.createdAt ? formatDate(historyItem.createdAt) : ''} </p>
      </div>
    </div>
  )
}

export default ItemTxhBoughtMobile
