import * as types from './constants'

export const getListF1 = (payload, cbs) => ({
  type: types.GET_LIST_F1,
  payload,
  cbs,
})
export const getListF1Success = (payload) => ({
  type: types.GET_LIST_F1_SUCCESS,
  payload,
})

export const getInfoF1 = (payload) => ({
  type: types.GET_INFO_F1,
  payload,
})
export const getTotalReport = (payload) => ({
  type: types.GET_TOTAL_REPORT,
  payload,
})
export const getTotalReportSuccess = (payload) => ({
  type: types.GET_TOTAL_REPORT_SUCCESS,
  payload,
})

export const getInvestmentStatisticReport = (payload) => ({
  type: types.INVESTMENT_STATISTIC_REPORT,
  payload,
})

export const getInvestmentStatisticReportSuccess = (payload) => ({
  type: types.INVESTMENT_STATISTIC_REPORT_SUCCESS,
  payload,
})
