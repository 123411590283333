import { all, takeLatest, call, put } from 'redux-saga/effects'
import qs from 'query-string'
import { fetchHelper } from 'helpers'
import { ROOT_API_URL } from 'constants/index'
import * as types from './constants'
import * as actions from './actions'

function getListF1FromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/getListF1?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListF1({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getListF1FromApi, payload)
    if (data.code === 200) {
      yield put(actions.getListF1Success(data.data.listF1))
      if (cbs) cbs(data.data.listF1)
    } else if (cbe) cbe(data)
  } catch (error) {
    if (cbe) cbe(error)
    console.error('getListF1', error)
  }
}

function getTotalReportFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/report/total?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getTotalReport({ payload }) {
  try {
    const { data } = yield call(getTotalReportFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getTotalReportSuccess(data.data))
    }
  } catch (error) {
    console.error('getTotalReport', error)
  }
}

function getInvestmentStatisticReportFromApi(payload = {}) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/investmentStatistic/report?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getInvestmentStatisticReport({ payload }) {
  try {
    const { data } = yield call(getInvestmentStatisticReportFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getInvestmentStatisticReportSuccess(data.data))
    }
  } catch (error) {
    console.error('getInvestmentStatisticReport', error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.GET_LIST_F1, getListF1),
    takeLatest(types.GET_TOTAL_REPORT, getTotalReport),
    takeLatest(types.INVESTMENT_STATISTIC_REPORT, getInvestmentStatisticReport),
  ])
}
