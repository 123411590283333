import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { resendAuthenLoginAccountCode } from 'state/auth/actions'
import VerifyEmailCode from 'components/Auth/VerifyEmailCode'
import useWalletAuth from 'hooks/useAppAuth'

const SignInVerifyEmailCode = ({ type, initialField, token, onFinish, onBack }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')

  const { verifyCode } = useWalletAuth()

  const onSubmit = (code) => {
    if (!code || code.length < initialField) {
      setErrorMess(`Please enter ${initialField} characters from email of you.`)
    } else {
      setErrorMess('')
      setLoading(true)
      verifyCode(
        token,
        code,
        (receipt) => {
          onFinish({ code, type: 'is_email', ...receipt })
          setLoading(false)
        },
        () => {
          setLoading(false)
        },
      )
    }
  }

  const resendCode = (resetCountdownCallback) => {
    switch (type) {
      case 'login':
        executeRecaptcha((recaptcha) => {
          dispatch(
            resendAuthenLoginAccountCode(
              {
                token,
                recaptcha,
              },
              () => {
                if (resetCountdownCallback) resetCountdownCallback()
              },
            ),
          )
        })
        break
      default:
        break
    }
  }

  return (
    <VerifyEmailCode
      initialField={initialField}
      loading={loading}
      errorMess={errorMess}
      onResendCode={resendCode}
      onAutoFinish={onSubmit}
      onBack={onBack}
    />
  )
}

export default SignInVerifyEmailCode
