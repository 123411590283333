import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import ButtonPrimary from 'widgets/ButtonPrimary'
import InputCode from 'components/InputCode'
import ModalCustom from 'components/BaseModal'
import { verifyWithdraw } from 'state/wallets/actions'
import { toast } from 'react-toastify'
import ResendCode from 'components/ResendCode'
import { FormattedMessage } from 'react-intl'
import { verifyUpdateWalletAddress } from 'state/auth/actions'

export const MODAL_VERIFY_TYPE = {
  VERIFY_WITHDRAW: 0,
  VERIFY_CHANGE_WALLET_ADDRESS: 1,
}

const initialField = 6

const ModalVerificationCode = ({ visible, dataModal, modalType, onFinish, onCancel }) => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [emailCode, setEmailCode] = useState('')
  const [gg2faCode, setGg2faCode] = useState('')
  const [errorMess, setErrorMess] = useState(false)

  useEffect(
    () => () => {
      setStep(1)
      setLoading(false)
      setEmailCode('')
      setGg2faCode('')
      setErrorMess('')
    },
    [],
  )

  const handleVerifyWithdraw = () => {
    setLoading(true)
    executeRecaptcha((recaptcha) => {
      dispatch(
        verifyWithdraw(
          {
            TransactionCode: dataModal._id,
            email_code: emailCode,
            auth_code: gg2faCode,
            recaptcha,
          },
          (receipt) => {
            onFinish(receipt)
            setLoading(false)
            onCancel({ toggle: false, dataModal: null })
            setEmailCode('')
            setGg2faCode('')
            setStep(1)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    })
  }

  const handleVerifyChangeWalletAddress = () => {
    setLoading(true)
    executeRecaptcha((recaptcha) => {
      dispatch(
        verifyUpdateWalletAddress(
          {
            wallet_address: dataModal.address_wallet,
            code_email: emailCode,
            code_2fa: gg2faCode,
            recaptcha,
          },
          (receipt) => {
            onFinish(receipt)
            setLoading(false)
            onCancel({ toggle: false, dataModal: null })
            setEmailCode('')
            setGg2faCode('')
            setStep(1)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    })
  }

  const handleSubmit = () => {
    switch (modalType) {
      case MODAL_VERIFY_TYPE.VERIFY_WITHDRAW:
        handleVerifyWithdraw()
        break
      case MODAL_VERIFY_TYPE.VERIFY_CHANGE_WALLET_ADDRESS:
        handleVerifyChangeWalletAddress()
        break
      default:
        break
    }
  }

  const handleNext = () => {
    if (!dataModal) return toast.error('An occurred error, Please try again late.')
    if (step === 1 && emailCode.length < 6) toast.error(`Please enter ${initialField} characters from email of you.`)
    if (step === 2 && gg2faCode.length < 6) {
      toast.error(`Please enter ${initialField} characters from Google Authenticator app on mobile of you.`)
    }

    setErrorMess(false)
    switch (step) {
      case 1:
        if (emailCode?.length < initialField) return setErrorMess(true)
        setStep(2)
        break
      case 2:
        if (gg2faCode?.length < initialField) return setErrorMess(true)
        handleSubmit()
        break
      default:
        break
    }
  }

  return (
    <ModalCustom
      className="modal-verification-code"
      title={
        step === 1 ? (
          <div className="modal-title-wd">
            <FormattedMessage id="Verification Code" />
          </div>
        ) : (
          <div className="modal-title-wd">
            <FormattedMessage id="Google Authenticator" />
          </div>
        )
      }
      visible={Boolean(visible)}
      width={window.innerWidth >= 768 ? 562 : '100vw'}
      onCancel={() => {
        onCancel({ toggle: false, dataModal: null })
        setEmailCode('')
        setGg2faCode('')
      }}
    >
      <div className="list-steps-verify">
        <ul>
          <li className={step === 1 ? 'current' : 'active'}>
            <p>EMAIL OTP</p>
          </li>
          <li className={step === 2 ? 'current' : ''}>
            <p>GG2FA</p>
          </li>
        </ul>
      </div>

      <p className="modal-verification-code-note">
        {step === 1 ? 'Please check your email for OTP' : 'Please input your Google Authenticator Code'}
      </p>

      {step === 1 ? (
        <InputCode initialField={initialField} hasErrored={!!errorMess} value={emailCode} onChange={setEmailCode} />
      ) : (
        <InputCode initialField={initialField} hasErrored={!!errorMess} value={gg2faCode} onChange={setGg2faCode} />
      )}

      <ResendCode
        className="resend-code"
        style={{ visibility: '' }}
        data={dataModal}
        countDown={50}
        onPaste={(code) => {
          if (step === 1) {
            setEmailCode(code)
          } else {
            setGg2faCode(code)
          }
        }}
      />

      <div className="modal-scan-action">
        {step === 2 && (
          <ButtonPrimary
            style={{ maxWidth: window.innerWidth >= 768 ? '150px' : '120px', marginRight: '24px' }}
            onClick={() => {
              if (step === 1) {
                onCancel({ toggle: false, dataModal: null })
                setEmailCode('')
                setGg2faCode('')
              } else {
                setStep(1)
              }
            }}
          >
            <FormattedMessage id="Back" />
          </ButtonPrimary>
        )}
        <ButtonPrimary
          className="active"
          style={{ maxWidth: window.innerWidth >= 768 ? '150px' : '120px' }}
          loading={loading}
          onClick={handleNext}
        >
          <FormattedMessage id="Next" />
        </ButtonPrimary>
      </div>
    </ModalCustom>
  )
}
ModalVerificationCode.defaultProps = {
  visible: false,
  modalType: MODAL_VERIFY_TYPE.VERIFY_WITHDRAW,
  onFinish: () => null,
  onCancel: () => null,
}

export default ModalVerificationCode
