import React from 'react'
import QRCode from 'qrcode.react'
import { isMobile } from 'react-device-detect'
import './styles.scss'
import ModalCustom from 'components/BaseModal'
import QrReader from 'react-qr-reader'
import { FormattedMessage } from 'react-intl'

const ModalScan = ({ visible, value, type, setOpen, title, handleScan, handleError }) => {
  const renderContentQrReader = () => (
    <>
      <div className="modal-deposit-qs-code">
        <QrReader delay={300} showViewFinder onScan={handleScan} onError={handleError} />
      </div>
      <p>
        <FormattedMessage id="Powered by" /> <span className="powered-by">OPENLIVE</span>
      </p>
    </>
  )
  const renderContentQrCode = () => (
    <>
      <div className="modal-deposit-qs-code" style={{ maxWidth: '204px', maxHeight: '204px' }}>
        <QRCode size={200} value={value} includeMargin fgColor="#fff" bgColor="#08A7B7" />
      </div>
      <p>
        <FormattedMessage id="Powered by" /> <span className="powered-by">OPENLIVE</span>
      </p>
    </>
  )

  let modalProps = {}
  if (isMobile) {
    modalProps = { ...modalProps, width: 490, height: window.innerWidth >= 768 ? '' : 490 }
  }
  return (
    <ModalCustom
      title={title}
      className="modal-qrcode-deposit"
      visible={Boolean(visible)}
      style={{ bottom: 0 }}
      onCancel={() => setOpen(false)}
      {...modalProps}
    >
      {type === 'deposit' ? renderContentQrCode() : renderContentQrReader()}
    </ModalCustom>
  )
}

export default ModalScan
