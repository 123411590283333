import React from 'react'
import { useHistory } from 'react-router-dom'
import { BackLinkIcon } from 'widgets/Svg'
import './styles.scss'

const BackLink = ({ icon, showBackIcon, iconRight, label, to, ...props }) => {
  const history = useHistory()
  return (
    <div className="back-link" {...props}>
      <div
        className="back-title"
        role="presentation"
        onClick={() => {
          if (to) history.push(to)
        }}
        style={{ cursor: to ? 'pointer' : 'default' }}
      >
        {showBackIcon && <BackLinkIcon className="back-icon" />}
        {label}
      </div>
      <div className="back-link-right">{iconRight}</div>
    </div>
  )
}

BackLink.defaultProps = {
  showBackIcon: true,
  label: '',
}

export default BackLink
