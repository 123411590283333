import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import ModalCustom from 'components/BaseModal'
import { DECIMALS } from 'constants/index'
import { BASE_SCAN_URLS } from 'connectors'
import { formatCode, formatDate, formatAmount } from 'helpers'
import './styles.scss'
import TxStatus from 'components/TxStatus'
import TxAmount from 'components/TxAmount'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const ModalViewDetails = ({ visible, dataModal, close }) => {
  const history = useHistory()
  const { chainId } = useActiveWeb3React()

  if (!dataModal) return <></>
  const pathName = history.location.pathname.split('/')
  const pathNameTitle = () => {
    if (pathName.includes('deposit')) {
      return (
        <>
          <FormattedMessage id="Deposit Details" />
        </>
      )
    }
    if (pathName.includes('withdraw')) {
      return (
        <>
          <FormattedMessage id="Withdraw Details" />
        </>
      )
    }
    if (pathName.includes('history')) {
      return (
        <>
          <FormattedMessage id="Transaction Details" />
        </>
      )
    }
  }
  return (
    <ModalCustom
      className="modal-history-details"
      title={<div className="history-detail-heading">{pathNameTitle()}</div>}
      visible={Boolean(visible)}
      width={window.innerWidth <= 768 ? '100vw' : 700}
      onCancel={() => close(false)}
    >
      <div className="modal-scan-action-bg" />

      <ul className="modal-history-details-steps">
        <li>
          <p>
            <FormattedMessage id="Deposit order submitted" />
          </p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </li>
        <li>
          <p>
            <FormattedMessage id="System processing" />
          </p>
          <p>{dataModal?.status === 'COMPLETED' ? formatDate(dataModal.updatedAt * 1000) : 'Processing...'}</p>
        </li>
        <li>
          <p>Completed</p>
          <p>{dataModal?.status === 'COMPLETED' ? formatDate(dataModal.updatedAt * 1000) : 'Processing...'}</p>
        </li>
      </ul>

      <div className="modal-history-details-note">
        <p>
          <FormattedMessage id="Please note that you will receive a email once it is complete." />
        </p>
        <p>
          <FormattedMessage id="Why hasn’t my history arrived?" />
        </p>
      </div>

      <div className="modal-history-details-info">
        <div className="modal-history-details-info-item info-item-time">
          <p>
            <FormattedMessage id="Time" />
          </p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </div>

        <div className="modal-history-details-info-item info-item-action">
          <p>
            <FormattedMessage id="Actions" />
          </p>
          <p>{dataModal.action}</p>
        </div>

        <div className="modal-history-details-info-item info-item-network">
          <p>
            <FormattedMessage id="Network" />
          </p>
          <p>{dataModal.chain}</p>
        </div>

        <div className="modal-history-details-info-item info-item-currency">
          <p>
            <FormattedMessage id="Currency" />
          </p>
          <p>{dataModal.currency}</p>
        </div>

        <div className="modal-history-details-info-item info-item-fee">
          <p>
            <FormattedMessage id="Fee" />
          </p>
          <p>{dataModal.fee / 10 ** DECIMALS}</p>
        </div>
        <div className="modal-history-details-info-item info-item-amount">
          <p>
            <FormattedMessage id="Amount" />
          </p>
          <TxAmount action={dataModal.action} title={formatAmount(dataModal.amount, { decimals: DECIMALS })} />
        </div>
        <div className="modal-history-details-info-item info-item-status">
          <p>
            <FormattedMessage id="Status" />
          </p>
          <TxStatus status={dataModal.status} title={dataModal.status} />
        </div>
        <div className="modal-history-details-info-item info-item-txh">
          <p>TxHash</p>
          {dataModal.txhash.length < 60 ? (
            <p className="info-item-txhash">{formatCode(dataModal.txhash, 6, 10)}</p>
          ) : (
            <a
              className="info-item-txhash link"
              href={`${BASE_SCAN_URLS[chainId]}/tx/${dataModal.txhash}`}
              target="_blank"
              rel="noreferrer"
            >
              {formatCode(dataModal.txhash, 6, 10)}
            </a>
          )}
        </div>
      </div>
    </ModalCustom>
  )
}

export default ModalViewDetails
