const { DEFAULT_ACTIVE_CHAIN, CACHE_CHAIN_ID, CHAIN_LIST } = require('connectors')

function getLocaleChainId() {
  // force cache chain id from localStorage
  let initForceCacheChainId = DEFAULT_ACTIVE_CHAIN
  if (typeof localStorage === 'object') {
    initForceCacheChainId = localStorage?.getItem(CACHE_CHAIN_ID)
    const checkIsAvailableChain = parseInt(initForceCacheChainId) in CHAIN_LIST
    if (!checkIsAvailableChain) {
      initForceCacheChainId = undefined
    }
  }
  if (!initForceCacheChainId) {
    initForceCacheChainId = Object.entries(CHAIN_LIST)[0][1].chainId
  }
  return initForceCacheChainId
}
export default getLocaleChainId
