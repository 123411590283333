import { all, takeLatest, call, put } from 'redux-saga/effects'
import qs from 'query-string'
import { fetchHelper } from 'helpers'
import { ROOT_API_URL } from 'constants/index'
import * as types from './constants'
import * as actions from './actions'

/**
 * Get package list
 */
function getPackageListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/investPackage/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getPackageList({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getPackageListFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    }
  } catch (error) {
    console.error('getPackageList', error)
    if (cbe) cbe(error)
  }
}

function getInvestmentListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/investment/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}

function* getInvestmentList({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getInvestmentListFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    }
  } catch (error) {
    console.error('InvestmentList', error)
    if (cbe) cbe(error)
  }
}

/**
 * report package
 */
function orderinvestmentReportFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/investmentStatistic/report`, {
      method: 'GET',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* orderInvestmentReport({ payload }) {
  try {
    const { data } = yield call(orderinvestmentReportFromApi, payload)

    if (data.code === 200) {
      yield put(actions.orderInvestmentReportSuccess(data.data))
    }
  } catch (error) {
    console.error('orderInvestmentReport', error)
  }
}
function getLevelCustomerFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/level/list`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getLevelCustomer({ payload, cbe }) {
  try {
    const { data } = yield call(getLevelCustomerFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getLevelCustomerSuccess(data.data))
    }
  } catch (error) {
    console.error('getLevelCustomer', error)
    if (cbe) cbe(error)
  }
}
function getReportTotalFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/report/total`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getReportTotal({ payload, cbe }) {
  try {
    const { data } = yield call(getReportTotalFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getReportTotalSuccess(data.data))
    }
  } catch (error) {
    console.error('getReportTotal', error)
    if (cbe) cbe(error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.GET_PACKAGE_LIST, getPackageList),
    takeLatest(types.GET_INVESTMENT_LIST, getInvestmentList),
    takeLatest(types.GET_ORDER_INVESTMENT_REPORT, orderInvestmentReport),
    takeLatest(types.GET_LEVEL_CUSTOMER, getLevelCustomer),
    takeLatest(types.REPORT_TOTAL, getReportTotal),
  ])
}
