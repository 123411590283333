import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.59375 15.0625C10.6724 15.0625 11.5469 14.1881 11.5469 13.1094C11.5469 12.0307 10.6724 11.1562 9.59375 11.1562C8.51507 11.1562 7.64062 12.0307 7.64062 13.1094C7.64062 14.1881 8.51507 15.0625 9.59375 15.0625Z"
      stroke="#D0D0D0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.59375 15.0625V17.4062" stroke="#D0D0D0" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17.4062 8.03125H1.78125C1.34978 8.03125 1 8.38103 1 8.8125V19.75C1 20.1815 1.34978 20.5312 1.78125 20.5312H17.4062C17.8377 20.5312 18.1875 20.1815 18.1875 19.75V8.8125C18.1875 8.38103 17.8377 8.03125 17.4062 8.03125Z"
      stroke="#D0D0D0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.07812 8.03125V4.51562C6.07813 3.58322 6.44852 2.68901 7.10783 2.0297C7.76714 1.3704 8.66135 1 9.59375 1C10.5262 1 11.4204 1.3704 12.0797 2.0297C12.739 2.68901 13.1094 3.58322 13.1094 4.51562V8.03125"
      stroke="#D0D0D0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Icon
