/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Space } from 'antd'
import './styles.scss'
import BackLink from 'components/BackLink'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import RecentTransaction from 'components/History/RecentTransaction'
import { getCurrency, resendVerifyWithdrawCode } from 'state/wallets/actions'
import { getListStatus, getTransactionList } from 'state/transactions/actions'
import ControlInputText from 'components/ControlInputText'
import ControlInputSelect from 'components/ControlInputSelect'
import { debounce } from 'lodash'
import { removeEmpty } from 'helpers'
import { FormattedMessage, injectIntl } from 'react-intl'
import { BackLinkIcon } from 'widgets/Svg'
import RangePicker from 'widgets/RangePicker'
import ModalVerificationCode from 'components/ModalVerificationCode'

const WalletHistory = ({ intl }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { chainList, currency } = useSelector((state) => state.wallets)
  const { listStatus } = useSelector((state) => state.transactions)
  const { transactionsList } = useSelector((state) => state.transactions)
  const [modalVerifyCode, setModalVerifyCode] = useState({ toggle: false, dataModal: null })
  const optionCurrency = useMemo(() => {
    if (currency) {
      return currency.map((item) => ({
        label: item.title,
        value: item.code,
        currency_code: item.code,
        image: item.icon,
      }))
    }
    return undefined
  }, [currency])
  const optionChain = useMemo(() => {
    if (chainList) {
      return chainList.map((item) => ({
        label: item.code,
        code_chain: item.code,
        value: item.chainid,
      }))
    }
    return undefined
  }, [chainList])

  const optionActions = useMemo(() => {
    if (listStatus?.actionTransaction) {
      return Object.keys(listStatus?.actionTransaction)
        .map((i) => ({
          label: i,
          value: i,
        }))
        .filter(
          (item) =>
            ![
              'RANK_REWARD',
              'PREMIUM_BONUS',
              'INTEREST',
              'INVESTMENT_DAILY_REWARD',
              'FEE',
              'BONUS_FIRST_DEPOSIT',
              'BONUS_ACTIVE',
              'BONUS_ACTIVE_24H',
              'BINARY_COMMISSION',
            ].includes(item.value),
        )
    }
    return undefined
  }, [listStatus])

  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    txhash: '',
    type: '',
    from_date: '',
    to_date: '',
    chain_code: '',
    currency: '',
    status_url: false,
  })

  useEffect(() => {
    const parseSearch = Object.fromEntries(new URLSearchParams(location.search).entries())
    setTxParams((prev) => ({
      ...prev,
      ...parseSearch,
      status_url: true,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (txParams.status_url) {
      dispatch(getTransactionList(removeEmpty(txParams)))
    }
  }, [dispatch, txParams])
  // Search Transaction
  const handleSearchTransaction = debounce((value) => {
    setTxParams((prev) => ({
      ...prev,
      page: 1,
      pageSize: 10,
      txhash: value,
    }))
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    queryParams.set('pageSize', 10)
    queryParams.set('txhash', value || '')
    history.replace(`?${queryParams.toString()}`)
  }, 500)

  const handleSetSelectCurrency = (value) => {
    setTxParams((prev) => ({
      ...prev,
      page: 1,
      currency: value ? JSON.parse(value)?.currency_code : '',
    }))
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    queryParams.set('pageSize', 10)
    if (value) {
      queryParams.set('currency', JSON.parse(value)?.currency_code)
    } else {
      queryParams.delete('currency')
    }
    history.replace(`?${queryParams.toString()}`)
  }

  const handleSelectAction = (value) => {
    setTxParams((prev) => ({
      ...prev,
      page: 1,
      type: value ? JSON.parse(value)?.value : '',
    }))
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    queryParams.set('pageSize', 10)
    if (value) {
      queryParams.set('action', JSON.parse(value)?.value)
    } else {
      queryParams.delete('action')
    }
    history.replace(`?${queryParams.toString()}`)
  }

  const handleSelectChain = (value) => {
    setTxParams((prev) => ({
      ...prev,
      page: 1,
      chain_code: value ? JSON.parse(value)?.code_chain : '',
    }))
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    queryParams.set('pageSize', 10)
    if (value) {
      queryParams.set('chain_code', JSON.parse(value)?.code_chain)
    } else {
      queryParams.delete('chain_code')
    }
    history.replace(`?${queryParams.toString()}`)
  }

  useEffect(() => {
    dispatch(getCurrency())
  }, [dispatch])

  useEffect(() => {
    dispatch(getListStatus())
  }, [dispatch])

  const handleReVerifyWithdraw = useCallback(
    (item) => {
      executeRecaptcha((recaptcha) => {
        dispatch(
          resendVerifyWithdrawCode({
            transaction_id: item._id,
            recaptcha,
          }),
        )
      })
      setModalVerifyCode({ toggle: true, dataModal: item })
    },
    [dispatch],
  )

  // Filter date
  const onChange = (dates, dateStrings) => {
    if (dates) {
      setTxParams((prev) => ({
        ...prev,
        from_date: new Date(dateStrings[0]).getTime() / 1000 ? new Date(dateStrings[0]).getTime() / 1000 : '',
        to_date: new Date(dateStrings[1]).getTime() / 1000 ? new Date(dateStrings[1]).getTime() / 1000 : '',
        status_url: true,
      }))
    } else {
      setTxParams((prev) => ({
        ...prev,
        from_date: '',
        to_date: '',
        status_url: true,
      }))
    }
  }

  /**
   * Verify code success withdraw
   */
  const handleFinishWithdraw = useCallback(() => {
    dispatch(getTransactionList(removeEmpty(txParams)))
  }, [dispatch, txParams])

  return (
    <div className="container warp-wallet-history-form">
      <BackLink label={<FormattedMessage id="History" />} icon={<BackLinkIcon />} to="/wallet" />

      <div className="wallet-history-form">
        <Row
          className="wallet-history-form-filter"
          gutter={[
            { xs: 12, sm: 16, md: 24 },
            { xs: 12, sm: 16, md: 24 },
          ]}
        >
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <div className="wallet-box-history-title">
              <p>
                <FormattedMessage id="Transaction Txh" />
              </p>
              <ControlInputText
                placeholder={intl.formatMessage({ id: 'Search Txh' })}
                onChange={(value) => handleSearchTransaction(value)}
              />
            </div>
          </Col>
          <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 5 }}>
            <div className="wallet-box-history-title">
              <ControlInputSelect
                title={<FormattedMessage id="Network" />}
                dataSelect={optionChain}
                defaultValue="All"
                onChange={(value) => handleSelectChain(value)}
              />
            </div>
          </Col>
          <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 5 }}>
            <div className="wallet-box-history-title">
              <ControlInputSelect
                title={<FormattedMessage id="Currency" />}
                dataSelect={optionCurrency}
                defaultValue="All"
                onChange={(value) => handleSetSelectCurrency(value)}
              />
            </div>
          </Col>
          <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 5 }}>
            <div className="wallet-box-history-title">
              <ControlInputSelect
                title={<FormattedMessage id="Actions" />}
                dataSelect={optionActions}
                defaultValue="All"
                onChange={(value) => handleSelectAction(value)}
              />
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9 }}>
            <div className="wallet-box-history-title">
              <p>
                <FormattedMessage id="Time" />
              </p>
              <Space direction="vertical" style={{ width: '100%' }}>
                <RangePicker onChange={onChange} placeholder={['1 Jan 2021', '3 May 2022']} suffixIcon format="YYYY/MM/DD" />
              </Space>
            </div>
          </Col>
        </Row>
        <RecentTransaction
          txParams={txParams}
          transactionsList={transactionsList}
          setTxParams={setTxParams}
          setModalVerifyCode={handleReVerifyWithdraw}
        />
      </div>

      <ModalVerificationCode
        visible={modalVerifyCode.toggle}
        dataModal={modalVerifyCode.dataModal}
        onCancel={setModalVerifyCode}
        onFinish={handleFinishWithdraw}
      />
    </div>
  )
}

export default injectIntl(WalletHistory)
