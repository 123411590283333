import React from 'react'
import './style.scss'
import { FormattedMessage } from 'react-intl'
import CurrencyFormat from 'react-currency-format'
import { roundNumber } from 'helpers/index'

const ItemMemberMobile = ({ item }) => (
  <div className="table-static-member-item-mobile">
    <div>
      <p>
        <FormattedMessage id="Type Member" />
      </p>
      <p>{`${item?.floor === 1 ? 'Diamond' : (item?.floor === 2 && 'Platinum') || (item?.floor === 3 && 'Gold')} `}</p>
    </div>
    <div>
      <p>
        <FormattedMessage id="Total Member" />
      </p>
      <p>{item?.total_member}</p>
    </div>
    <div>
      <p>
        <FormattedMessage id="Total Volume" />
      </p>
      <p>
        <CurrencyFormat value={roundNumber(item?.total_volume / 1e18, 2)} displayType="text" thousandSeparator prefix="$" />
      </p>
    </div>
    <div>
      <p>
        <FormattedMessage id="Total Commission" />
      </p>
      <p>
        <CurrencyFormat value={roundNumber(item?.total_commission / 1e18, 2)} displayType="text" thousandSeparator prefix="$" />
      </p>
    </div>
  </div>
)

export default ItemMemberMobile
