import { useEffect } from 'react'
import { G_SITE_KEY } from 'constants/index'

export function executeRecaptcha(CBSuccess, CBError) {
  // CBSuccess()
  try {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(G_SITE_KEY, { action: 'submit' })
        .then((tokenCaptcha) => {
          if (CBSuccess) CBSuccess(tokenCaptcha)
        })
        .catch((error) => {
          // toast.error('Execute google recaptcha error. Please try again late.')
          if (CBError) CBError(error)
        })
    })
  } catch (error) {
    if (CBError) CBError(error)
  }
}

/**
 * @Googlerecapcha
 */
export function useConfigRecaptcha() {
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = () => {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${G_SITE_KEY}`, () => {
      console.log('recaptcha config success!')
    })
  }, [])
}
