import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { CACHE_CHAIN_ID } from 'connectors'
import getLocaleChainId from 'utils/getLocaleChainIId'

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = () => {
  const { library, chainId, ...web3React } = useWeb3React()
  const localeChainId = getLocaleChainId()
  const [cacheChainId, setCacheChainId] = useState(chainId ?? parseInt(localeChainId))

  // here fetch chain from localestorage or default item 0 of chain list
  useEffect(() => {
    if (chainId) {
      localStorage.setItem(CACHE_CHAIN_ID, chainId.toString())
      setCacheChainId(chainId)
    }
  }, [chainId])

  return {
    library: library ?? undefined,
    chainId: cacheChainId,
    ...web3React,
  }
}

export default useActiveWeb3React
