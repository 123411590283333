import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { requestForgotPassword } from 'state/auth/actions'
import VerifyEmailCode from 'components/Auth/VerifyEmailCode'

const VerifyForgotPass = ({ initialField, email, onSubmit, onBack }) => {
  const dispatch = useDispatch()
  const [errorMess, setErrorMess] = useState('')

  const onFinish = (code) => {
    if (!code || code.length < initialField) {
      setErrorMess(`Please enter ${initialField} characters from email of you.`)
    } else {
      setErrorMess('')
      onSubmit(code)
    }
  }

  const resendCode = (resetCountdownCallback) => {
    executeRecaptcha((recaptcha) => {
      dispatch(
        requestForgotPassword(
          {
            email: `${email}`,
            recaptcha,
          },
          () => {
            // reset countdown callback
            if (resetCountdownCallback) resetCountdownCallback()
          },
        ),
      )
    })
  }

  return (
    <VerifyEmailCode
      initialField={initialField}
      buttonLabel="Next"
      errorMess={errorMess}
      onResendCode={resendCode}
      onFinish={onFinish}
      onAutoFinish={() => setErrorMess('')}
      onBack={onBack}
    />
  )
}

export default VerifyForgotPass
