import './styles.scss'
import React from 'react'
import Form from './component/form'

const AccountActivity = () => (
  <>
    <div className="container warp-page-account-activity">
      <Form />
    </div>
  </>
)

export default AccountActivity
