import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 30 26" {...props}>
    <path d="M28.125 11.1442H5.8875L12.6938 3.04777C13.012 2.66854 13.1651 2.17964 13.1194 1.6886C13.0737 1.19757 12.8329 0.744631 12.45 0.42943C12.0671 0.114229 11.5734 -0.0374148 11.0776 0.0078578C10.5818 0.0531304 10.1245 0.291612 9.80625 0.670837L0.43125 11.8127C0.368176 11.9013 0.311774 11.9944 0.2625 12.0912C0.2625 12.1841 0.2625 12.2398 0.13125 12.3327C0.0462631 12.5456 0.0017646 12.7722 0 13.0012C0.0017646 13.2301 0.0462631 13.4568 0.13125 13.6697C0.13125 13.7625 0.13125 13.8182 0.2625 13.9111C0.311774 14.0079 0.368176 14.101 0.43125 14.1896L9.80625 25.3315C9.98254 25.5411 10.2033 25.7097 10.4528 25.8252C10.7024 25.9408 10.9745 26.0004 11.25 26C11.6881 26.0008 12.1127 25.8497 12.45 25.5729C12.6399 25.417 12.7968 25.2255 12.9118 25.0095C13.0269 24.7934 13.0977 24.557 13.1204 24.3138C13.143 24.0706 13.117 23.8253 13.0438 23.5921C12.9706 23.3589 12.8516 23.1422 12.6938 22.9546L5.8875 14.8581H28.125C28.6223 14.8581 29.0992 14.6625 29.4508 14.3142C29.8025 13.966 30 13.4937 30 13.0012C30 12.5087 29.8025 12.0363 29.4508 11.6881C29.0992 11.3398 28.6223 11.1442 28.125 11.1442Z" />
  </Svg>
)

export default Icon
