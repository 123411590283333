import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import VerifyEmailCode from 'components/Auth/VerifyEmailCode'
import { verifyAuthenActiveAccountCode, resendAuthenActiveAccountCode } from 'state/auth/actions'
import { FormattedMessage } from 'react-intl'

const VerifyActiveAccountCode = ({ initialField, email }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')

  const onSubmit = (code) => {
    if (!code || code.length < initialField) {
      setErrorMess(
        <>
          <FormattedMessage id="Please enter" /> {initialField} <FormattedMessage id="characters from email of you." />
        </>,
      )
    } else {
      setErrorMess('')
      setLoading(true)
      executeRecaptcha((recaptcha) => {
        dispatch(
          verifyAuthenActiveAccountCode(
            {
              email: `${email}`,
              code,
              recaptcha,
            },
            () => {
              history.push('/login')
              setLoading(false)
            },
            () => {
              setLoading(false)
            },
          ),
        )
      })
    }
  }

  const resendCode = (resetCountdownCallback) => {
    executeRecaptcha((recaptcha) => {
      dispatch(
        resendAuthenActiveAccountCode(
          {
            email: `${email}`,
            recaptcha,
          },
          () => {
            // reset countdown callback
            if (resetCountdownCallback) resetCountdownCallback()
          },
        ),
      )
    })
  }

  return (
    <VerifyEmailCode
      initialField={initialField}
      loading={loading}
      errorMess={errorMess}
      onResendCode={resendCode}
      onFinish={onSubmit}
    />
  )
}

export default VerifyActiveAccountCode
