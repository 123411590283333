import React from 'react'
import './styles.scss'
import { formatDate, isNumber, roundNumber } from 'helpers'
import { FormattedMessage } from 'react-intl'
import CurrencyFormat from 'react-currency-format'
import TxStatus from 'components/TxStatus'

const ItemPacKageTypeMobile = ({ data, ...props }) => (
  <>
    <div className="item-package-type-wrapper" {...props}>
      <div>
        <p>
          <FormattedMessage id="Package" />
        </p>
        <p>{data?.package?.title}</p>
      </div>
      <div>
        <p>
          <FormattedMessage id="Value (USD)" />
        </p>
        <p>
          {isNumber(data?.price_invest) ? (
            <CurrencyFormat value={roundNumber(data?.price_usd_invest) / 1e18} suffix="$" displayType="text" thousandSeparator />
          ) : (
            '--'
          )}
        </p>
      </div>
      <div>
        <p>
          <FormattedMessage id="Status" />
        </p>
        <TxStatus status="COMPLETED" title="COMPLETED" />
      </div>
      <div>
        <p>
          <FormattedMessage id="Time" />
        </p>
        <p>{formatDate(data?.createdAt * 1000, 'YYYY/MM/DD')}</p>
      </div>
    </div>
  </>
)
export default ItemPacKageTypeMobile
