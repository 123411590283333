import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M25.25 9.30375V4.75875C25.25 2.97 24.53 2.25 22.7413 2.25H18.1962C16.4075 2.25 15.6875 2.97 15.6875 4.75875V9.30375C15.6875 11.0925 16.4075 11.8125 18.1962 11.8125H22.7413C24.53 11.8125 25.25 11.0925 25.25 9.30375Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3125 9.585V4.4775C12.3125 2.89125 11.5925 2.25 9.80375 2.25H5.25875C3.47 2.25 2.75 2.89125 2.75 4.4775V9.57375C2.75 11.1713 3.47 11.8012 5.25875 11.8012H9.80375C11.5925 11.8125 12.3125 11.1713 12.3125 9.585Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3125 22.2413V17.6962C12.3125 15.9075 11.5925 15.1875 9.80375 15.1875H5.25875C3.47 15.1875 2.75 15.9075 2.75 17.6962V22.2413C2.75 24.03 3.47 24.75 5.25875 24.75H9.80375C11.5925 24.75 12.3125 24.03 12.3125 22.2413Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17.375 17.4375H24.125" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M17.375 21.9375H24.125" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Icon
