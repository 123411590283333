import './styles.scss'
import React from 'react'
import { Select } from 'antd'

const InputSelect = ({ className, value, options, ...props }) => (
  <Select
    className={`w-select-item ${className}`}
    value={value || null}
    optionFilterProp="children"
    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
    getPopupContainer={(trigger) => trigger.parentNode}
    {...props}
  >
    {options.map((item) => (
      <Select.Option key={item.value} value={item.value}>
        {item.label}
      </Select.Option>
    ))}
  </Select>
)
InputSelect.defaultProps = {
  className: '',
  options: [],
}
export default InputSelect
