import React from 'react'
import './styles.scss'
import { formatDate, isNumber, roundNumber } from 'helpers/index'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import TxAmount from 'components/TxAmount'
import CurrencyFormat from 'react-currency-format'

const ItemTransaction = ({ NoIndex, data, index, setViewDetails, setModalVerifyCode, ...props }) => {
  const { currency } = useSelector((state) => state.wallets)
  const CurrencyType = currency?.find((item) => item.code === data?.currency)

  return (
    <div className="recent-transaction-dashboard" {...props}>
      <div className="no">
        <p>
          <FormattedMessage id="No." />
        </p>
        <p>
          <span>{index + 1}</span>
        </p>
      </div>
      <div>
        <p>
          <FormattedMessage id="Amount" />
        </p>
        <p>
          {isNumber(+data.amount) ? (
            <TxAmount
              action={data.action}
              title={
                <CurrencyFormat
                  value={roundNumber(data.amount, { decimals: 18 })}
                  displayType="text"
                  thousandSeparator
                  renderText={(t) => t}
                />
              }
            />
          ) : (
            '--'
          )}
        </p>
      </div>
      <div className="type">
        <p />
        <p>
          <span>
            <img className="img-curr-type" src={CurrencyType?.icon} alt="" />
            {data.currency}
          </span>
        </p>
      </div>
      <div className="action">
        <p>
          <FormattedMessage id="Action" />
        </p>
        <p>{data.action}</p>
      </div>
      <div>
        <p>
          <FormattedMessage id="Time" />
        </p>
        <p>{formatDate(data.createdAt * 1000)}</p>
      </div>
    </div>
  )
}

export default ItemTransaction
