import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import ModalCustom from 'components/BaseModal'
import { toggleModalSoon } from 'state/app/actions'

const ModalSoon = () => {
  const dispatch = useDispatch()
  const { toggle } = useSelector((state) => state.app.modalSoon)

  return (
    <ModalCustom className="modal-soon" visible={toggle} onCancel={() => dispatch(toggleModalSoon({ toggle: false }))}>
      <h2>Coming Soon</h2>
      <p>We will be new site very soon!</p>
    </ModalCustom>
  )
}

export default ModalSoon
