import * as types from './constants'

export const getInvestPackageList = (payload, cbs, cbe) => ({
  type: types.GET_PACKAGE_LIST,
  payload,
  cbs,
  cbe,
})
export const getInvestmentList = (payload, cbs, cbe) => ({
  type: types.GET_INVESTMENT_LIST,
  payload,
  cbs,
  cbe,
})

export const orderInvestmentReport = (payload) => ({
  type: types.GET_ORDER_INVESTMENT_REPORT,
  payload,
})
export const orderInvestmentReportSuccess = (payload) => ({
  type: types.GET_ORDER_INVESTMENT_REPORT_SUCCESS,
  payload,
})

export const getCustomePackageDetail = (payload) => ({
  type: types.GET_CUSTOMER_PACKAGE_DETAIL,
  payload,
})
export const getCustomePackageDetailSuccess = (payload) => ({
  type: types.GET_CUSTOMER_PACKAGE_DETAIL_SUCCESS,
  payload,
})

export const getLevelCustomer = (payload) => ({
  type: types.GET_LEVEL_CUSTOMER,
  payload,
})
export const getLevelCustomerSuccess = (payload) => ({
  type: types.GET_LEVEL_CUSTOMER_SUCCESS,
  payload,
})

export const getReportTotal = (payload) => ({
  type: types.REPORT_TOTAL,
  payload,
})
export const getReportTotalSuccess = (payload) => ({
  type: types.REPORT_TOTAL_SUCCESS,
  payload,
})
