import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { isMobile } from 'react-device-detect'
import { Switch } from 'antd'
import './style.scss'
import SearchInput from 'widgets/SearchInput'
import WalletListDesktop from './WalletListDesktop'
import WalletListMobile from './WalletListMobile'

const WalletsList = ({ intl, currency }) => {
  const [smallerThanOne, setSmallerThanOne] = useState(false)
  const [currencySorting, setCurrencySorting] = useState(currency)
  const [searchCode, setSearchCode] = useState('')
  const [search, setSearch] = useState(false)

  useEffect(() => {
    if (smallerThanOne) {
      setCurrencySorting(currency.filter((item) => item.usdValue >= 1) || [])
    } else {
      setCurrencySorting(currency)
    }
  }, [currency, smallerThanOne])

  const onChange = (checked) => {
    setSmallerThanOne(checked)
  }
  const handleSearchCode = (value) => {
    setSearchCode(value.toUpperCase())
  }

  return (
    <div className="wallet-list-container">
      <div className="navigation-header">
        <div className="navigation-left">
          <h2>
            <FormattedMessage id="Your Asset" />
          </h2>
          <div className="right-switch">
            <p style={{ whiteSpace: 'nowrap' }}>
              <FormattedMessage id="Hide small balance" />
            </p>

            <div>
              <Switch onChange={onChange} />
              {window.innerWidth < 991 && (
                <img
                  role="presentation"
                  onClick={() => setSearch((prev) => !prev)}
                  style={{ marginLeft: '8px' }}
                  src="/images/icons/btn-search.png"
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
        <div className="navigation-right">
          {search && window.innerWidth < 991 && (
            <SearchInput
              className="navigation-right-search"
              placeholder={intl.formatMessage({ id: 'Search currency' })}
              value={searchCode}
              onChange={(value) => handleSearchCode(value.target.value)}
            />
          )}

          {window.innerWidth > 991 && (
            <SearchInput
              className="navigation-right-search"
              placeholder={intl.formatMessage({ id: 'Search currency' })}
              value={searchCode}
              onChange={(value) => handleSearchCode(value.target.value)}
            />
          )}
        </div>
      </div>

      {isMobile ? (
        <WalletListMobile walletList={currencySorting} searchCode={searchCode} />
      ) : (
        <WalletListDesktop walletList={currencySorting} searchCode={searchCode} />
      )}
    </div>
  )
}
export default injectIntl(WalletsList)
