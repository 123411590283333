import './styles.scss'
import React from 'react'
import { Input, Select } from 'antd'

const { Option } = Select

const SelectWithImage = ({ showAll, label, dataSelect, disabled, title, defaultValue, style, ...props }) => (
  <div className="box-form-control" style={style}>
    {title && <p>{title}</p>}
    <Input.Group compact>
      <Select defaultValue={defaultValue} disabled={disabled} dropdownClassName="select-with-image-dropdown" {...props}>
        {defaultValue === 'All' && <Option value="">All</Option>}
        {defaultValue === 'Select Name' && <Option value="">All</Option>}
        {dataSelect &&
          dataSelect
            ?.sort((a, b) => a?.label.localeCompare(b?.label))
            ?.map((item, index) => (
              <Option value={JSON.stringify(item)} key={index}>
                {item.image && (
                  <span>
                    <img src={item.image} alt={item.label} />
                  </span>
                )}
                {item.label}
              </Option>
            ))}
      </Select>
    </Input.Group>
  </div>
)
SelectWithImage.defaultProps = {
  showAll: '',
}
export default SelectWithImage
