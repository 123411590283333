import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import useWalletAuth from 'hooks/useWalletAuth'
import { connectorsList } from 'connectors'
import ModalCustom from 'components/BaseModal'
import getPreferredConfig from 'utils/getPreferredConfig'
import WalletCard from 'components/CardSignMessage/WalletCard'
import { toggleModalConnect } from 'state/wallets/actions'

const ModalConnect = ({ displayCount = 3 }) => {
  const dispatch = useDispatch()
  const { visible } = useSelector((state) => state.wallets.modalConnect)

  const [showMore] = useState(false)

  const { login } = useWalletAuth()

  const handleCloseModal = () => {
    dispatch(toggleModalConnect({ visible: false }))
  }

  const handleConnectWallet = (connectorId) => {
    login(connectorId)
    handleCloseModal()
  }

  const sortedConfig = getPreferredConfig(connectorsList)
  // Filter out WalletConnect if user is inside TrustWallet built-in browser
  const walletsToShow =
    window.ethereum?.isTrust && !window?.ethereum?.isSafePal
      ? sortedConfig.filter((wallet) => wallet.title !== 'WalletConnect')
      : sortedConfig
  const displayListConfig = showMore ? walletsToShow : walletsToShow.slice(0, displayCount)
  return (
    <ModalCustom
      title="Connect wallet"
      className="modal-connect"
      visible={visible}
      style={{ bottom: 0 }}
      onCancel={handleCloseModal}
    >
      <div className="wallet-list">
        {displayListConfig.map((wallet) => (
          <WalletCard key={wallet?.title} walletConfig={wallet} login={handleConnectWallet} />
        ))}
      </div>
    </ModalCustom>
  )
}

export default ModalConnect
