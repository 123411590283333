import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { linksPublic } from 'components/Menu/config'
import FooterAuth from '../FooterAuth'
import './styles.scss'
import WalletLogin from 'components/WalletLogin'

const AuthWrapper = ({ children }) => {
  const history = useHistory()
  const [openForm] = useState(true)
  const location = useLocation()

  const pathName = history.location.pathname.split('/')
  const pathNameLogin = pathName?.includes('login')
  const pathNameRegister = pathName?.includes('register')
  const pathNameForgot = pathName?.includes('forgot-password')

  useEffect(() => {
    document.getElementById('auth-container').scrollTo(0, 0)
  }, [pathName])

  return (
    <div className="auth-container" id="auth-container">
      <div className={window.innerWidth > 768 ? 'light-desktop' : 'light-mobile'} />
      <div className={window.innerWidth > 768 ? 'light-desktop' : 'light-mobile'} />

      <FooterAuth links={linksPublic} location={location} />

      <div className="auth-content-left">
        <div className="auth-content-img">
          <WalletLogin
            pathNameRegister={pathNameRegister}
            openForm={openForm}
            pathNameForgot={pathNameForgot}
            pathNameLogin={pathNameLogin}
            history={history}
          />
        </div>
      </div>

      <div className="auth-content-right">
        <div className="auth-body-right" style={{ display: openForm && 'block' }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthWrapper
