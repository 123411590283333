import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { verifyMessage } from 'state/auth/actions'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import CardSignMessage from 'components/CardSignMessage'
import WrapperSignOption from 'modules/auth/components/WrapperSignOption'
import { setAuthToken } from 'helpers'
import { getProfile } from 'state/user/actions'

const SignWallet = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { account } = useActiveWeb3React()

  const requestSignInMessage = ({ message, signature }) => {
    if (!account) return
    executeRecaptcha((recaptcha) => {
      dispatch(
        verifyMessage(
          {
            message,
            signature,
            address: account,
            os: window.navigator.platform,
            device_id: window.navigator.appVersion,
            recaptcha,
          },
          (receipt) => {
            const authToken = setAuthToken(receipt?.token)
            if (authToken) {
              dispatch(
                getProfile({}, () => {
                  if (get(history.location, 'state.from')) {
                    const pathName = get(history.location, 'state.from.pathname')
                    const search = get(history.location, 'state.from.search')
                    history.push(`${pathName}${search}`)
                  } else {
                    history.push('/dashboard')
                  }
                }),
              )
            }
          },
          (error) => {
            if (error?.code === 'err_email_active_required') {
              history.push('/register', { view: 1, email: error?.data?.email })
            }
          },
        ),
      )
    })
  }

  return (
    <WrapperSignOption>
      <CardSignMessage account={account} onFinish={requestSignInMessage} />
    </WrapperSignOption>
  )
}

export default SignWallet
