import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path
      d="M19.7906 14.9301C17.7306 16.9801 14.7806 17.6101 12.1906 16.8001L7.48063 21.5001C7.14063 21.8501 6.47063 22.0601 5.99063 21.9901L3.81063 21.6901C3.09063 21.5901 2.42063 20.9101 2.31063 20.1901L2.01063 18.0101C1.94063 17.5301 2.17063 16.8601 2.50063 16.5201L7.20063 11.8201C6.40063 9.22007 7.02063 6.27007 9.08063 4.22007C12.0306 1.27007 16.8206 1.27007 19.7806 4.22007C22.7406 7.17007 22.7406 11.9801 19.7906 14.9301Z"
      stroke="#007CA1"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.89062 17.49L9.19062 19.79"
      stroke="#007CA1"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z"
      stroke="#007CA1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
)

export default Icon
