import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Providers from 'Providers'
import App from './App'
import reportWebVitals from './reportWebVitals'

const renderApp = () => {
  ReactDOM.render(
    <Providers>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Providers>,
    document.getElementById('root'),
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp)
}

renderApp()
reportWebVitals()
