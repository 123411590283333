import './styles.scss'
import React from 'react'

const ControlInputText = ({ onChange, label, ...props }) => (
  <div className="control-input-text-item">
    {label && <p>{label}</p>}
    <input onChange={(e) => onChange(e.target.value)} {...props} />
  </div>
)

ControlInputText.defaultProps = {
  onChange: () => null,
}

export default ControlInputText
