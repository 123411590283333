import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import './style.scss'
import { DECIMALS } from 'constants/index'
import { EyeIcon, EyeSleepIcon } from 'widgets/Svg'
import { isNumber, roundNumber } from 'helpers'

const TotalBalance = ({ opvPrice, ...props }) => {
  const { currency } = useSelector((state) => state.wallets)
  const [show, setShow] = useState(false)
  // const totalBalance = useMemo(() => {
  //   if (currency) {
  //     return currency.reduce((total, current) => total + current.balance * current.usd_rate, 0)
  //   }
  //   return undefined
  // }, [currency])
  const opvWallet = currency?.find((item) => item.code === 'OPV')

  return (
    <div className="total-balance" {...props}>
      <div onClick={() => setShow((prev) => !prev)} role="presentation">
        <div className="left-balance">
          {show && isNumber(opvWallet?.balance) ? (
            <CurrencyFormat
              value={roundNumber(opvWallet?.balance, { decimals: DECIMALS })}
              displayType="text"
              thousandSeparator
              suffix={opvPrice ? ` ($${roundNumber(opvPrice)}) OPV` : ` OPV`}
              renderText={(t) => t}
            />
          ) : (
            <>
              <span>$ </span>********
            </>
          )}
        </div>
        {show ? <EyeIcon /> : <EyeSleepIcon />}
      </div>
    </div>
  )
}

export default TotalBalance
