import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import ModalCustom from 'components/BaseModal'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { DECIMALS } from 'constants/index'
import { getCurrency } from 'state/wallets/actions'
import { postExchange } from 'state/exchange/actions'
import { toLocaleString } from 'utils'
import { Field } from 'state/exchange/const'

const ModalExchange = ({ visible, dataModal, setModal, setParamsTxs }) => {
  const dispatch = useDispatch()
  const { formattedAmounts, inputCurrencyId, outputCurrencyId } = dataModal || {
    formattedAmounts: undefined,
    inputCurrencyId: undefined,
    outputCurrencyId: undefined,
  }

  const [exchangeLoading, setExchangeLoading] = useState(false)

  // handle exchange now
  const handleExchange = () => {
    if (formattedAmounts && inputCurrencyId && outputCurrencyId) {
      setExchangeLoading(true)

      executeRecaptcha((recaptcha) => {
        dispatch(
          postExchange(
            {
              currency_from_code: inputCurrencyId.code,
              currency_to_code: outputCurrencyId.code,
              amount: +toLocaleString(formattedAmounts[Field.INPUT] * 10 ** DECIMALS),
              recaptcha,
            },
            () => {
              dispatch(getCurrency({ page: 1, pageSize: 100 }))
              setModal({ open: false })
              setExchangeLoading(false)
              setParamsTxs((prev) => ({ ...prev }))
            },
            () => {
              dispatch(getCurrency({ page: 1, pageSize: 100 }))
              setExchangeLoading(false)
              setParamsTxs((prev) => ({ ...prev }))
            },
          ),
        )
      })
    }
  }

  return (
    <ModalCustom
      title="Exchange Processing"
      className="modal-exchange"
      centered
      visible={Boolean(visible)}
      width={700}
      onCancel={() => setModal({ open: false })}
    >
      <p>
        You will receive{' '}
        <span style={{ fontWeight: 'bold' }}>
          {formattedAmounts?.[Field.OUTPUT]} {outputCurrencyId?.code}
        </span>
        . Please select <span style={{ fontWeight: 'bold' }}>"CONFIRM"</span> to complete the transaction.
      </p>
      <div className="modal-exchange-actions">
        <ButtonPrimary loading={exchangeLoading} disabled={exchangeLoading} onClick={handleExchange}>
          Confirm
        </ButtonPrimary>
      </div>
    </ModalCustom>
  )
}

export default ModalExchange
