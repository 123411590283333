import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.8881 3.35075L19.6493 7.11194C22.7836 10.2463 22.7836 12.7537 19.6493 15.8881L15.8881 19.6493C12.7537 22.7836 10.2463 22.7836 7.11194 19.6493L3.35075 15.8881C0.216418 12.7537 0.216418 10.2463 3.35075 7.11194L7.11194 3.35075C10.2463 0.216418 12.7537 0.216418 15.8881 3.35075Z"
      // stroke="#007CA1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.35938 14.8442H8.20703L5.41016 10.2856H5.375C5.38542 10.4393 5.39453 10.6021 5.40234 10.7739C5.41276 10.9458 5.42057 11.1229 5.42578 11.3052C5.43359 11.4875 5.4401 11.6724 5.44531 11.8599V14.8442H4.59375V9.1333H5.73828L8.53125 13.6606H8.55859C8.55339 13.5278 8.54688 13.3755 8.53906 13.2036C8.53125 13.0317 8.52344 12.8547 8.51562 12.6724C8.51042 12.4875 8.50651 12.3091 8.50391 12.1372V9.1333H9.35938V14.8442ZM11.7891 14.8442H10.8594V9.1333H14.0859V9.92236H11.7891V11.7036H13.9375V12.4888H11.7891V14.8442ZM17.1055 14.8442H16.1641V9.93018H14.4922V9.1333H18.7734V9.93018H17.1055V14.8442Z"
      // fill="#007CA1"
    />
  </Svg>
)

export default Icon
