import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import CurrencyFormat from 'react-currency-format'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { formatDate, isNumber, roundNumber } from 'helpers'
import { signInvest, submitSAFT } from 'state/invest/actions'
import { useGetInvestmentDetail } from 'state/invest/hooks'
import { useSignMessage } from 'utils/web3React'
import { BackLinkIcon } from 'widgets/Svg'
import BackLink from 'components/BackLink'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './style.scss'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import ConnectWalletButton from 'components/ConnectWalletButton/ConnectWalletButton'

const SignSALFContract2 = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { userInfo } = useSelector((state) => state.user)
  const [signLoading, setSignLoading] = useState(false)
  const [signData, setSignData] = useState()

  const { account } = useActiveWeb3React()

  const { signMessageAsync } = useSignMessage()

  const { investmentDetail, fetchInvestmentDetail } = useGetInvestmentDetail(id)

  const handleSignMessage = () => {
    if (account !== userInfo?.wallet_address) {
      toast.error('Please connect your wallet to sign message')
    }

    setSignLoading(true)
    const message = id
    signMessageAsync({ message })
      .then((signature) => {
        setSignData({ signature, message })
        setSignLoading(false)
      })
      .catch((error) => {
        if ('ACTION_REJECTED'.indexOf(error)) {
          toast.error('User rejected signing')
        } else {
          toast.error('Error by rejected signing')
        }
        setSignLoading(false)
      })
  }

  const handleSubmitSignature = () => {
    if (!signData || !userInfo || !id) return
    setSignLoading(true)
    executeRecaptcha((recaptcha) => {
      dispatch(
        signInvest(
          {
            investment_id: id,
            address: userInfo?.wallet_address,
            signature: signData.signature,
            message: id,
            recaptcha,
          },
          () => {
            fetchInvestmentDetail()
            setSignLoading(false)
          },
          () => {
            setSignLoading(false)
          },
        ),
      )
    })
  }

  const handleSubmitSAFT = () => {
    if (!id) return
    setSignLoading(true)
    executeRecaptcha((recaptcha) => {
      dispatch(
        submitSAFT(
          {
            investment_id: id,
            recaptcha,
          },
          () => {
            fetchInvestmentDetail()
            setSignLoading(false)
          },
          () => {
            setSignLoading(false)
          },
        ),
      )
    })
  }

  console.log(account?.toLowerCase(), userInfo?.wallet_address?.toLowerCase())

  return (
    <div className="sign-contact-wrapper-2">
      <div className="container">
        <div className="go-back">
          <BackLink
            label={<FormattedMessage id={`SAFT Contract For NFT ${investmentDetail?.nft_id}`} />}
            icon={<BackLinkIcon />}
            to={`/investment/detail/${id}`}
          />
        </div>
        <div className="sign-contact-content-wrapper-2">
          <div className="wrapper-img-heading">
            <img src="/images/logo.png" alt="" />
          </div>
          <div className="wrapper-content-information-2">
            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item content-info-item-heading">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="INFORMATION" />:
                </p>
              </Col>
              <Col xs={16}>
                <p className="content-info-item-heading-content-right">
                  <FormattedMessage id="OPENLIVE INVESTMENT AND DEVELOPMENT JOINT STOCK COMPANY WITH STRATEGIC PARTNERSHIP" />
                </p>
              </Col>
            </Row>

            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="Invest in Platform" />:
                </p>
              </Col>
              <Col xs={16}>
                <p>
                  <FormattedMessage id="OPENLIVE NFT NFT MARKETPLACE" />
                </p>
                <p style={{ wordBreak: 'break-all' }}>
                  <FormattedMessage id="Token contract address" />: 0x36C7B164F85D6F775cD128966D5819c7d36FEfF3
                </p>
                <p>
                  <FormattedMessage id="Token symbol" />: OPV
                </p>
                <p>
                  <FormattedMessage id="Total supply" />: 200,000,000
                </p>
              </Col>
            </Row>
            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="Join Date" />:
                </p>
              </Col>
              <Col xs={16}>
                <p> {formatDate(investmentDetail?.createdAt * 1000, 'YYYY/MM/DD')}.</p>
              </Col>
            </Row>

            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="Investment amount (USDT)" />:
                </p>
              </Col>
              <Col xs={16}>
                <p>
                  {isNumber(investmentDetail?.package?.price_invest) ? (
                    <CurrencyFormat
                      value={roundNumber(investmentDetail?.package?.price_invest, { decimals: 18 })}
                      displayType="text"
                      thousandSeparator
                      suffix={` USDT`}
                      renderText={(t) => t}
                    />
                  ) : (
                    <>--</>
                  )}
                </p>
              </Col>
            </Row>

            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="Total bonus OPV token invest will receive" />:
                </p>
              </Col>
              <Col xs={16}>
                <p>
                  {isNumber(investmentDetail?.package?.bonus_token) ? (
                    <CurrencyFormat
                      value={roundNumber(investmentDetail?.package?.bonus_token, { decimals: 18 })}
                      displayType="text"
                      thousandSeparator
                      suffix={` OPV`}
                      renderText={(t) => t}
                    />
                  ) : (
                    <>--</>
                  )}
                </p>
              </Col>
            </Row>
            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="Exclusive OpenLive NFT card will receive" />:
                </p>
              </Col>
              <Col xs={16}>
                <p>{investmentDetail?.nft_id}</p>
              </Col>
            </Row>
            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="Exclusive OpenLive NFT card" />:
                </p>
              </Col>
              <Col xs={16}>
                <p>{investmentDetail?.dividend_rate}% of Openlive NFT Marketplace profit.</p>
              </Col>
            </Row>
            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="Lockup Schedule" />:
                </p>
              </Col>
              <Col xs={16}>
                <p>
                  -{' '}
                  <FormattedMessage id="OPV Token vesting plan is as follows: 0 month cliff; 8% unlock; 3% vesting per month." />{' '}
                </p>
                <p>
                  -{' '}
                  <FormattedMessage
                    id="Exclusive OpenLive NFT card is started from 2024/01/15. Profit of Exclusive OpenLive NFT card is divided
                  cyclically every year."
                  />
                </p>
                <p>- Get Reward rate from Holding wallet after 3 YEARS of vesting every month 3%.</p>
              </Col>
            </Row>

            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="Transaction ID" />:
                </p>
              </Col>
              <Col xs={16}>
                <p>{investmentDetail?.package_id ? investmentDetail?.package_id : ''}</p>
              </Col>
            </Row>

            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="OPENLIVE INVESTMENT & DEVELOPMENT JOINT STOCK COMPANY " />
                  “<FormattedMessage id="OPENLIVE JSC" />
                  ”:
                </p>
              </Col>
              <Col xs={16}>
                <p>Company number: 0317141176</p>
                <p>Name: Tran Trong Huan</p>
                <p>Title: CEO</p>
                <p>
                  Registered office address: 1st Floor, Packsimex Building, 52 Dong Du, Ben Nghe Ward, District 1, Ho Chi Minh
                  City, Vietnam
                </p>
                <p>Email: contact@openlivenft.com</p>
              </Col>
            </Row>

            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="OPV’s BEP20 Wallet address" />:
                </p>
              </Col>
              <Col xs={16}>
                <p style={{ wordBreak: 'break-all' }}>{investmentDetail?.admin_sign_address}</p>
              </Col>
            </Row>

            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="OPV's Signature" />:
                </p>
              </Col>
              <Col xs={16}>
                <p style={{ wordBreak: 'break-all' }}>
                  <FormattedMessage id="Signed date" />:{' '}
                  {formatDate(investmentDetail?.admin_sign_date * 1000, 'YYYY/MM/DD') || ''}
                </p>
                <p style={{ wordBreak: 'break-all' }}>
                  <FormattedMessage id="Signed address" />: {investmentDetail?.admin_sign_address || ''}
                </p>
                <p style={{ wordBreak: 'break-all' }}>
                  <FormattedMessage id="Signature" />: {investmentDetail?.admin_sign_signature || ''}
                </p>
              </Col>
            </Row>

            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="STRATEGIC PARTNERSHIP" />:
                </p>
              </Col>
              <Col xs={16}>
                <p>
                  <FormattedMessage id="Name" />: {`${userInfo?.profile?.fistname || ''} ${userInfo?.profile?.lastname || ''}`}
                </p>
                <p>
                  <FormattedMessage id="Address" />: {userInfo?.profile?.address || ''}
                </p>
                <p>
                  <FormattedMessage id="Email" />: {userInfo?.email || ''}
                </p>
                <p>
                  <FormattedMessage id="Phone" />: {userInfo?.profile?.phone_number || ''}
                </p>
              </Col>
            </Row>
            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="Purchaser’s Receiving BEP20 Wallet address" />:
                </p>
              </Col>
              <Col xs={16}>
                <p style={{ wordBreak: 'break-all' }}>{userInfo?.wallet_address || ''}</p>
              </Col>
            </Row>
            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="STRATEGIC PARTNERSHIP's Signature" />:
                </p>
              </Col>
              <Col xs={16}>
                <p>
                  <FormattedMessage id="Signed date" />:
                  {formatDate(investmentDetail?.customer_sign_date * 1000, 'YYYY/MM/DD') || ''}
                </p>
                <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <FormattedMessage id="Signed address" />: {investmentDetail?.customer_sign_address || ''}
                </p>
                <p style={{ wordBreak: 'break-all' }}>
                  <FormattedMessage id="Signature" />: {investmentDetail?.customer_sign_signature || ''}
                </p>
              </Col>
            </Row>
            <Row gutter={[{ xs: 4, md: 10 }]} className="content-info-item">
              <Col xs={8}>
                <p>
                  <FormattedMessage id="GENERAL AGREEMENT" />:
                </p>
              </Col>
              <Col xs={16}>
                <p className="commit-both-part">
                  <FormattedMessage id="The two parties agree on a voluntary, non-coercive and self-responsibility" />
                </p>
              </Col>
            </Row>
          </div>

          <div className="wrapper-btn-contract-signature">
            {(() => {
              if (!investmentDetail?.admin_sign_signature) {
                return <ButtonPrimary disabled>OPV waiting approve</ButtonPrimary>
              }

              if (!investmentDetail?.link_contract) {
                if (!investmentDetail?.customer_sign_signature) {
                  if (account?.toLowerCase() !== userInfo?.wallet_address?.toLowerCase()) {
                    if (!account) {
                      return <ConnectWalletButton />
                    }
                    return (
                      <ButtonPrimary disabled>
                        <FormattedMessage id="Please switch to your wallet" />
                      </ButtonPrimary>
                    )
                  }

                  if (!signData) {
                    return (
                      <ButtonPrimary loading={signLoading} onClick={handleSignMessage}>
                        <FormattedMessage id="Click to sign" />
                      </ButtonPrimary>
                    )
                  }

                  return (
                    <ButtonPrimary loading={signLoading} onClick={handleSubmitSignature}>
                      <FormattedMessage id="Submit Signature" />
                    </ButtonPrimary>
                  )
                }
                return (
                  <ButtonPrimary loading={signLoading} onClick={handleSubmitSAFT}>
                    <FormattedMessage id="Submit SAFT" />
                  </ButtonPrimary>
                )
              }
              return <></>
            })()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignSALFContract2
