import React from 'react'
import { Select } from 'antd'
import './styles.scss'
import CurrencyFormat from 'react-currency-format'
import { isNumber, roundNumber } from 'helpers/Number'
import { DECIMALS } from 'constants/index'
import { escapeRegExp } from 'utils'

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

const InputPanel = ({
  title,
  value,
  placeholder,
  disabledSelect,
  walletsList,
  isError,
  errorMess,
  currency,
  optionsSelect,
  onCurrencySelect,
  onUserInput,
  onMax,
}) => {
  const fWallet = walletsList?.find((wallet) => wallet.code === currency?.code)
  const enforcer = (nextUserInput) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput)
    }
  }
  return (
    <div className="exchange-input">
      <div className="exchange-input-head">
        {title && <div className="exchange-input-label">{title}</div>}
        <div className="exchange-input-balance">
          Balance:{' '}
          {isNumber(fWallet?.balance) ? (
            <CurrencyFormat
              value={roundNumber(fWallet.balance / 10 ** DECIMALS, 6)}
              displayType="text"
              thousandSeparator
              renderText={(v) => v}
            />
          ) : (
            '--'
          )}
        </div>
      </div>
      <div className={`exchange-input-input ${isError ? 'error' : ''}`}>
        {optionsSelect && (
          <Select
            disabled={disabledSelect}
            dropdownClassName="exchange-dropdown"
            value={currency?.code}
            onChange={onCurrencySelect}
            getPopupContainer={(trigger) => trigger.parentNode}
            dropdownStyle={{ minWidth: '140px' }}
            placeholder="Select a token"
          >
            {optionsSelect.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                <div className="exchange-dropdown-item">
                  {item.image && (
                    <div className="exchange-dropdown-logo">
                      <img src={item.image} alt="" />
                    </div>
                  )}
                  {item.label && <div className="exchange-dropdown-label">{item.label}</div>}
                </div>
              </Select.Option>
            ))}
          </Select>
        )}
        <input
          value={value}
          type="text"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          pattern="^[0-9]*[.,]?[0-9]*$"
          placeholder={placeholder || '0.0'}
          minLength={1}
          maxLength={79}
          onChange={(event) => {
            // replace commas with periods, because we exclusively uses period as the decimal separator
            enforcer(event.target.value.replace(/,/g, '.'))
          }}
        />
        {onMax && (
          <div className="exchange-input-right">
            <a onClick={() => onMax(fWallet)} role="presentation">
              Max
            </a>
          </div>
        )}
      </div>
      {errorMess && <div className="exchange-input-error">{errorMess}</div>}
    </div>
  )
}

export default InputPanel
