import React, { useState } from 'react'
import './styles.scss'
import VerifyEmailCode from 'components/Auth/VerifyEmailCode'
import { VERIFY_TYPE } from 'components/Auth/const'
import useWalletAuth from 'hooks/useAppAuth'

const VerifyAuthenCode = ({ initialField, token, onBack, onFinish }) => {
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')

  const { verifyCode } = useWalletAuth()

  const onSubmit = (code) => {
    if (!code || code.length < initialField) {
      setErrorMess(`Please enter ${initialField} characters Google Authenticator on the Mobile App`)
    } else {
      setErrorMess('')
      setLoading(true)
      verifyCode(
        token,
        code,
        (receipt) => {
          onFinish({ code, type: 'is_email', ...receipt })
          setLoading(false)
        },
        () => {
          setLoading(false)
        },
      )
    }
  }

  return (
    <VerifyEmailCode
      type={VERIFY_TYPE.AUTHEN}
      initialField={initialField}
      loading={loading}
      errorMess={errorMess}
      onAutoFinish={onSubmit}
      onBack={onBack}
    />
  )
}

VerifyAuthenCode.defaultProps = {
  initialField: 6,
  onFinish: () => null,
}

export default VerifyAuthenCode
