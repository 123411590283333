import { all, takeLatest, call } from 'redux-saga/effects'
import * as types from './constants'
import { fetchHelper } from 'helpers'
import { ROOT_API_URL } from 'constants/index'

function uploadFileFromApi(payload) {
  const formData = new FormData()
  formData.append('key', payload.key)
  formData.append('image', payload.image)
  fetchHelper.removeDefaultHeader('Content-Type')
  const response = fetchHelper
    .fetch(`${ROOT_API_URL}/file/upload`, {
      method: 'POST',
      body: formData,
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
  fetchHelper.addDefaultHeader('Content-Type', 'application/json')
  return response
}
function* uploadFile({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(uploadFileFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    if (cbe) cbe(error)
  }
}

export default function* rootSaga() {
  yield all([takeLatest(types.UPLOAD_FILE, uploadFile)])
}
