import BackLink from 'components/BackLink'
import React, { useEffect, useState, useMemo } from 'react'
import { Row, Col, Empty } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { getHistoryInvestment } from 'state/invest/actions'
import { removeEmpty } from 'helpers'
import PaginationCustomer from 'components/PaginationCustomer'
import MediaCard from 'components/MediaCard'
import ControlInputSelect from 'components/ControlInputSelect'

const InvestmentMyNft = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const listNft = useMemo(
    () => [
      {
        title: 'HEMATITE',
        package_id: '6331c171b9126e04dd44ab3d',
      },
      {
        title: 'AMBER',
        package_id: '6331c21eb9126e04dd44ab3f',
      },
      {
        title: 'ZICRON',
      },
      {
        title: 'PERIDOT',
      },
      {
        title: 'TOURMALINE',
      },
      {
        title: 'TOPAZ',
      },
      {
        title: 'BERYL',
      },
      {
        title: 'EMERALD',
      },
      {
        title: 'SPINEL',
      },
      {
        title: 'RUBY',
      },
    ],
    [],
  )

  /* Bought history */
  const { historyInvestment } = useSelector((state) => state.invest)

  const optionPackages = useMemo(() => {
    if (listNft) {
      return listNft?.map((i) => ({
        label: i?.title,
        package_id: i?.package_id,
      }))
    }
    return undefined
  }, [listNft])

  const [txBoughtParams, setTxBoughtParams] = useState({
    page: 1,
    pageSize: 10,
    package_id: '',
    status_url: false,
  })
  useEffect(() => {
    const parseSearch = Object.fromEntries(new URLSearchParams(location.search).entries())
    setTxBoughtParams((prev) => ({
      ...prev,
      ...parseSearch,
      status_url: true,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (txBoughtParams) {
      dispatch(getHistoryInvestment(removeEmpty(txBoughtParams)))
    }
  }, [dispatch, txBoughtParams])

  const handleSelectPackage = (value) => {
    setTxBoughtParams((prev) => ({
      ...prev,
      package_id: value ? JSON.parse(value)?.package_id : '',
    }))
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    queryParams.set('pageSize', 10)
    if (value) {
      queryParams.set('package_id', JSON.parse(value)?.package_id)
    } else {
      queryParams.delete('package_id')
    }
    history.replace(`?${queryParams.toString()}`)
  }

  const listMyNft = [
    {
      url: '/images/icons/smonter-nft.png',
      title: 'hematite',
    },
    {
      url: '/images/icons/smonter-nft.png',
      title: 'hematite',
    },
    {
      url: '/images/icons/smonter-nft.png',
      title: 'hematite',
    },
  ]

  return (
    <div className="wapper-investment-my-nft">
      <div className="mynft-header">
        {window.innerWidth > 768 ? (
          <BackLink showBackIcon={false} label={<FormattedMessage id="My NFTs" />} />
        ) : (
          <div className="mynft-input-select">
            <ControlInputSelect
              dataSelect={optionPackages}
              defaultValue="Select Name"
              onChange={(value) => handleSelectPackage(value)}
            />
          </div>
        )}
      </div>
      <div className="my-nft-list">
        <div className={listMyNft.length <= 3 ? 'my-nft-list-list my-nft-list-list-short' : 'my-nft-list-list'}>
          <Row gutter={[16, 16]}>
            {(() => {
              if (historyInvestment?.rows?.length) {
                return (
                  <>
                    {historyInvestment?.rows?.map((item) => (
                      <Col xs={24} sm={12} lg={8}>
                        <div className="my-nft-item-wrapper">
                          <div className="my-nft-item-img">
                            <MediaCard fileUrl={item?.package?.avatar} />
                          </div>
                          <div className="my-nft-item-title">
                            <Link to={`/investment/detail/${item._id}`}> {item?.package?.title}</Link>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </>
                )
              }
              if (historyInvestment === undefined) {
                return <div className="my-nft-list-loading">Loading...</div>
              }
              return <Empty className="my-nft-list-empty" />
            })()}
            <Col span={24}>
              <PaginationCustomer
                style={{ justifyContent: 'center' }}
                total={historyInvestment?.total}
                current={historyInvestment?.page}
                pageSize={txBoughtParams.pageSize}
                onChange={(page, pageSize) => {
                  setTxBoughtParams((prev) => ({
                    ...prev,
                    page,
                    pageSize,
                  }))
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default InvestmentMyNft
