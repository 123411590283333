import * as types from './constants'

export const getProfile = (payload, cbs, cbe) => ({
  type: types.GET_PROFILE,
  payload,
  cbs,
  cbe,
})
export const getProfileSuccess = (payload) => ({
  type: types.GET_PROFILE_SUCCESS,
  payload,
})

export const updateProfile = (payload, cbs, cbe) => ({
  type: types.UPDATE_PROFILE,
  payload,
  cbs,
  cbe,
})

export const updateKyc = (payload, cbs, cbe) => ({
  type: types.UPDATE_KYC,
  payload,
  cbs,
  cbe,
})

export const getCustomerDetail = (payload) => ({
  type: types.GET_CUSTOMER_DETAILS,
  payload,
})
export const getCustomerDetailSuccess = (payload, params) => ({
  type: types.GET_CUSTOMER_DETAILS_SUCCESS,
  payload,
  params,
})

export const getCustomerSummaryFloor = (payload) => ({
  type: types.GET_CUSTOMER_SUMMARY_FLOOR,
  payload,
})
export const getCustomerSummaryFloorSuccess = (payload) => ({
  type: types.GET_CUSTOMER_SUMMARY_FLOOR_SUCCESS,
  payload,
})
