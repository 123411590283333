/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import ProfileAccount from 'components/ProfileAccount'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './styles.scss'
import TotalBalance from 'components/TotalBalance'
import Languages from 'components/Languages'
import useTokenPrice from 'hooks/useTokenPrice'
import { TOKEN } from 'config/constants'

const HeaderDesktop = ({ links, isMobile, isLogin, showMenu, toggleMenu }) => {
  const history = useHistory()
  const { userInfo } = useSelector((state) => state.user)

  const { price: opvPrice } = useTokenPrice(TOKEN)

  return (
    <header className="w-header-desktop">
      <div className="header-body" style={{ height: isMobile ? 80 : 100 }}>
        <div className="header-left">
          <Link to="/">
            <img className="header-logo" src="/images/logo.png" alt="" />
          </Link>
        </div>
        <div className="header-right">
          {isLogin && userInfo && <TotalBalance opvPrice={opvPrice?.usdPrice} />}

          {isLogin && userInfo && (
            <ProfileAccount hideName={isMobile} style={{ marginLeft: window.innerWidth >= 768 ? '23px' : '10px' }} />
          )}

          {isLogin && userInfo && <Languages style={{ marginLeft: window.innerWidth >= 768 ? '23px' : '10px' }} />}

          {isMobile && (
            <div className="menu-collapse" role="presentation" onClick={() => toggleMenu()}>
              <img src="/images/icons/menu_collapse.png" alt="" />
            </div>
          )}

          {!userInfo && (
            <div className="header-right-actions">
              <Link to="/login" className="actions" style={{ marginRight: 20 }}>
                <ButtonPrimary className={history.location.pathname.indexOf('/login') !== -1 ? 'active' : ''}>
                  <FormattedMessage id="Sign In" />
                </ButtonPrimary>
              </Link>
              <Link to="/register" className="actions">
                <ButtonPrimary className={history.location.pathname.indexOf('/register') !== -1 ? 'active' : ''}>
                  <FormattedMessage id="Sign Up" />
                </ButtonPrimary>
              </Link>
            </div>
          )}
        </div>

        <div className="menu-content-mobile" data-visible={showMenu.toString()}>
          {isMobile && showMenu && (
            <div className="collapse-icon">
              <input type="checkbox" id="menu" checked={showMenu} onChange={toggleMenu} />
              <label htmlFor="menu" className="icon">
                <div className="menu" />
              </label>
            </div>
          )}
          <div className="menu-body">
            <ul className="menu-link">
              {links.map((item, index) => {
                const isHttp = item?.href?.startsWith('http')
                const Tag = isHttp ? 'a' : Link
                const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
                return (
                  <li
                    key={`menu-mb-${index}`}
                    className={history.location.pathname === item.href ? 'active' : ''}
                    role="presentation"
                    onClick={toggleMenu}
                  >
                    <Tag {...propsLink} rel="noreferrer">
                      {item.label}
                    </Tag>
                  </li>
                )
              })}
            </ul>
            {!isLogin && (
              <div className="menu-actions-mobile">
                <Link to="/register" onClick={toggleMenu}>
                  <ButtonSecondary
                    size="larger"
                    style={{
                      color: history.location.pathname === '/register' ? '#000' : '#fff',
                      background: history.location.pathname === '/register' ? '#fff' : 'transparent',
                    }}
                  >
                    Sign Up
                  </ButtonSecondary>
                </Link>
                <Link to="/login" onClick={toggleMenu}>
                  <ButtonPrimary
                    size="larger"
                    style={{
                      color: history.location.pathname === '/login' ? '#000' : '#fff',
                      background: history.location.pathname === '/login' ? '#fff' : 'transparent',
                    }}
                  >
                    Sign In
                  </ButtonPrimary>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}
export default HeaderDesktop
