import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ToastContainer } from 'react-toastify'
import 'antd/dist/antd.css'
import 'react-toastify/dist/ReactToastify.css'
import './assets/css/global.scss'
import { languages } from 'language/config'
import { PrivateLayout, PublicLayout } from 'layouts'
import { getAuthToken, fetchHelper } from 'helpers'
import { useConfigRecaptcha } from 'helpers/GoogleRecaptcha'
import useEagerConnect from 'hooks/useEagerConnect'
import SignOption from 'modules/auth/pages/SignOption'
import SignEmail from 'modules/auth/pages/SignEmail'
import SignWallet from 'modules/auth/pages/SignWallet'
import SignUp from 'modules/auth/pages/SignUp'
import ForgotPassword from 'modules/auth/pages/ForgotPassword'
import ResetPassword from 'modules/auth/pages/ResetPassword'
import Page404 from 'modules/404'
// import ComingSoon from 'modules/comingSoon'
import Dashboard from 'modules/dashboard'
import Account from 'modules/account'
import Wallet from 'modules/wallet'
import Security from 'modules/security'
import PrimaryKYC from 'modules/primaryKYC'
import Withdraw from 'modules/withdraw'
import Exchange from 'modules/exchange'
import VerifyCode from 'modules/auth/pages/VerifyCode'
import NewPassword from 'modules/auth/pages/NewPassword'
import Deposit from 'modules/deposit'
import { getProfile } from 'state/user/actions'
import { getListCountry } from 'state/app/actions'
import { getChainList, getCurrency, getWalletAddress } from 'state/wallets/actions'
import WalletHistory from 'modules/walletHistory'
import AccountActivity from 'modules/AccountActivity'
import NetWork from 'modules/NetWork'
import NetWorkDetail from 'modules/NetWork/NetworkDetail'
import ModalSoon from 'components/ModalSoon'
import RedirectTo from 'RedirectTo'
// import Stake from 'modules/stake'
// import StakingHistory from 'modules/staking-history/index'
import Investment from 'modules/Investment'
import InvestmentMyNft from 'modules/InvestmentMyNft'
import InvestmentDetail from 'modules/InvestmentDetail'
// import SignSALFContract from 'modules/SignSALFContract'
import SignSALFContract2 from 'modules/SignSALFContract2'
import ModalConnect from 'components/ModalConnect'

const isLogin = () => {
  const authToken = getAuthToken()
  if (authToken) {
    fetchHelper.addDefaultHeader('Authorization', `Bearer ${authToken}`)
  }
  return Boolean(authToken)
}

const DefaultRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PublicLayout
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...rest} isLogin={isLogin()}>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PublicLayout
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogin() ? (
          <Layout {...rest} isLogin={isLogin()}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: '/account', state: { from: props.location } }} />
        )
      }
    />
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PrivateLayout
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <Layout {...rest} isLogin={isLogin()}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

if (process.env.NODE_ENV === 'production') {
  window.console.log = () => {}
  window.console.warn = () => {}
}

function GlobalHooks() {
  useEagerConnect()
  useConfigRecaptcha()
  return null
}

function App() {
  const dispatch = useDispatch()
  const { language } = useSelector((state) => state.app)
  const hadValidToken = isLogin()

  useEffect(() => {
    if (hadValidToken) {
      dispatch(getChainList())
      dispatch(getListCountry())
      dispatch(getProfile())
    }
  }, [dispatch, hadValidToken])

  /**
   * Fetch wallet
   */
  useEffect(() => {
    if (hadValidToken) {
      dispatch(getCurrency({ page: 1, pageSize: 100 }))
      dispatch(getWalletAddress({ page: 1, pageSize: 100 }))
    }
  }, [dispatch, hadValidToken])

  return (
    <>
      <ToastContainer />
      <IntlProvider locale={language || 'en'} messages={languages[language]}>
        <GlobalHooks />
        <Router>
          <Switch>
            {/* <Route path="*" component={ComingSoon} /> */}

            <DefaultRoute exact path="/404" component={Page404} />

            <PublicRoute exact path="/login" component={SignOption} />
            <PublicRoute exact path="/login/email" component={SignEmail} />
            <PublicRoute exact path="/login/wallet" component={SignWallet} />
            <PublicRoute exact path="/register" component={SignUp} />
            <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
            <PublicRoute exact path="/reset-password" component={ResetPassword} />
            <PublicRoute exact path="/verify-code" component={VerifyCode} />
            <PublicRoute exact path="/new-password" component={NewPassword} />

            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/wallet" component={Wallet} />
            <PrivateRoute exact path="/exchange" component={Exchange} />
            <PrivateRoute exact path="/wallet/history" component={WalletHistory} />
            <PrivateRoute exact path="/network" component={NetWork} />
            <PrivateRoute exact path="/investment" component={Investment} />
            <PrivateRoute exact path="/investment/my-nft" component={InvestmentMyNft} />
            <PrivateRoute exact path="/investment/detail/:id" component={InvestmentDetail} />
            <PrivateRoute exact path="/investment/sign-contract/:id" component={SignSALFContract2} />
            <PrivateRoute exact path="/wallet/deposit/:id" component={Deposit} />
            <PrivateRoute exact path="/wallet/withdraw/:id" component={Withdraw} />
            <PrivateRoute exact path={['/network/detail', '/network/detail/:id']} component={NetWorkDetail} />
            <PrivateRoute exact path={['/profile/security', '/profile/security/:id', '/security']} component={Security} />
            <PrivateRoute exact path={['/profile/primary-kyc', '/security/primary-kyc']} component={PrimaryKYC} />
            <PrivateRoute exact path={['/profile/account-activity', '/security/account-activity']} component={AccountActivity} />
            <PrivateRoute exact path={['/profile/account', '/profile']} component={Account} />

            <Route component={RedirectTo} />
          </Switch>
        </Router>
        <ModalSoon />
        <ModalConnect />
      </IntlProvider>
    </>
  )
}

export default App
