import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInvestmentDetail } from './actions'

export function useGetInvestmentDetailById(id) {
  const { investmentDetail } = useSelector((state) => state.invest)
  return useMemo(() => investmentDetail[id], [id, investmentDetail])
}
export function useGetInvestmentDetail(id) {
  const dispatch = useDispatch()
  const investmentDetail = useGetInvestmentDetailById(id)
  const fetchInvestmentDetail = useCallback(() => {
    if (id) {
      dispatch(getInvestmentDetail({ investment_id: id }))
    }
  }, [dispatch, id])
  useEffect(() => {
    fetchInvestmentDetail()
  }, [fetchInvestmentDetail])
  return { investmentDetail, fetchInvestmentDetail }
}
