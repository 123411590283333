import React, { useState } from 'react'
import './style.scss'
import { linksPublic } from 'components/Menu/config'
import HeaderDesktop from 'components/Menu/HeaderDesktop'
import useIsMobile from 'hooks/useIsMobile'
import NavigationMobile from 'components/NavigationMobile'
// import { useCheckChangeAccount } from 'hooks/useCheckChangeAccount'
import Panel from 'components/Menu/Panel'
import Body from 'components/Menu/Body'
import HeaderMobile from 'components/Menu/HeaderMobile'

const PrivateLayout = ({ isLogin, children }) => {
  const isMobile = useIsMobile()
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    // document.querySelector('body').style.overflow = showMenu ? 'unset' : 'hidden'
    setShowMenu((prev) => !prev)
  }

  // useCheckChangeAccount()

  return (
    <div className="wrapper-big">
      <img className="linear-block-1" src="/images/linear-block.png " alt="" />
      <img className="linear-block-2" src="/images/linear-block-1.png " alt="" />
      {isMobile ? (
        <HeaderMobile links={linksPublic} isMobile={isMobile} isLogin={isLogin} showMenu={showMenu} toggleMenu={toggleMenu} />
      ) : (
        <HeaderDesktop links={linksPublic} isMobile={isMobile} isLogin={isLogin} showMenu={showMenu} toggleMenu={toggleMenu} />
      )}

      <div className="private-layout-container">
        <div className="private-content-body">
          {!isMobile && <Panel />}

          <Body isLogin={isLogin} isMobile={isMobile} showMenu={showMenu} linksPublic={linksPublic} toggleMenu={toggleMenu}>
            {children}
          </Body>
        </div>

        {isMobile && <NavigationMobile showMenu={showMenu} toggleMenu={toggleMenu} />}
      </div>
    </div>
  )
}

export default PrivateLayout
