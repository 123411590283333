import React from 'react'
import './styles.scss'
import { formatCode, formatDate } from 'helpers/index'
import { DECIMALS } from 'constants/index'
import { getBlockExploreLink } from 'utils'
import { formatAmount } from 'helpers'
import { FormattedMessage } from 'react-intl'
import TxStatus from 'components/TxStatus'
import TxAmount from 'components/TxAmount'

const ItemTransaction = ({ data, setViewDetails, setModalVerifyCode, ...props }) => (
  <div className="wItemTxhHistory" {...props}>
    <div className="history-item-line">
      <p>
        <FormattedMessage id="Amount" />
      </p>
      <TxAmount action={data.action} title={formatAmount(data.amount, { decimals: DECIMALS })} />
    </div>
    {/*  */}
    <div className="history-item-line">
      <p>
        <FormattedMessage id="Currency" />
      </p>
      <p>{data.currency}</p>
    </div>
    {/*  */}
    <div className="history-item-line">
      <p>
        <FormattedMessage id="Action" />
      </p>
      <p>{data.action}</p>
    </div>
    {/*  */}
    <div className="history-item-line">
      <p>
        <FormattedMessage id="Network" />
      </p>
      <p>{data.chain}</p>
    </div>
    {/*  */}
    <div className="history-item-line">
      <p>
        <FormattedMessage id="Status" />
      </p>
      <TxStatus status={data.status} title={data.status} />
    </div>
    {/*  */}
    <div className="history-item-line">
      <p>Txh</p>
      {(() => {
        if (data.status !== 'COMPLETED') return data.status
        if (data.txhash.length < 60) {
          return <p>{formatCode(data.txhash, 5, 5)}</p>
        }
        return (
          <a href={getBlockExploreLink(data.txhash, 'transaction', data.chain)} target="_blank" rel="noreferrer">
            {formatCode(data.txhash, 5, 5)}
          </a>
        )
      })()}
    </div>
    {/*  */}
    <div className="history-item-line">
      <p>
        <FormattedMessage id="Date" />
      </p>
      <p>{formatDate(data.createdAt * 1000)}</p>
    </div>
    {/*  */}
    <div className="history-item-line">
      <p />
      {data.status !== 'CREATED' ? (
        <a style={{ fontWeight: 700 }} role="presentation" onClick={() => setViewDetails({ toggle: true, dataModal: data })}>
          <FormattedMessage id="View" />
        </a>
      ) : (
        <a style={{ color: '#2FB4FF' }} role="presentation" onClick={() => setModalVerifyCode(data)}>
          <FormattedMessage id="Verify" />
        </a>
      )}
    </div>
  </div>
)

export default ItemTransaction
