import React from 'react'
import './styles.scss'
import { formatDate } from 'helpers'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from 'state/auth/actions'
import { FormattedMessage } from 'react-intl'

const ItemActive = ({ data, setViewDetails, setModalVerifyCode, ...props }) => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <>
      <div className="Active-item-recent-transaction" {...props}>
        <div>
          <p>{formatDate(data.createdAt * 1000)}</p>

          {data.status !== 'CREATED' ? (
            <Link to="/security/account-activity" className="ts-log-out" onClick={handleLogout}>
              <FormattedMessage id="Log Out" />
            </Link>
          ) : (
            <a className="recent-transaction-table-view" role="presentation" onClick={() => setModalVerifyCode(data)}>
              <FormattedMessage id="Verify" />
            </a>
          )}
        </div>

        <div>
          <p>
            <FormattedMessage id="IP Address" />
          </p>
          <p>{data.ip}</p>
        </div>
        <div>
          <p>
            <FormattedMessage id="Location" />
          </p>
          <p>Ho Chi Minh city</p>
        </div>
        <div>
          <p>
            <FormattedMessage id="Device" />
          </p>
          <p data-status={data.status}>
            <span>{data.os}</span>
          </p>
        </div>
      </div>
    </>
  )
}

export default ItemActive
