import React, { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Row, Col } from 'antd'
import './styles.scss'
import BackLink from 'components/BackLink'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import ControlInputSelect from 'components/ControlInputSelect'
import RecentTransaction from 'components/History/RecentTransaction'
import { createWallet, getCurrencyAttr } from 'state/wallets/actions'
import { getTransactionList } from 'state/transactions/actions'
import ModalScan from 'components/ModalScan'
import { FormattedMessage } from 'react-intl'
import { BackLinkIcon } from 'widgets/Svg'
import { isMobile } from 'react-device-detect'
import Address from './component/Address'

const Deposit = ({ notica }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { chainList, currency, currencyAttr, walletsAddress } = useSelector((state) => state.wallets)
  const { transactionsList } = useSelector((state) => state.transactions)
  const [selectCurrency, setSelectCurrency] = useState()
  const [selectChain, setSelectChain] = useState()
  const [walletAddressSelect, setWalletAddressSelect] = useState()
  const [showNoti, setShowNoti] = useState(false)
  const [open, setOpen] = useState(false)

  const optionCurrency = useMemo(() => {
    if (currency) {
      return currency.map((item) => ({
        label: item.title,
        value: item.code,
        type: item.type,
        currency_code: item.code,
        image: item.icon,
      }))
    }
    return undefined
  }, [currency])

  const optionChain = useMemo(() => {
    if (chainList && currencyAttr?.length > 0) {
      return currencyAttr.map((currAttr) => {
        const fChain = chainList.find((o) => o._id === currAttr.blockchain)
        return {
          _id: fChain._id,
          label: fChain.title,
          value: fChain.chainid,
          chain_code: fChain.code,
          image: fChain.icon,
        }
      })
    }
    return undefined
  }, [chainList, currencyAttr])
  /* Initial select once chain */
  useEffect(() => {
    if (optionCurrency && params?.id) {
      const fCurrency = optionCurrency.find((o) => o.value === params.id)
      setSelectCurrency(JSON.stringify(fCurrency))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionCurrency])

  /* Wallet currency default select (any TOKEN) */
  const baseCurrency = useMemo(() => {
    if (selectCurrency) {
      return currency?.find((item) => item.code === JSON.parse(selectCurrency).currency_code)
    }
    return undefined
  }, [currency, selectCurrency])

  /* Clear all data when change coin */
  useEffect(() => {
    setSelectChain()
  }, [selectCurrency])

  /**
   * Fetch attr for get fee by code of currency
   */
  useEffect(() => {
    if (baseCurrency?._id) {
      dispatch(
        getCurrencyAttr({
          currency_id: baseCurrency._id,
        }),
      )
    }
  }, [baseCurrency, dispatch])

  /* Handle get address when enough field currency & chain */
  useEffect(() => {
    if (walletsAddress && selectCurrency && selectChain) {
      const pSelectChain = JSON.parse(selectChain)
      const pSelectCurrency = JSON.parse(selectCurrency)
      const fWallet = walletsAddress.find((item) => {
        if (item.currency === pSelectCurrency.currency_code && item.chain === pSelectChain.chain_code) {
          return true
        }
        return false
      })
      if (fWallet) {
        setWalletAddressSelect(fWallet)
      } else {
        /**
         * Select wallet will be rerender and checked
         * Create new wallet if wallet is not available
         * After create new wallet this will make actions in the saga for fetch all new wallet (Search keyword: {yield put(actions.getWalletAddress})
         */
        executeRecaptcha((recaptcha) => {
          dispatch(
            createWallet(
              {
                currency_code: pSelectCurrency.currency_code,
                chain_code: pSelectChain.chain_code,
                type: 'CRYPTO',
                recaptcha,
              },
              () => {
                // dispatch(getWalletAddress({ page: 1, pageSize: 100 }))
              },
              (error) => {
                if (error.code === 'err_not_found') {
                  setWalletAddressSelect(undefined)
                }
              },
            ),
          )
        })
      }
    }
  }, [dispatch, walletsAddress, selectCurrency, selectChain])

  /* Fetch txh history */
  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    currency: undefined,
    type: 'DEPOSIT',
  })
  useEffect(() => {
    if (selectCurrency) {
      setTxParams((prev) => ({
        ...prev,
        currency: JSON.parse(selectCurrency).currency_code,
      }))
    }
  }, [selectCurrency])
  useEffect(() => {
    if (txParams.currency) {
      dispatch(getTransactionList(txParams))
    }
  }, [dispatch, txParams])

  return (
    <div className="warp-page-deposit">
      <div className="container warp-form-deposit">
        <div className="go-back">
          <BackLink label={<FormattedMessage id="Deposit" />} icon={<BackLinkIcon />} to="/wallet" />
        </div>

        <div className="deposit-form">
          <Row gutter={[16, 16]} className="deposit-form-top">
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <div className="box-select-deposit">
                {optionCurrency && selectCurrency && (
                  <ControlInputSelect
                    title={<FormattedMessage id="Select currency" />}
                    dataSelect={optionCurrency}
                    value={selectCurrency}
                    onChange={(curr) => {
                      history.replace(`/wallet/deposit/${JSON.parse(curr).currency_code}`)
                      setSelectCurrency(curr)
                    }}
                  />
                )}
                {optionChain && (
                  <ControlInputSelect
                    title={<FormattedMessage id="Select Chain" />}
                    dataSelect={optionChain}
                    placeholder={<FormattedMessage id="Select Chain" />}
                    value={selectChain || undefined}
                    onChange={setSelectChain}
                  />
                )}
              </div>

              {selectChain && (
                <div className="box-qr-code-title">
                  <p>
                    <FormattedMessage id="Address" />
                  </p>
                  <p>
                    <FormattedMessage id="Use the wallet address below to deposit" />
                  </p>
                </div>
              )}
            </Col>
          </Row>
          {selectChain && (
            <>
              <Address text={walletAddressSelect?.address} />
              {walletAddressSelect?.addressTag && (
                <div className="memo-section-wrapper">
                  <Row gutter={[16, 16]}>
                    <Col className="title-memo">
                      <FormattedMessage id="MEMO" />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} className="memo-section-content">
                    <Col>
                      <div className="token-memo">{walletAddressSelect?.addressTag}</div>
                    </Col>
                    <Col>
                      <div>
                        <img
                          role="presentation"
                          onClick={() => {
                            if (navigator.clipboard) {
                              navigator.clipboard.writeText(walletAddressSelect?.addressTag)
                              setShowNoti(true)
                              setTimeout(() => {
                                setShowNoti(false)
                              }, 3000)

                              if (showNoti) setShowNoti(false)
                            }
                          }}
                          src="/images/icons/icon-copy.png"
                          alt=""
                        />
                        {showNoti && <div className="tooltip">{notica || 'Copied'}</div>}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <img src="/images/icons/icon-qr.png" role="presentation" onClick={() => setOpen(true)} alt="" />
                      </div>
                    </Col>
                    <Col>
                      <div className="note-required">
                        <FormattedMessage id="MEMO is required, or you will lose your coins" />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              <div className="deposit-box-attention">
                <ul>
                  <li>
                    {selectCurrency && (
                      <p>
                        <FormattedMessage id="Send only" /> <span>{JSON.parse(selectCurrency).currency_code} </span>
                        <FormattedMessage id="to this deposit address" />
                      </p>
                    )}
                  </li>
                  <li>
                    <p>
                      <FormattedMessage id="Ensure the network is" />
                      {selectChain && <span> {`${JSON.parse(selectChain)?.label} ${JSON.parse(selectChain).chain_code}`}</span>}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="deposit-box-expected">
                <div className="item-expected">
                  <p>
                    <FormattedMessage id="Expected arrival" />
                  </p>
                  <p>
                    <FormattedMessage id="15 network confirmations" />
                  </p>
                </div>

                <div className="item-expected">
                  <p>
                    <FormattedMessage id="Expected unlock" />
                  </p>
                  <p>
                    <FormattedMessage id="15 network confirmations" />
                  </p>
                </div>
              </div>
            </>
          )}
          {!isMobile && <RecentTransaction txParams={txParams} transactionsList={transactionsList} setTxParams={setTxParams} />}
        </div>
      </div>
      <ModalScan title={<FormattedMessage id="Scan to Memo" />} visible={open} setOpen={setOpen} />
    </div>
  )
}

export default Deposit
