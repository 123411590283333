import './styles.scss'
import React from 'react'
import { Table } from 'antd'
import { FormattedMessage } from 'react-intl'
import { formatDate } from 'helpers'
import PaginationCustomer from 'components/PaginationCustomer'

const TableHistoryLoginDesktop = ({ handleLogout, dataSource, historyLogin, setParamsHistory, paramsHistory, ...props }) => {
  const columns = (handleLogout) => [
    {
      title: <FormattedMessage id="Date" />,
      dataIndex: 'createdAt',
      render: (text) => formatDate(text * 1000, 'YYYY/MM/DD'),
    },
    {
      title: <FormattedMessage id="IP Address" />,
      dataIndex: 'ip',
      render: (text) => text,
    },
    {
      title: <FormattedMessage id="Location" />,
      dataIndex: 'index',
      render: () => 'Ho Chi Minh city',
    },
    {
      title: <FormattedMessage id="Device" />,
      dataIndex: 'os',
    },
    {
      title: <FormattedMessage id="Actions" />,
      render: () => (
        <a className="btn-logout" role="presentation" onClick={handleLogout}>
          <FormattedMessage id="Log Out" />
        </a>
      ),
    },
  ]
  return (
    <div className="account-activity-recent-transaction-table">
      <Table
        scroll={{ y: 400 }}
        columns={columns(handleLogout)}
        dataSource={dataSource}
        rowKey={(record) => record._id}
        pagination={false}
        {...props}
      />
      <PaginationCustomer
        total={historyLogin?.total}
        current={historyLogin?.page}
        onChange={(page, pageSize) => {
          setParamsHistory((prev) => ({
            ...prev,
            page,
            pageSize,
          }))
        }}
      />
    </div>
  )
}

export default TableHistoryLoginDesktop
