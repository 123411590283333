import React from 'react'
import { Checkbox } from 'antd'
import './style.scss'

const CheckBox = ({ className, value, size, children, ...props }) => (
  <Checkbox className={`w-checkbox-${size} ${className}`} {...props}>
    {children}
  </Checkbox>
)
CheckBox.defaultProps = {
  className: '',
  size: 'standard',
}
export default CheckBox
