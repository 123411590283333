import React from 'react'
import { ArrowLeftIcon } from 'widgets/Svg'
import './styles.scss'

const AuthCard = ({ children, className, onBack, ...props }) => (
  <div className={`auth-card ${className}`} {...props}>
    {onBack && (
      <div className="auth-back-icon" onClick={onBack} role="presentation">
        <ArrowLeftIcon width={20} />
      </div>
    )}
    <div className="auth-body">{children}</div>
  </div>
)

export default AuthCard
