import React from 'react'
import { Form } from 'antd'
import './styles.scss'
import AuthCard from 'components/Auth/AuthCard'
import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { FormattedMessage } from 'react-intl'

const FormForgotPass = ({ onFinish, ...props }) => {
  const [form] = Form.useForm()
  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      onFinish(values)
    })
  }
  return (
    <AuthCard className="form-forgot fadeIn" {...props}>
      <h2 className="forgot-title">
        <FormattedMessage id="Forgot Password" />
      </h2>
      <Form layout="vertical" form={form} onFinish={onFormSubmit}>
        <FormItem
          label={
            <span className="forgot-label">
              <FormattedMessage id="Enter your email address" />
            </span>
          }
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Enter a valid email address!',
            },
            {
              required: true,
              message: <FormattedMessage id="Please enter email field" />,
            },
          ]}
        >
          <Input placeholder="example@gmail.com" type="email" />
        </FormItem>

        <FormItem>
          <ButtonPrimary
            type="submit"
            className="form-action-forgot active"
            style={{
              maxWidth: window.innerWidth > 768 ? '150px' : '100%',
              height: window.innerWidth < 768 && '48px',
              borderRadius: '10px',
            }}
          >
            <FormattedMessage id="Continue" />
          </ButtonPrimary>
        </FormItem>
      </Form>
    </AuthCard>
  )
}

export default FormForgotPass
