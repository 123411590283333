import React from 'react'
import './styles.scss'

const SearchInput = ({ className, value, onChange, placeholder, props }) => (
  <div className={`search-wrapper ${className}`} {...props}>
    <input placeholder={placeholder} value={value} onChange={onChange} />
    <img src="/images/icons/search-icon.png" alt="" />
  </div>
)
SearchInput.defaultProps = {
  value: '',
  className: '',
}

export default SearchInput
