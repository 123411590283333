import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { Col, Row, Menu } from 'antd'
import BackLink from 'components/BackLink'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './style.scss'
import { useGetInvestmentDetail } from 'state/invest/hooks'
import MediaCard from 'components/MediaCard'
import { formatCode, isNumber, roundNumber } from 'helpers'
import CurrencyFormat from 'react-currency-format'
import { getBlockExploreLink, getBscScanLinkForNft } from 'utils'
import { NFT, USER_REWARD_URL } from 'config/constants'

const { SubMenu } = Menu

const MyInvestmentDetail = () => {
  const params = useParams()

  const userInfo = useSelector((state) => state.user.userInfo)
  const { investmentDetail } = useGetInvestmentDetail(params.id)
  const opvReward = investmentDetail?.package?.meta_data?.find((item) => item.key === 'OPV Reward')

  console.log(investmentDetail)
  return (
    <div className="invest-detail-wrapper">
      <div className="container">
        <div className="go-back">
          <BackLink label={<FormattedMessage id={`NFT Detail: ${investmentDetail?.nft_id}`} />} to="/investment/my-nft" />
        </div>
        <div className="invest-detail-wrapper-info">
          <Row gutter={60} className="invest-detail-wrapper-info-content">
            <Col xs={24} md={12} className="invest-detail-wrapper-img">
              <MediaCard fileUrl={investmentDetail?.package?.avatar} />
            </Col>
            <Col xs={24} md={12} className="invest-wrapper-info-detail">
              <div className="info-title">{investmentDetail?.package?.title}</div>
              <div className="info-action">
                <ButtonPrimary>
                  {isNumber(investmentDetail?.price_usd_invest) ? (
                    <CurrencyFormat
                      value={roundNumber(investmentDetail?.price_usd_invest, { decimals: 18 })}
                      displayType="text"
                      thousandSeparator
                      suffix={` USDT`}
                      renderText={(t) => t}
                    />
                  ) : (
                    <>--</>
                  )}
                </ButtonPrimary>
                <ButtonPrimary>
                  <FormattedMessage id="Completed" />
                </ButtonPrimary>
              </div>
              <p className="info-des">
                <FormattedMessage id="This NFT will give you some unique benefits." />
              </p>
              <div className="info-des-detail">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <FormattedMessage id="Owner" />:
                        </p>
                      </td>
                      <td>
                        <p>
                          <a href={getBlockExploreLink(userInfo?.wallet_address, 'address')} target="_blank" rel="noreferrer">
                            {formatCode(userInfo?.wallet_address, 5, 5)}
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <p>
                            <FormattedMessage id="NFT ID" />:
                          </p>
                        </p>
                      </td>
                      <td>
                        <a href={getBscScanLinkForNft(NFT, investmentDetail?.nft_id)} target="_blank" rel="noreferrer">
                          {investmentDetail?.nft_id}
                          <img src="/images/icons/icons_link-chain.png" alt="" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <FormattedMessage id="NFT Contract" />:
                        </p>
                      </td>
                      <td>
                        <a href={getBlockExploreLink(NFT, 'address')} target="_blank" rel="noreferrer">
                          {formatCode(NFT, 5, 5)}
                          <img src="/images/icons/icons_link-chain.png" alt="" />
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p>
                          <FormattedMessage id="Dividend" />:
                        </p>
                      </td>
                      <td>
                        <p>{investmentDetail?.dividend_rate}%</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <FormattedMessage id="Daily Holder Reward" />:
                        </p>
                      </td>
                      <td>
                        <a href={USER_REWARD_URL} target="_blank" rel="noreferrer">
                          {opvReward && opvReward.value}
                          <img src="/images/icons/icons_link-chain.png" alt="" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <FormattedMessage id="OPV Bonus" />:
                        </p>
                      </td>
                      <td>
                        <p>
                          {isNumber(investmentDetail?.bonus_token) ? (
                            <CurrencyFormat
                              value={roundNumber(investmentDetail?.bonus_token, { decimals: 18 })}
                              displayType="text"
                              thousandSeparator
                              suffix={` OPV`}
                              renderText={(t) => t}
                            />
                          ) : (
                            <>--</>
                          )}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="info-des-rewards">
                  <FormattedMessage id="Receiving 8% in the first month, the remaining will be getting 3% in the following months until enough 100%." />
                </div>
              </div>
            </Col>
          </Row>
          <div className="invest-detail-wrapper-attribute">
            <div className="invest-attribute">
              <div className="attribute-menu-wrapper">
                <Menu defaultOpenKeys={['sub1']} mode="inline">
                  <SubMenu
                    key="sub1"
                    title={
                      <span>
                        <FormattedMessage id="Investment Process" />
                        <svg
                          className="arrow-down"
                          width="13"
                          height="10"
                          viewBox="0 0 13 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.31516 9.35284C6.91651 9.91385 6.08349 9.91385 5.68484 9.35284L0.69398 2.32924C0.223493 1.66713 0.696888 0.75 1.50914 0.75L11.4909 0.749999C12.3031 0.749999 12.7765 1.66713 12.306 2.32924L7.31516 9.35284Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    }
                  >
                    <Menu.Item key="1" className="child-item">
                      <div className="process-check active " />
                      <FormattedMessage id="New SAFT generated" />
                    </Menu.Item>
                    <Menu.Item key="2" className="child-item">
                      <div className={`process-check ${investmentDetail?.admin_sign_signature ? 'active' : ''}`} />
                      <FormattedMessage id="OPV Signed" />
                    </Menu.Item>
                    <Menu.Item key="3" className="child-item">
                      <div className={`process-check ${investmentDetail?.customer_sign_signature ? 'active' : ''}`} />
                      <FormattedMessage id="Investor Signed (Please" />
                      {investmentDetail?.admin_sign_signature ? (
                        <Link to={`/investment/sign-contract/${investmentDetail?._id}`}>
                          <FormattedMessage id="click here" />
                        </Link>
                      ) : (
                        <a style={{ cursor: 'not-allowed' }}>
                          <FormattedMessage id="click here" />
                        </a>
                      )}
                      <FormattedMessage id="to Sign!)" />
                    </Menu.Item>
                    <Menu.Item key="4" className="child-item">
                      <div
                        className={`process-check ${
                          investmentDetail?.admin_sign_signature && investmentDetail?.customer_sign_signature ? 'active' : ''
                        }`}
                      />
                      <FormattedMessage id="SAFT Completed" />

                      {investmentDetail?.link_contract && (
                        <a href={investmentDetail.link_contract} target="_blank" rel="noreferrer">
                          <img src="/images/icons/download-pdf.png" alt="" />
                        </a>
                      )}
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              </div>
            </div>
            <div className="invest-attribute invest-property">
              <div className="attribute-menu-wrapper attribute-menu-wrapper-active">
                <Menu defaultOpenKeys={['sub1']} mode="inline">
                  <SubMenu
                    key="sub1"
                    title={
                      <span>
                        <FormattedMessage id="Properties" />
                        <svg
                          className="arrow-down"
                          width="13"
                          height="10"
                          viewBox="0 0 13 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.31516 9.35284C6.91651 9.91385 6.08349 9.91385 5.68484 9.35284L0.69398 2.32924C0.223493 1.66713 0.696888 0.75 1.50914 0.75L11.4909 0.749999C12.3031 0.749999 12.7765 1.66713 12.306 2.32924L7.31516 9.35284Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    }
                  >
                    <Menu.Item key="1" className=" property-child property-child-item">
                      <div className="child-item">
                        <FormattedMessage id="Category" />:
                      </div>
                      <div className="child-item">
                        <FormattedMessage id="Action Card" />
                      </div>
                    </Menu.Item>
                    <Menu.Item key="2" className="property-child property-child-item">
                      <div className="child-item">
                        <FormattedMessage id="Identification" />:
                      </div>
                      <div className="child-item">
                        <FormattedMessage id="Admin" />
                      </div>
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <div className="invest-detail-wrapper-activites">
          {/* <TransactionNftActivities /> */}
          {/* <PaginationCustomer page={params2.page} pageSize={params2.pageSize} total={50} params={params2} setParams={setParams} /> */}
        </div>
      </div>
    </div>
  )
}

export default MyInvestmentDetail
