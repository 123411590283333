import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './style.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { resendVerifyWithdrawCode } from 'state/wallets/actions'
import { FormattedMessage } from 'react-intl'
import { toast } from 'react-toastify'

/**
 * @dev Required body data by resend action
 * Type === login => required body data { transaction_id }
 * Type === register => required body data { code }
 */
const ResendCode = ({ type, countDown, data, className, onPaste, ...props }) => {
  const dispatch = useDispatch()

  const [timeCountDown, setTimeCountDown] = useState(countDown)

  const resendCode = () => {
    switch (type) {
      case 'login':
        executeRecaptcha((recaptcha) => {
          dispatch(
            resendVerifyWithdrawCode(
              {
                transaction_id: data._id,
                recaptcha,
              },
              () => {
                setTimeCountDown(50)
              },
            ),
          )
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  const handlePaste = async () => {
    if (navigator.clipboard) {
      onPaste(await navigator.clipboard.readText())
    } else {
      toast.error('Paste is not support in browser')
    }
  }

  return (
    <div className={`resend-code ${className}`} {...props}>
      {timeCountDown > 0 ? (
        <p className="coundown-send-code">Resend in {timeCountDown}</p>
      ) : (
        <p className="coundown-send-code" onClick={resendCode} role="presentation" style={{ cursor: 'pointer' }}>
          Resend
        </p>
      )}

      <p onClick={handlePaste} role="presentation">
        <FormattedMessage id="Paste" />
      </p>
    </div>
  )
}

ResendCode.defaultProps = {
  type: 'login',
  className: '',
  countDown: 50,
  onPaste: () => null,
}

export default React.memo(ResendCode)
