import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import { getListF1 } from 'state/networks/actions'
import RefBinaryChildItem from 'modules/NetWork/RefBinaryChildItem'
import { Link } from 'react-router-dom'
// import { getLevelCustomer } from 'state/package/actions'

const RefChild = () => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.user)
  const [dataTree, setDataTree] = React.useState([])
  const [showTree, setShowTree] = React.useState(true)
  const { levelCustomerList } = useSelector((state) => state.package)

  useEffect(() => {
    if (userInfo?.profile?.customer_id) {
      dispatch(
        getListF1(
          {
            customer_id: userInfo?.profile.customer_id,
          },
          (res) => {
            setDataTree(res)
          },
          () => {
            setDataTree(null)
          },
        ),
      )
    }
  }, [dispatch, userInfo?.profile.customer_id])

  // useEffect(() => {
  //   dispatch(getLevelCustomer())
  // }, [dispatch])

  return (
    <>
      <div className="tree-root">
        <div className="tree-root-tree">
          <div className="tree-root-name">
            <span className="tree-root-name-left">
              <img
                className="tree-root-name-img-plus"
                onClick={() => {
                  if (dataTree !== null) {
                    setShowTree((prev) => !prev)
                  }
                }}
                role="presentation"
                src={showTree ? '/images/referals/hideArrow.png' : !showTree && '/images/referals/showArrow.png'}
                alt=""
              />
              <Link className="tree-root-parent-info" to={`/network/detail/${userInfo?._id}`}>
                <span>{userInfo?.email}</span>
              </Link>
            </span>
          </div>
          {showTree && dataTree && (
            <div className="tree-root-children">
              {dataTree.map((item) => (
                <RefBinaryChildItem levelCustomerList={levelCustomerList} key={item?.email} treeInfo={item} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default RefChild
