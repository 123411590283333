import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './styles.scss'
import { Form } from 'antd'
import FormItem from 'widgets/FormItem'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { forgotPassword, requestForgotPassword } from 'state/auth/actions'
import InputCode from 'components/InputCode'
import { useHistory } from 'react-router-dom'
import Input from 'widgets/Input'
import ModalCustom from 'components/BaseModal'
import InputConfirm from 'widgets/InputConfirm'
import { FormattedMessage } from 'react-intl'

const defaultTimeCountDown = 50

const ChangePassword = ({ intl, visible, close, email, initialField, timeResend, setTimeResend }) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(defaultTimeCountDown)
  const [show, setShow] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const onSubmit = () => {
    form.validateFields().then((values) => {
      if (codeValue.length < initialField && typeof codeValue !== 'number') {
        setErrorMess(
          <>
            <FormattedMessage id="Please enter" /> {initialField} <FormattedMessage id="characters from email of you." />
          </>,
        )
      } else {
        setErrorMess('')
        setLoading(true)

        executeRecaptcha((recaptcha) => {
          dispatch(
            forgotPassword(
              {
                ...values,
                email: `${email}`,
                code: codeValue,
                recaptcha,
              },
              () => {
                history.push('/login')
                setLoading(false)
              },
              () => {
                setLoading(false)
              },
            ),
          )
        })
      }
    })
  }
  const onChangeCode = (value) => {
    setCodeValue(value)
  }

  const resendCode = () => {
    executeRecaptcha((recaptcha) => {
      dispatch(
        requestForgotPassword(
          {
            email: `${email}`,
            recaptcha,
          },
          () => {
            if (setTimeResend) {
              setTimeResend(defaultTimeCountDown)
              setTimeCountDown(defaultTimeCountDown)
            } else {
              setTimeCountDown(defaultTimeCountDown)
            }
          },
        ),
      )
    })
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  useEffect(() => {
    if (timeResend && timeResend > 0) {
      setTimeCountDown(timeResend)
    }
  }, [timeResend])
  const CloseModal = () => {
    close(false)
  }
  return (
    <>
      <ModalCustom
        className="form-change-password"
        title={
          <div className="form-change-password-heading">
            <FormattedMessage id="Change Password" />
          </div>
        }
        centered
        visible={Boolean(visible)}
        width={window.innerWidth <= 768 ? '100vw' : 580}
        height={window.innerWidth <= 768 ? '100%' : 550}
        onCancel={CloseModal}
      >
        <div className="modal-scan-action-bg" />
        <Form layout="vertical" form={form}>
          <FormItem
            label={
              <span>
                <FormattedMessage id="New Password" />
              </span>
            }
            name="new_password"
            rules={[
              {
                required: true,
                message: <FormattedMessage id="Please enter new password field" />,
              },
              {
                min: 10,
                message: (
                  <FormattedMessage id={`The 'password' field length must be greater than or equal to 10 characters long.`} />
                ),
              },
            ]}
          >
            <Input placeholder="************" type={show ? 'text' : 'password'} eyePassWord show={show} setShow={setShow} />
          </FormItem>

          <FormItem
            label={
              <span>
                <FormattedMessage id="Confirm Password" />
              </span>
            }
            name="confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: <FormattedMessage id="Please confirm your password!" />,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'))
                },
              }),
            ]}
          >
            <InputConfirm
              placeholder="************"
              type={showConfirm ? 'text' : 'password'}
              eyePassWord
              showConfirm={showConfirm}
              setShowConfirm={setShowConfirm}
            />
          </FormItem>
          <FormItem
            style={{ marginTop: '20px' }}
            label={
              <span>
                <FormattedMessage id="Verification code" />
              </span>
            }
            rules={[
              {
                required: true,
                message: (
                  <>
                    <FormattedMessage id="Please enter" /> {initialField} <FormattedMessage id="characters from email of you." />
                  </>
                ),
              },
            ]}
          >
            <div className="verify-input">
              {/* <p>Verification code</p> */}
              <InputCode
                value={codeValue}
                initialField={initialField}
                hasErrored={Boolean(errorMess)}
                onChange={onChangeCode}
                type="number"
              />
              <div className="verify-resend">
                <div className="time-count" style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>
                  <FormattedMessage id="Resend in" /> {timeCountDown}s
                </div>
                {timeCountDown <= 0 ? (
                  <a onClick={() => resendCode()} role="presentation">
                    <FormattedMessage id="Resend" />
                  </a>
                ) : (
                  <a
                    role="presentation"
                    onClick={async () => {
                      onChangeCode(await navigator.clipboard.readText())
                    }}
                  >
                    <FormattedMessage id="Paste" />
                  </a>
                )}
              </div>
              <div className="verify-error">{errorMess}</div>
            </div>
          </FormItem>
          <div className="verify-action-change-passwrord" style={{ textAlign: 'center' }}>
            <ButtonPrimary loading={loading} onClick={onSubmit}>
              <FormattedMessage id="CHANGE" />
            </ButtonPrimary>
          </div>
        </Form>
      </ModalCustom>
    </>
  )
}

ChangePassword.defaultProps = {
  onFinish: () => null,
  onBack: () => null,
  initialField: 6,
}

export default React.memo(ChangePassword)
