export const APP_URL = process.env.REACT_APP_USER_URL

// Root config
export const ROOT_API_URL = process.env.REACT_APP_USER_API
export const G_SITE_KEY = process.env.REACT_APP_GOOGLE_SITE_KEY

/**
 * Network
 */
export const DECIMALS = 18

/**
 * @dev Router
 * Path default config for redirect page
 */
export const HOME_PATH = '/'
export const LOGIN_PATH = '/login'

/**
 * @dev
 * Cache at locale global
 */
export const CACHE_TOKEN = 'OpenLive'
export const CACHE_USER_INFO = 'live_us'
export const CACHE_CHAIN_LIST = 'chain_list'

/**
 * STATUS PROCESSING
 */

export const STATUS_TRANSACTION = {
  FAIL: 'FAIL',
  COMPLETED: 'COMPLETED',
}

export const PREDICTION_TOOLTIP_DISMISS_KEY = 'prediction-switcher-dismiss-tooltip'
