import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 50 50" fill="transparent" {...props}>
    <path
      d="M26.3067 12.8125C26.3063 12.4192 26.434 12.0364 26.6705 11.7221C26.9069 11.4078 27.2393 11.179 27.6174 11.0704C27.9954 10.9618 28.3985 10.9792 28.7657 11.1201C29.133 11.2609 29.4444 11.5175 29.6529 11.851L34.52 16.7182C34.6881 16.8864 34.8214 17.0861 34.9124 17.3058C35.0033 17.5256 35.0501 17.7611 35.05 17.999C35.0499 18.2368 35.003 18.4723 34.9119 18.692C34.8208 18.9117 34.6873 19.1113 34.5191 19.2794C34.3509 19.4475 34.1512 19.5808 33.9314 19.6718C33.7116 19.7627 33.4761 19.8095 33.2383 19.8094C33.0005 19.8093 32.765 19.7624 32.5453 19.6713C32.3256 19.5802 32.126 19.4467 31.9579 19.2785L29.9299 17.2487V30.9194C29.9299 31.3996 29.7391 31.8602 29.3996 32.1997C29.06 32.5393 28.5994 32.7301 28.1192 32.7301C27.639 32.7301 27.1784 32.5393 26.8389 32.1997C26.4993 31.8602 26.3085 31.3996 26.3085 30.9194V12.8125H26.3067ZM22.7433 38.1875C22.7437 38.5808 22.616 38.9636 22.3795 39.2779C22.143 39.5922 21.8107 39.821 21.4326 39.9296C21.0546 40.0382 20.6515 40.0208 20.2842 39.8799C19.917 39.7391 19.6056 39.4825 19.3971 39.149L14.53 34.2818C14.1905 33.9421 13.9998 33.4814 14 33.001C14.0002 32.5207 14.1911 32.0601 14.5309 31.7206C14.8707 31.3811 15.3314 31.1905 15.8117 31.1906C16.292 31.1908 16.7526 31.3818 17.0921 31.7215L19.1201 33.7513V20.0806C19.1201 19.6004 19.3109 19.1398 19.6504 18.8003C19.99 18.4607 20.4506 18.2699 20.9308 18.2699C21.411 18.2699 21.8716 18.4607 22.2111 18.8003C22.5507 19.1398 22.7415 19.6004 22.7415 20.0806V38.1875H22.7433Z"
      fill="#F8F8F8"
    />
  </Svg>
)

export default Icon
