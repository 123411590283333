import React from 'react'
import TableWrapper from 'components/TableWrapper'
import { FormattedMessage } from 'react-intl'
import './styles.scss'
import { formatCode, formatDate, formatAmount } from 'helpers'
import { getBlockExploreLink } from 'utils'
import { DECIMALS } from 'constants/index'
import TxStatus from 'components/TxStatus'
import TxAmount from 'components/TxAmount'

const TableWalletHistoryDesktop = ({
  total,
  dataSource,
  txParams,
  setTxParams,
  setViewDetails,
  setModalVerifyCode,
  ...props
}) => {
  const columns = [
    {
      title: <FormattedMessage id="Amount" />,
      dataIndex: 'amount',
      width: 100,
      render: (text, record) => (
        <TxAmount
          className="recent-transaction-table-amount"
          action={record.action}
          title={formatAmount(text, { decimals: DECIMALS })}
        />
      ),
    },
    {
      title: (
        <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <FormattedMessage id="Currency" />
        </div>
      ),
      dataIndex: 'currency',
      width: 100,
      render: (item, record) => (
        <div className="coin-data" style={{ justifyContent: 'center', display: 'flex' }}>
          {record.icon && <img src={record.icon} alt="" />}
          <div className="coin-data-label">
            <div className="data-label-text">{item}</div>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
          <FormattedMessage id="Action" />
        </div>
      ),
      dataIndex: 'action',
      width: 100,
      render: (text) => (
        <p style={{ textAlign: 'center', maxWidth: '100px', whiteSpace: 'nowrap' }} className="recent-transaction-table-action">
          {text}
        </p>
      ),
    },
    {
      title: (
        <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <FormattedMessage id="Network" />
        </div>
      ),
      dataIndex: 'chain',
      width: 100,
      render: (text) => (
        <p
          className="recent-transaction-table-network"
          style={{ justifyContent: 'center', display: 'flex', whiteSpace: 'nowrap' }}
        >
          {text}
        </p>
      ),
    },
    {
      title: (
        <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
          <FormattedMessage id="Status" />
        </div>
      ),
      dataIndex: 'status',
      width: 120,
      render: (text) => (
        <div className="recent-transaction-table-status">
          <TxStatus status={text} title={text} />
        </div>
      ),
    },
    {
      title: <div style={{ textAlign: 'center' }}>Txh</div>,
      dataIndex: 'txhash',
      width: 120,
      render: (text, record) => {
        if (`${text}`.length > 60) {
          return (
            <a
              className="recent-transaction-table-txh"
              href={getBlockExploreLink(text, 'transaction', record.chain)}
              target="_blank"
              rel="noreferrer"
            >
              {formatCode(text, 5, 5).toLowerCase()}
            </a>
          )
        }
        return <p className="recent-transaction-table-txh">{formatCode(text, 5, 5).toLowerCase()}</p>
      },
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <FormattedMessage id="Time" />
        </div>
      ),
      dataIndex: 'createdAt',
      width: 120,
      render: (text) => <p className="recent-transaction-table-time">{formatDate(text * 1000, 'YYYY/MM/DD hh:mm')}</p>,
    },
    {
      // title: (
      //   <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
      //     <FormattedMessage id="Actions" />
      //   </div>
      // ),
      key: 'operation',
      width: 100,
      render: (_, record) => {
        if (record.status !== 'CREATED') {
          return (
            <a
              className="recent-transaction-table-actions"
              role="presentation"
              onClick={() => setViewDetails({ toggle: true, dataModal: record })}
            >
              <FormattedMessage id="View" />
            </a>
          )
        }
        return (
          <a className="recent-transaction-table-actions" role="presentation" onClick={() => setModalVerifyCode(record)}>
            <FormattedMessage id="Verify" />
          </a>
        )
      },
    },
  ]
  return (
    <div className="wallet-transaction-history">
      <TableWrapper
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record._id}
        pagination={{
          total,
          current: txParams?.page,
          onChange: (page, pageSize) =>
            setTxParams((prev) => ({
              ...prev,
              page,
              pageSize,
            })),
        }}
        {...props}
      />
    </div>
  )
}

export default TableWalletHistoryDesktop
