export const GET_INVESTMENT_LIST = 'GET_INVESTMENT_LIST'
export const GET_INVESTMENT_LIST_SUCCESS = 'GET_INVESTMENT_LIST_SUCCESS'

export const GET_INVESTMENT_DETAIL = 'GET_INVESTMENT_DETAIL'
export const GET_INVESTMENT_DETAIL_SUCCESS = 'GET_INVESTMENT_DETAIL_SUCCESS'

export const GET_HISTORY_INVESTMENT = 'GET_HISTORY_INVESTMENT'
export const GET_HISTORY_INVESTMENT_SUCCESS = 'GET_HISTORY_INVESTMENT_SUCCESS'

export const INVEST_BUY_NFT = 'INVEST_BUY_NFT'
export const SIGN_INVEST = 'SIGN_INVEST'
export const SUBMIT_SAFT = 'SUBMIT_SAFT'
