import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import './styles.scss'
import { Col, Row } from 'antd'
import InputPanel from '../InputPanel'
import { useDerivedExchangeInfo, useExchangeState } from 'state/exchange/hooks'
import { Field } from 'state/exchange/const'
import { ArrowUpDownIcon } from 'widgets/Svg'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { useSwapActionHandlers } from 'state/exchange/useSwapActionHandlers'
import ModalExchange from '../ModalExchange'
import { roundNumber } from 'helpers'
import { DECIMALS } from 'constants/index'

const CardExchange = ({ setParamsTxs }) => {
  const { currency } = useSelector((state) => state.wallets)

  const optionsFromCurrency = useMemo(() => {
    if (currency) {
      return currency
        .filter((o) => ['OP', 'OPV'].includes(o.code))
        .map((item) => ({
          label: item.title,
          value: item.code,
          image: item.icon,
        }))
    }
    return undefined
  }, [currency])

  const [errorMess, setErrorMess] = useState('')
  const [modalExchange, setModalExchange] = useState({ open: false, dataModal: null })

  const { onSwitchTokens, onCurrencySelection, onUserInput } = useSwapActionHandlers()

  // swap state & price data
  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useExchangeState()
  const dependentField = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  // here calculate amountOutput
  const { currencies, outputAmount, inputError } = useDerivedExchangeInfo(
    typedValue,
    independentField,
    inputCurrencyId,
    outputCurrencyId,
  )

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: outputAmount ?? '',
    }),
    [dependentField, independentField, outputAmount, typedValue],
  )

  // handle exchange now
  const handleExchange = useCallback(() => {
    if (!inputCurrencyId || !outputCurrencyId) {
      setErrorMess('Please select a token')
      return
    }
    if (!typedValue) {
      setErrorMess('Please enter amount')
      return
    }
    if (!outputAmount) {
      setErrorMess('Insuffix amount')
      return
    }

    setErrorMess('')
    setModalExchange({
      open: true,
      dataModal: {
        inputCurrencyId,
        outputCurrencyId,
        formattedAmounts,
      },
    })
  }, [inputCurrencyId, outputCurrencyId, typedValue, outputAmount, formattedAmounts])

  // user select token
  const handleInputSelect = useCallback(
    (currencyInput) => {
      const fCurrency = currency?.find((curr) => curr.code === currencyInput)
      onCurrencySelection(Field.INPUT, fCurrency)
    },
    [onCurrencySelection, currency],
  )
  const handleOutputSelect = useCallback(
    (currencyOutput) => {
      const fCurrency = currency?.find((curr) => curr.code === currencyOutput)
      onCurrencySelection(Field.OUTPUT, fCurrency)
    },
    [onCurrencySelection, currency],
  )

  // user input, output amount
  const handleTypeInput = useCallback(
    (value) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  // max user click
  const handleMaxInput = useCallback(
    (wallet) => {
      if (wallet) {
        onUserInput(Field.INPUT, roundNumber(wallet.balance / 10 ** DECIMALS, 6))
      }
    },
    [onUserInput],
  )
  // const handleMaxOutput = useCallback(
  //   (wallet) => {
  //     if (wallet) {
  //       onUserInput(Field.OUTPUT, roundNumber(wallet.balance / 10 ** DECIMALS, 6))
  //     }
  //   },
  //   [onUserInput],
  // )

  /* set default select if option is valid */
  useEffect(() => {
    if (inputCurrencyId && outputCurrencyId) return // break out if input is select
    handleInputSelect('OP')
    handleOutputSelect('OPV')
  }, [handleInputSelect, handleOutputSelect, inputCurrencyId, outputCurrencyId])

  return (
    <div className="card-exchange">
      <Row
        gutter={[
          { xs: 4, md: 18 },
          { xs: 4, md: 18 },
        ]}
      >
        <Col span={24}>
          <InputPanel
            title="From currency"
            value={formattedAmounts[Field.INPUT]}
            isError={!!errorMess}
            disabledSelect
            walletsList={currency}
            optionsSelect={optionsFromCurrency}
            currency={currencies[Field.INPUT]}
            otherCurrency={currencies[Field.OUTPUT]}
            onMax={handleMaxInput}
            onUserInput={handleTypeInput}
            onCurrencySelect={handleInputSelect}
          />
        </Col>
        <Col span={24}>
          <div className="exchange-swap-icon">
            <ArrowUpDownIcon
              className="icon-down"
              // onClick={onSwitchTokens}
            />
          </div>
        </Col>
        <Col span={24}>
          <InputPanel
            title="To currency"
            value={formattedAmounts[Field.OUTPUT]}
            isError={!!errorMess}
            disabledSelect
            walletsList={currency}
            optionsSelect={optionsFromCurrency}
            currency={currencies[Field.OUTPUT]}
            otherCurrency={currencies[Field.INPUT]}
            onUserInput={handleTypeOutput}
            onCurrencySelect={handleOutputSelect}
          />
        </Col>
        <Col span={24}>
          <div className="exchange-swap-action">
            <ButtonPrimary onClick={handleExchange}>{inputError || 'Exchange'}</ButtonPrimary>
          </div>
        </Col>
      </Row>
      <ModalExchange
        visible={modalExchange.open}
        dataModal={modalExchange.dataModal}
        setModal={setModalExchange}
        setParamsTxs={setParamsTxs}
      />
    </div>
  )
}

export default CardExchange
