import React from 'react'
import './styles.scss'
import { formatCode, formatDate, isNumber, roundNumber } from 'helpers/index'
import { FormattedMessage } from 'react-intl'
import CurrencyFormat from 'react-currency-format'
import TxAmount from 'components/TxAmount'
import TxStatus from 'components/TxStatus'

const ItemInvestmentTransaction = ({ historyItem }) => {
  if (!historyItem) return <></>
  return (
    <div className="recent-transaction-dashboard">
      <div>
        <p>
          <FormattedMessage id="Action" />
        </p>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {historyItem.action} <img style={{ width: '32px', marginLeft: '5px' }} src="/images/icons/download-pdf.png" alt="" />
        </div>
      </div>
      <div>
        <p>
          <FormattedMessage id="Amount" />
        </p>
        <p>
          {isNumber(+historyItem?.amount) ? (
            <TxAmount
              action={historyItem.action}
              title={
                <CurrencyFormat
                  value={roundNumber(historyItem?.amount, { decimals: 18 })}
                  displayType="text"
                  thousandSeparator
                  renderText={(t) => t}
                />
              }
            />
          ) : (
            '--'
          )}
        </p>
      </div>
      <div>
        <p>
          <FormattedMessage id="Package" />
        </p>
        <p>{formatCode(historyItem._id, 4, 4)}</p>
      </div>
      <div>
        <p>
          <FormattedMessage id="Status" />
        </p>
        <p>
          <TxStatus status={historyItem.status} title={historyItem.status} />
        </p>
      </div>
      <div>
        <p>
          <FormattedMessage id="Time" />
        </p>
        <p>{historyItem.createdAt ? formatDate(historyItem.createdAt) : ''} </p>
      </div>
    </div>
  )
}

export default ItemInvestmentTransaction
