export const DEFAULT_ACTIVE_CHAIN = 56

export const ChainId = {
  BSC: 56,
  BSC_TESTNET: 97,
}

export const BASE_SCAN_URLS = {
  [ChainId.BSC]: 'https://bscscan.com',
  [ChainId.BSC_TESTNET]: '',
}

export const RPC_URLS = {
  [ChainId.BSC]: ['https://bsc-dataseed.binance.org', 'https://bsc-dataseed1.defibit.io', 'https://bsc-dataseed1.ninicoin.io'],
  [ChainId.BSC_TESTNET]: [''],
}

export const WNATIVE = {
  [ChainId.BSC]: {},
  [ChainId.BSC_TESTNET]: {},
}

export const CHAIN_LIST = {
  [ChainId.BSC]: {
    name: 'BNB Smart Chain Mainnet',
    chainId: ChainId.BSC,
    network: 'pulse',
    rpcUrls: {
      default: RPC_URLS[ChainId.BSC][0],
      public: RPC_URLS[ChainId.BSC],
    },
    scanURL: BASE_SCAN_URLS[ChainId.BSC],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18,
    },
    wNativeCurrency: WNATIVE[ChainId.BSC],
    // routerAddress: ROUTER_ADDRESS_MAP[ChainId.BSC],
    // factoryAddress: FACTORY_ADDRESS_MAP[ChainId.BSC],
    // multicall: {
    //   address: MULTICALL_ADDRESS_MAP[ChainId.BSC],
    //   blockCreated: MULTICALL_BLOCK_CREATE_MAP[ChainId.BSC],
    //   INIT_CODE_HASH: INIT_CODE_HASH_MAP[ChainId.BSC],
    // },
  },
  [ChainId.BSC_TESTNET]: {
    name: 'BNB Smart Chain Testnet',
    chainId: ChainId.BSC_TESTNET,
    network: 'pulse',
    isMainnet: true,
    rpcUrls: {
      default: RPC_URLS[ChainId.BSC_TESTNET][0],
      public: RPC_URLS[ChainId.BSC_TESTNET],
    },
    scanURL: BASE_SCAN_URLS[ChainId.BSC_TESTNET],
    nativeCurrency: {
      name: 'PULSE',
      symbol: 'PULSE',
      decimals: 18,
    },
    wNativeCurrency: WNATIVE[ChainId.BSC_TESTNET],
    // routerAddress: ROUTER_ADDRESS_MAP[ChainId.BSC_TESTNET],
    // factoryAddress: FACTORY_ADDRESS_MAP[ChainId.BSC_TESTNET],
    // multicall: {
    //   address: MULTICALL_ADDRESS_MAP[ChainId.BSC_TESTNET],
    //   blockCreated: MULTICALL_BLOCK_CREATE_MAP[ChainId.BSC_TESTNET],
    //   INIT_CODE_HASH: INIT_CODE_HASH_MAP[ChainId.BSC_TESTNET],
    // },
  },
}
