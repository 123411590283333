/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Col, Row, Form, DatePicker } from 'antd'
import { countryCode } from 'emoji-flags'
import './styles.scss'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import FormItem from 'widgets/FormItem'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { regexPhone } from '../../utils/Regex'
import PointSecurity from 'components/PointSecurity'
import { getListCountry } from 'state/app/actions'
import { getProfile, updateProfile } from 'state/user/actions'
import { requestForgotPassword } from 'state/auth/actions'
import { uploadFile } from 'state/file/actions'
import { getBase64, checkDevice } from 'helpers'
import { CheckUnderLine, EditUnderLineIcon } from 'widgets/Svg'
import ChangePassword from './ChangePassword'
import { FormattedMessage, injectIntl } from 'react-intl'
import InputSelect from 'components/InputSelect'

const Account = ({ intl }) => {
  const dispatch = useDispatch()

  const [, setLoading] = useState(false)

  const [view, setView] = useState(0)
  const [name, setName] = useState(false)
  const [form] = Form.useForm()
  const { userInfo } = useSelector((state) => state.user)
  const { listCountry } = useSelector((state) => state.app)
  const [inputName, setInputName] = useState(`${userInfo?.profile?.fistname} ${userInfo?.profile?.lastname}`)
  const [inputFirstName, setInputFirstName] = useState(`${userInfo?.profile?.fistname}`)
  const [inputLastName, setInputLastName] = useState(`${userInfo?.profile?.lastname}`)
  const [inputBirthday, setInputBirthday] = useState(
    `${moment(userInfo?.profile?.birthday)._d}`.toUpperCase() !== 'Invalid Date'.toUpperCase()
      ? userInfo?.profile?.birthday
      : undefined,
  )
  const [avatar, setAvatar] = useState('')
  const [timeResend, setTimeResend] = useState(0)
  const [selectCountry, setSelectCountry] = useState()

  const [viewDetails, setViewDetails] = useState({ toggle: false, dataModal: null })

  const optionsCountry = useMemo(() => {
    if (listCountry) {
      return listCountry.map((item) => {
        const countryEmoji = countryCode(item.code)
        return {
          ...item,
          label: countryEmoji && checkDevice ? `${countryEmoji?.emoji || ''} ${item.title}` : item.title,
          value: item.code,
        }
      })
    }
    return []
  }, [listCountry])

  /* set init form */
  useEffect(() => {
    if (userInfo && listCountry) {
      const defaultValues = {
        country: userInfo.profile.country,
        phone_number: userInfo.profile.phone_number,
        address: userInfo.profile.address,
        birthday: userInfo.profile.birthday,
      }
      form.setFieldsValue(defaultValues)
      setAvatar({
        image: userInfo.profile.avatar,
      })
      const fCountry = listCountry.find((country) => country.code === userInfo.profile.country)
      setSelectCountry(fCountry)
    }
  }, [form, listCountry, userInfo])

  /*  */
  const handleChangeName = (value) => {
    setInputName(value)
  }

  const handleChangeDate = (_, dateString) => {
    setInputBirthday(dateString)
    form.setFieldsValue({ birthday: moment(dateString) })
  }

  const handleChangePassword = () => {
    setViewDetails({ toggle: true, dataModal: viewDetails })
    executeRecaptcha((recaptcha) => {
      dispatch(
        requestForgotPassword(
          {
            email: userInfo.email,
            recaptcha,
          },
          () => {},
        ),
      )
    })
  }

  const handleChangeAvatar = (event) => {
    if (event.target.files && event.target.files[0]) {
      getBase64(event.target.files[0]).then(() => {
        dispatch(
          uploadFile(
            {
              key: 1,
              image: event.target.files[0],
            },
            (receipt) => {
              setAvatar({
                ...receipt,
                image: receipt.full_link,
              })
            },
          ),
        )
      })
    }
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeResend((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  /* Fetch country list */
  useEffect(() => {
    dispatch(getListCountry())
  }, [dispatch])

  /* Submit form */
  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      if (!selectCountry) return
      setLoading(true)

      executeRecaptcha((recaptcha) => {
        dispatch(
          updateProfile(
            {
              ...values,
              fistname: inputFirstName,
              lastname: inputLastName,
              birthday: moment(values.birthday).format('YYYY-MM-DD'),
              phone_code: selectCountry?.phone_code,
              avatar: avatar._id,
              recaptcha,
            },
            () => {
              dispatch(getProfile())
              setView(0)
              setLoading(false)
            },
            () => {
              setLoading(false)
            },
          ),
        )
      })
    })
  }

  if (!userInfo) return <></>
  return (
    <>
      {view === 0 ? (
        <div className="container warp-page-account">
          <div className="page-account-content">
            <div className="box-avatar">
              <div className="avatar-content-left">
                <div className="avatar-image">
                  <img src={avatar?.image ? `${avatar.image}` : '/images/user-default.png'} alt="" />

                  <label htmlFor="change_avatar">
                    <input
                      id="change_avatar"
                      accept="image/*"
                      type="file"
                      disabled
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        handleChangeAvatar(e)
                      }}
                    />
                  </label>
                </div>
                <span className="wrapper-info">
                  <div className="avatar-info">
                    <p>
                      {name && view === 1 ? (
                        <input autoFocus defaultValue={inputName} onChange={(event) => handleChangeName(event.target.value)} />
                      ) : (
                        <>
                          {userInfo?.profile?.fistname} {userInfo?.profile?.lastname}
                        </>
                      )}
                    </p>

                    <p>{userInfo.email}</p>
                  </div>
                </span>
              </div>
              <div className="avatar-content-right">
                <ButtonPrimary
                  style={{
                    padding: '0 16px',
                    width: '120px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                  }}
                  onClick={() => setView(1)}
                >
                  <EditUnderLineIcon />
                  <span>
                    <FormattedMessage id="Edit" />
                  </span>
                </ButtonPrimary>
              </div>
            </div>

            {/* <ReferrerCard className="ref-view" userInfo={userInfo} /> */}

            <div className="box-info-input">
              <Form layout="vertical" form={form}>
                <Row gutter={[{ sm: 15, md: 32, lg: 57 }]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <div className="input-width-pen">
                      <div className="box-input">
                        <FormItem
                          label={
                            <span>
                              <FormattedMessage id="Country" />
                            </span>
                          }
                          name="country"
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="Please enter country field" />,
                            },
                          ]}
                        >
                          <InputSelect style={{ width: '100%' }} options={optionsCountry} disabled showArrow={false} />
                        </FormItem>
                      </div>
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <div className="input-width-pen">
                      <div className="box-input">
                        <FormItem
                          label={
                            <span>
                              <FormattedMessage id="Phone Number" />
                            </span>
                          }
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="Please enter phone number field" />,
                            },
                            { pattern: regexPhone, message: <FormattedMessage id="Invalid Phone number" /> },
                          ]}
                        >
                          <input
                            className="input-phone"
                            disabled
                            id="phone-number"
                            placeholder="Phone Number"
                            style={{ paddingLeft: selectCountry ? '72px' : '20px' }}
                          />
                        </FormItem>
                        {selectCountry && <span className="code-phone">{selectCountry?.phone_code}</span>}
                      </div>
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <div className="input-width-pen">
                      <div className="box-input">
                        <FormItem
                          label={
                            <span>
                              <FormattedMessage id="Address" />
                            </span>
                          }
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Address field',
                            },
                          ]}
                        >
                          <input disabled id="address" placeholder={intl?.formatMessage({ id: 'address' })} />
                        </FormItem>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="input-width-pen">
                      <div className="box-input">
                        <FormItem
                          label={
                            <span>
                              <FormattedMessage id="Birthday" />
                            </span>
                          }
                          name="birthday"
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="Please enter Birthday field" />,
                            },
                          ]}
                        >
                          <div style={{ position: 'relative' }}>
                            <DatePicker
                              disabled
                              value={moment(inputBirthday)}
                              style={{ width: '100%', padding: '0', borderRadius: '10px' }}
                            />
                          </div>
                        </FormItem>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>

            <div className="box-info-password">
              <div className="password-left">
                <p>
                  <FormattedMessage id="Password" />
                </p>
                <p>
                  <FormattedMessage id="Increase your password strength to enhance account security" />
                </p>
              </div>
            </div>
            <div className="box-rate-sercurity">
              <Link to="/wallet">
                <div className="rate-sercurity-item">
                  <div className="rate-sercurity-item-name">
                    <img src="/images/account/wallet.png" alt="Wallet" />
                    <p>
                      <FormattedMessage id="Wallet" />
                    </p>
                  </div>
                  <div className="rate-sercurity-item-description">
                    <p>
                      <FormattedMessage id="Your assets will be safely kept here and the multi-chain makes it easy to use." />
                    </p>
                  </div>
                </div>
              </Link>

              <Link to="/profile/security">
                <div className="rate-sercurity-item">
                  <div className="rate-sercurity-item-name">
                    <img src="/images/account/shield.png" alt="Security" />
                    <p>
                      <FormattedMessage id="Security" />
                    </p>
                  </div>
                  <div className="rate-sercurity-item-description">
                    <p>
                      <FormattedMessage id="Account risk level:" />
                      {userInfo?.status_2fa === 2 || userInfo?.status_2fa === 0 ? (
                        <PointSecurity point={3} />
                      ) : !userInfo?.status_kyc ? (
                        <PointSecurity point={4} />
                      ) : (
                        <PointSecurity point={5} />
                      )}
                    </p>
                  </div>
                </div>
              </Link>
              <div
                className="btn-edit-mobile"
                style={{ display: window.innerWidth >= 991 ? 'none' : 'flex', textAlign: 'center' }}
              >
                <ButtonPrimary onClick={() => setView(1)} timeresend={timeResend}>
                  <FormattedMessage id="Edit" />
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container warp-page-account">
          <div className=" page-account-content">
            <div className="box-avatar">
              <div className="avatar-content-left">
                <div className="avatar-image">
                  <img src={avatar?.image ? `${avatar.image}` : '/images/user-default.png'} alt="" />
                  <label htmlFor="change_avatar">
                    <input
                      id="change_avatar"
                      accept="image/*"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        handleChangeAvatar(e)
                      }}
                    />
                    <img
                      src={window.innerWidth < 768 ? '/images/icons/camera-mobile.png' : '/images/icons/icon-edit-avatar.png'}
                      alt=""
                      role="presentation"
                    />
                  </label>
                </div>
                <span className="wrapper-info">
                  <div className="avatar-info  avatar-info-edit">
                    <p className={`${!(name && view === 1) ? 'edit' : ''}`}>
                      {name && view === 1 ? (
                        inputName
                      ) : (
                        <>
                          <input
                            className="input-first-name"
                            autoFocus
                            defaultValue={inputFirstName}
                            onChange={(event) => setInputFirstName(event.target.value)}
                          />
                          {window.innerWidth < 768 && '|'}
                          <input
                            autoFocus
                            defaultValue={inputLastName}
                            onChange={(event) => setInputLastName(event.target.value)}
                          />
                        </>
                      )}
                      <img
                        src="/images/icons/icon-edit.png"
                        alt=""
                        role="presentation"
                        onClick={() => {
                          if (name) {
                            setName(false)
                          } else {
                            setName(true)
                          }

                          document.getElementById('username')?.focus()
                        }}
                      />
                    </p>
                    <p>{userInfo.email}</p>
                  </div>
                </span>
              </div>
              <div className="avatar-content-right avatar-content-right-save">
                <ButtonPrimary
                  style={{
                    padding: '0 16px',
                    width: '120px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                  }}
                  onClick={onFormSubmit}
                >
                  <CheckUnderLine />
                  <span style={{ color: '#fff' }}>
                    <FormattedMessage id="Save" />
                  </span>
                </ButtonPrimary>
              </div>
            </div>

            <div className="box-info-input">
              <Form layout="vertical" form={form}>
                <Row gutter={[{ sm: 15, md: 32, lg: 57 }]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <div className="input-width-pen">
                      <div className="box-input box-input-country">
                        <FormItem
                          label={
                            <span>
                              <FormattedMessage id="Country" />
                            </span>
                          }
                          name="country"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter country field',
                            },
                          ]}
                        >
                          <InputSelect
                            showSearch
                            style={{ width: '100%' }}
                            placeholder={intl.formatMessage({ id: 'Select Country' })}
                            options={optionsCountry}
                            onChange={(code) => {
                              const fCountry = listCountry?.find((country) => country.code === code)
                              setSelectCountry(fCountry)
                            }}
                          />
                          {/* <Select
                              id="country"
                              dropdownClassName="dropdown-country"
                              showSearch
                              style={{ width: '100%' }}
                              placeholder={<FormattedMessage id="Country" />}
                              onChange={(e) => setDataSelectCountry(e)}
                            >
                              {dataSelectCountry}
                            </Select> */}
                        </FormItem>
                      </div>
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <div className="input-width-pen">
                      <div className="box-input">
                        <FormItem
                          label={
                            <span>
                              <FormattedMessage id="Phone Number" />
                            </span>
                          }
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="Please enter Phone Number field" />,
                            },
                            { pattern: regexPhone, message: <FormattedMessage id="Invalid Phone number" /> },
                          ]}
                        >
                          <input
                            className="input-phone"
                            id="phone-number"
                            placeholder="Phone Number"
                            style={{ paddingLeft: selectCountry ? '72px' : '20px' }}
                          />
                        </FormItem>
                        {selectCountry && <span className="code-phone">{selectCountry?.phone_code}</span>}
                        <img
                          src="/images/icons/icon-edit.png"
                          alt=""
                          role="presentation"
                          onClick={() => {
                            document.getElementById('phone-number')?.focus()
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <div className="input-width-pen">
                      <div className="box-input">
                        <FormItem
                          label={
                            <span>
                              <FormattedMessage id="Address" />
                            </span>
                          }
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="Please enter Address field" />,
                            },
                          ]}
                        >
                          <input id="address" placeholder="address" />
                        </FormItem>
                        <img
                          src="/images/icons/icon-edit.png"
                          alt=""
                          role="presentation"
                          onClick={() => {
                            document.getElementById('address')?.focus()
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <div className="input-width-pen">
                      <div className="box-input">
                        <FormItem
                          label={
                            <span>
                              <FormattedMessage id="Birthday" />
                            </span>
                          }
                          name="birthday"
                          rules={[
                            {
                              required: true,
                              message: <FormattedMessage id="Please enter Birthday field" />,
                            },
                          ]}
                        >
                          <div style={{ position: 'relative' }}>
                            <DatePicker
                              id="birthday"
                              value={moment(inputBirthday)}
                              style={{ width: '100%', padding: '0', borderRadius: '10px' }}
                              onChange={(date, dateString) => handleChangeDate(date, dateString)}
                            />
                            <img
                              className="icon-edit-birthday"
                              style={{ top: '28px' }}
                              src="/images/icons/icon-edit.png"
                              alt=""
                              role="presentation"
                              onClick={() => {
                                document.getElementById('birthday')?.focus()
                              }}
                            />
                          </div>
                        </FormItem>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>

            <div className="box-info-password">
              <div className="password-left">
                <p>
                  <FormattedMessage id="Password" />
                </p>
                <p>
                  <FormattedMessage id="Increase your password strength to enhance account security" />
                </p>
              </div>
              <div className="password-right">
                <ButtonPrimary
                  style={{
                    width: window.innerWidth > 768 ? '102px' : '80px',
                    height: window.innerWidth > 768 ? '46px' : '30px',
                  }}
                  role="presentation"
                  onClick={handleChangePassword}
                >
                  <FormattedMessage id="CHANGE" />
                </ButtonPrimary>
              </div>
            </div>

            <div className="box-rate-sercurity">
              <Link to="/wallet">
                <div className="rate-sercurity-item">
                  <div className="rate-sercurity-item-name">
                    <img src="/images/account/wallet.png" alt="Wallet" />
                    <p>
                      <FormattedMessage id="Wallet" />
                    </p>
                  </div>
                  <div className="rate-sercurity-item-description">
                    <p>
                      <FormattedMessage id="Your assets will be safely kept here and the multi-chain makes it easy to use." />
                    </p>
                  </div>
                </div>
              </Link>

              <Link to="/profile/security">
                <div className="rate-sercurity-item">
                  <div className="rate-sercurity-item-name">
                    <img src="/images/account/shield.png" alt="Security" />
                    <p>
                      <FormattedMessage id="Security" />
                    </p>
                  </div>
                  <div className="rate-sercurity-item-description">
                    <p>
                      <FormattedMessage id="Account risk level:" />
                      {userInfo?.status_2fa === 2 || userInfo?.status_2fa === 0 ? (
                        <PointSecurity point={3} />
                      ) : !userInfo?.status_kyc ? (
                        <PointSecurity point={4} />
                      ) : (
                        <PointSecurity point={5} />
                      )}
                    </p>
                  </div>
                </div>
              </Link>
              <div
                className="btn-save-mobile"
                style={{ display: window.innerWidth >= 991 ? 'none' : 'block', textAlign: 'center' }}
              >
                <ButtonPrimary onClick={onFormSubmit}>
                  <FormattedMessage id="Save" />
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      )}
      <ChangePassword
        email={userInfo.email}
        visible={viewDetails.toggle}
        dataModal={viewDetails.dataModal}
        close={setViewDetails}
      />
    </>
  )
}

export default injectIntl(Account)
